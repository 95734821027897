import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import ResetPasswordComponent from '../../components/reset-password/index';

import './reset-password.css';

// Images
import spartaLogo from '../../assets/images/logo/Sparta 3.svg';
import backgroundBanner from '../../assets/images/banner/landng_banner.png';

//Helper function
import { GOOGLE_RECAPTCHA } from '../../utils/constants';

const ResetPassword = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA}>
      <div className="reset-password-container">
        <div className="banner-sections">
          <div className='banner-img'>
            <img alt="sparta Logo" src={backgroundBanner} />
          </div>
          <div className="sparta-logo-sections">
            <div className="sparta-logo">
              <img className="" alt="sparta Logo" src={spartaLogo} />
            </div>
          </div>
        </div>
        <ResetPasswordComponent />
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default ResetPassword;
