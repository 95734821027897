import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './header.css';

// Images
import SpartaWhiteLogo from '../../assets/images/logo/sparta_white.svg';
import ProfileIcon from '../../assets/images/headers/profile.svg';
// import NotificationIcon from '../../assets/images/headers/notification.svg';
import DocumentIcon from '../../assets/images/headers/document.svg';
import Folder from '../../assets/images/headers/allrequest-folder.svg';
import Logout from '../../assets/images/headers/logout.svg';
import Redirect from '../../assets/images/headers/redirect.svg';
import Settings from '../../assets/images/headers/settings.svg';
import DocumentActiveIcon from '../../assets/images/headers/document-green.svg';
import ProfileActiveIcon from '../../assets/images/headers/profile-green.svg';
// import QuestionIcon from '../../assets/images/headers/question.svg';
// import QuestionActiveIcon from '../../assets/images/headers/support-green.svg';

import {
  forceLogout,
  getUserDetails,
  storeUserDetails
} from '../../utils/user-helper';
import { USER_DETAILS_KEY } from '../../utils/constants';
import { logout } from '../../services/auth-services';
import { toast } from 'react-toastify';
import $ from 'jquery';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation().pathname.split('/');
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    let user = getUserDetails(USER_DETAILS_KEY);
    if (user) {
      setUserData(user);
    }

    if (!getUserDetails('initial')) {
      storeUserDetails('initial', true);

      $('.tooltip-popup').css('display', 'block');
      setTimeout(() => {
        $('.tooltip-popup').css('display', 'none');
      }, 7000);
    }
  }, []);

  const onLogout = async () => {
    try {
      const { status, error } = await logout();
      if (status === 200) {
        forceLogout();
      }
      if (error) {
        toast.error(error?.response?.data);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  return (
    <div className="header-wrapper">
      <div className="logo-wrapper">
        <div className="logo-sections" onClick={() => navigate('/dashboard')}>
          <img src={SpartaWhiteLogo} alt="sparta white logo" />
        </div>
      </div>
      <div className="header">
        <h1>
          {location[1] === 'dashboard'
            ? 'API Explorer'
            : location[1] === 'all-requests'
            ? 'Requests'
            : location[1] === 'settings'
            ? 'Settings'
            : location[1] === 'support'
            ? 'Support'
            : ''}
        </h1>
      </div>
      <ul className="icons-list">
        {/* <li className="list-item">
          <label className="icons-wrapper notifications">
            <img src={NotificationIcon} alt="notification" />
          </label>
          <div className="c-tool-tip">
            <p>Notifications</p>
          </div>
        </li>
        <li
          className={
            location[1] === 'support'
              ? 'list-item document-show extra-width'
              : 'list-item'
          }
          onClick={() => navigate('/support')}>
          <label className="icons-wrapper support">
            <img
              src={
                location[1] === 'support' ? QuestionActiveIcon : QuestionIcon
              }
              alt="question"
            />
          </label> <div className="c-tool-tip">
            <p>Support</p>
          </div>
        </li> */}
        <li
          className={
            location[1] === 'documentation'
              ? 'list-item document-show'
              : 'list-item'
          }
          onClick={() => navigate('/documentation')}>
          <label className="icons-wrapper documentation">
            <img
              src={
                location[1] === 'documentation'
                  ? DocumentActiveIcon
                  : DocumentIcon
              }
              alt="documentation"
            />
          </label>
          <div className="c-tool-tip">
            <p>Documentation</p>
          </div>
          <div className="tooltip-popup">
            <p className="info-content">
              Check here for more info about API and Use cases.
            </p>
            <span className="toolitip-triangle"></span>
          </div>
        </li>
        <li
          className={
            location[1] === 'settings' ? 'list-item document-show' : 'list-item'
          }>
          <div className="btn-group">
            <label
              className="icons-wrapper profile dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              <img
                src={
                  location[1] === 'settings' ? ProfileActiveIcon : ProfileIcon
                }
                loading="lazy"
                alt="document"
              />
            </label>
            <div className="c-tool-tip">
              <p>Profile</p>
            </div>
            <ul className="dropdown-menu">
              <li className="dropdown-menu-item">
                <label>{userData?.userName}</label>
                <span>{userData?.userEmail}</span>
              </li>
              <li className="dropdown-menu-item">
                <hr className="dropdown-divider" />
              </li>
              <li
                className="dropdown-menu-item"
                onClick={() => navigate('/all-requests')}>
                <label>
                  <img src={Folder} alt="Folder" />
                </label>
                <span>All Requests</span>
              </li>
              <li
                className="dropdown-menu-item"
                onClick={() => navigate('/settings')}>
                <label>
                  <img src={Settings} alt="setting" />
                </label>
                <span>Settings</span>
              </li>

              <li className="dropdown-menu-item">
                <label>
                  <img src={Redirect} alt="redirect" />
                </label>
                <span>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://satsure.co/product-sparta.html">
                    Visit Website
                  </a>
                </span>
              </li>
              <li className="dropdown-menu-item" onClick={() => onLogout()}>
                <label>
                  <img src={Logout} alt="logout" />
                </label>
                <span>Logout</span>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Header;
