import { getRequest, postRequest } from '../utils/http-helper';
import { DASHBOARD_BASE_URL, FILE_UPLOAD_URL, API_COUNT_URL } from '../utils/url-helper';
import { attachParams, getUserDetails } from '../utils/user-helper';
import { USER_DETAILS_KEY, header } from '../utils/constants';

export const getListofRequest = async (queryparams) => {
  try {
    let userEmail = getUserDetails(USER_DETAILS_KEY)?.userEmail;
    let url = `${DASHBOARD_BASE_URL}/${userEmail}/all-requests`;
    let queryUrl = attachParams(url, queryparams);
    return await getRequest({
      url: queryUrl,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const updateActions = async (id, type, payload) => {
  try {
    return await postRequest({
      url: `${DASHBOARD_BASE_URL}/${id}/${type}`,
      Auth: true,
      headers: true,
      data: payload
    });
  } catch (e) {
    return e;
  }
};

export const updateDeliveryDate = async (id, payload) => {
  try {
    return await postRequest({
      url: `${DASHBOARD_BASE_URL}/${id}/update-delivery-date`,
      Auth: true,
      headers: true,
      data: payload
    });
  } catch (e) {
    return e;
  }
};

export const uploadFile = async (id, payload) => {
  header['Content-Type'] = 'multipart/form-data';
  try {
    return await postRequest({
      url: `${FILE_UPLOAD_URL}/${id}`,
      Auth: true,
      headers: true,
      data: payload
    });
  } catch (e) {
    return e;
  }
};

export const getApiHitsRequest = async (requestId, date, apiKeyId) => {
  try {
    let url = `${API_COUNT_URL}?request_id=${requestId}&date=${date}&api_key_id=${apiKeyId}`;
    return await getRequest({
      url: url,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};