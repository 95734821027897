import React, { useState } from 'react';
import ContactUs from '../../components/contact-us';
import Modal from 'react-modal';
import './support.css';

import MailLogo from '../../assets/images/icons/c-mail.svg';
import PhoneLogo from '../../assets/images/icons/call.svg';
import WebsiteLogo from '../../assets/images/icons/website.svg';

import SideBar from '../../components/sidebar/sidebar';
import FAQAccordion from '../../components/faq-accordion';
import { POPULAR_TOPICS } from '../../utils/dummy-data.js';

const Support = () => {
  const [modal, setModal] = useState(false);
  const [activeForm, setActiveForm] = useState('');

  const toggleContent = (data) => {
    setModal(!modal);
    setActiveForm(data);
  };

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '0px',
      borderRadius: '8px',
      textAlign: 'center'
    }
  };

  return (
    <div className="support-container">
      <div className="support-list">
        <SideBar tab="support" />
      </div>
      <div className="documentations-wrapper">
        <div className="search-bar">
          <span className=" search-icon" />
          <input type="text" placeholder="How can we help?" />
        </div>
        <h1>Popular Topics</h1>
        <div className="popular-topics">
          {POPULAR_TOPICS &&
            POPULAR_TOPICS.length !== 0 &&
            POPULAR_TOPICS.map((item) => {
              return (
                <FAQAccordion question={item.question} answer={item.answer} />
              );
            })}
        </div>
      </div>
      <div className="right-side-nav-container">
        <div className="contact-us">
          <h3 className="summary">Contact Us</h3>
          <div className="contact-btn">
            <button
              type="button"
              className="btn-contact"
              onClick={() => toggleContent('raiseQuery')}>
              Raise a Complaint
            </button>
          </div>
          <div className="contact-btn">
            <button
              type="button"
              className="btn-contact"
              onClick={() => toggleContent('requestDemo')}>
              Request a Demo
            </button>
          </div>
          <div className="contact-btn">
            <button
              type="button"
              className="btn-contact"
              onClick={() => toggleContent('generalQuery')}>
              General Query
            </button>
          </div>
        </div>
        <ul className="footer-contact">
          <li>
            <a href="mailto:info2satsure.co" className="icon-length-width">
              <label>
                <img src={MailLogo} alt="upload"></img>
              </label>
              <span className="label-width">info@satsure.co</span>
            </a>
          </li>
          <li>
            <a
              href="https://satsure.co/"
              target="_blank"
              rel="noreferrer"
              className="icon-length-width">
              <label>
                <img src={WebsiteLogo} alt="upload"></img>
              </label>
              <span className="label-width">https://www.satsure.co/</span>
            </a>
          </li>
          <li>
            <label>
              <img src={PhoneLogo} alt="upload"></img>
            </label>
            <span className="label-width">+91 - 9603151409</span>
          </li>
        </ul>
      </div>
      <Modal
        isOpen={modal}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal">
        {modal && <ContactUs closeModal={setModal} activeForm={activeForm} />}
      </Modal>
    </div>
  );
};

export default Support;
