import React, { useState } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { getProductListName } from '../../utils/user-helper';

//CSS
import './index.css';
import moment from 'moment';

// Images
import arrowWhite from '../../assets/images/icons/right-arrow.svg';
import zoom_in from '../../assets/images/icons/zoom-in.svg';
import zoom_out from '../../assets/images/icons/zoom-out.svg';
import opacityControl from '../../assets/images/icons/opacity-filter.svg';
import opacityControlDisable from '../../assets/images/icons/opacity-filter-disable.svg';
import EYE_BLUE from '../../assets/images/icons/opacity-blue.svg';
// import layerControl from '../../assets/images/icons/layer-control.svg';
import fullScreen from '../../assets/images/icons/full-screen.svg';
import exitFullScreen from '../../assets/images/icons/exit-full-screen.svg';
import location from '../../assets/images/icons/location.svg';
import location_disabled from '../../assets/images/icons/location_disabled.svg';

const MapControls = ({
  map,
  onChangeRange,
  onChangeSecondRange,
  layerName,
  singleLayerName,
  compareLayerName,
  flyToGeometry,
  wmsSinlgeMergeLayer
}) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [mapTools, setMapTools] = useState({
    opacity: false,
    layer: true
  });

  const mapZoom = (zoomValue) => {
    const view = map.getView();
    const currentzoom = map.getView().getZoom();
    view.animate({
      zoom: currentzoom + zoomValue,
      duration: 300
    });
  };

  window.onresize = () => {
    if (map) map.updateSize();
  };

  const fullScreenview = () => {
    const element = map.getTargetElement();
    if (!isFullScreen) {
      // Enter full screen mode
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
      setIsFullScreen(true);
    } else {
      // Exit full screen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      setIsFullScreen(false);
    }
  };

  return (
    <ul className="map-controls-section">
      <li className="map-list zoom-in">
        <div className="map-control">
          <img
            onClick={() => mapZoom(+1)}
            src={zoom_in}
            alt="zoom-in"
            className="control"
          />
        </div>
        <div className="control-hover">
          <label>Zoom in</label>
          <span></span>
        </div>
      </li>
      <li className="map-list zoom-out">
        <div className="map-control">
          <img
            onClick={() => mapZoom(-1)}
            src={zoom_out}
            alt="zoom-out"
            className="control"
          />
        </div>
        <div className="control-hover">
          <label>Zoom out</label>
          <span></span>
        </div>
      </li>
      {/* <li className="map-list">
        <div
          data-bs-toggle="dropdown"
          aria-expanded="false"
          aria-haspopup="false">
          <div className="map-control">
            <img src={layerControl} alt="opacityControl" className="control" />
          </div>
        </div>
        <div className="dropdown-menu opacity-layer">
          <div className="opacity-header">
            <label>Opacity</label>
            <span>
              <img src={arrowWhite} alt="arrowWhite" />
            </span>
          </div>
          <ul className="opacity-list">
            <li>
              <label> </label>
              <span>Forest</span>
            </li>
            <li>
              <label></label>
              <span>urban area</span>
            </li>
          </ul>
        </div>
      </li> */}
      <li className="map-list opacity">
        <div className="opacity-sections">
          <div className="map-control">
            {layerName || singleLayerName ? (
              <img
                onClick={() =>
                  setMapTools({ ...mapTools, opacity: !mapTools.opacity })
                }
                src={opacityControl}
                alt="layerControl"
                className="control"
              />
            ) : (
              <img
                src={opacityControlDisable}
                alt="layerControl"
                className="control"
              />
            )}
          </div>
          <div className="control-hover">
            <label>Opacity</label>
            <span></span>
          </div>
        </div>
        {layerName && (
          <div
            className={
              mapTools.opacity ? 'opacity-popup' : 'opacity-popup hidden'
            }>
            <div className="opacity-header">
              <label className="opacity-header-title">Opacity</label>
              <span
                onClick={() =>
                  setMapTools({ ...mapTools, opacity: !mapTools.opacity })
                }>
                <img src={arrowWhite} alt="arrowWhite" />
              </span>
            </div>
            <div className="opacity-crop-name">
              <label className="opacity-header-title">
                {getProductListName(layerName).title}
              </label>
              <span>
                <img src={EYE_BLUE} alt="Eye Blue" />
              </span>
            </div>

            <div className="opacity-range-slider">
              {/* <label> */}
              <Slider
                defaultValue={100}
                step={1}
                railStyle={{ background: '#D9D9D9', height: '5px' }}
                trackStyle={{ background: '#261E80', height: '5px' }}
                onChange={onChangeRange}
              />
              {/* </label> */}
              {/* <span>10%</span> */}
            </div>
          </div>
        )}
        {singleLayerName && (
          <div
            className={
              mapTools.opacity ? 'opacity-popup' : 'opacity-popup hidden'
            }>
            <div className="opacity-header">
              <label className="opacity-header-title">Opacity</label>
              <span
                onClick={() =>
                  setMapTools({ ...mapTools, opacity: !mapTools.opacity })
                }>
                <img src={arrowWhite} alt="arrowWhite" />
              </span>
            </div>
            <div className="opacity-crop-name">
              <label className="opacity-header-title">
                {moment(
                  wmsSinlgeMergeLayer?.first?.wms?.properties?.datetime.split(
                    'T'
                  )[0]
                ).format('DD-MM-YYYY') +
                  '_' +
                  getProductListName(wmsSinlgeMergeLayer?.first?.productId)
                    ?.treeTitleCode}
              </label>
              <span>
                <img src={EYE_BLUE} alt="Eye Blue" />
              </span>
            </div>
            <div className="opacity-range-slider">
              <Slider
                defaultValue={100}
                step={1}
                railStyle={{ background: '#D9D9D9', height: '5px' }}
                trackStyle={{ background: '#261E80', height: '5px' }}
                onChange={onChangeRange}
              />
            </div>
            <div className="opacity-crop-name">
              <label className="opacity-header-title">
                {moment(
                  wmsSinlgeMergeLayer?.second?.wms?.properties?.datetime.split(
                    'T'
                  )[0]
                ).format('DD-MM-YYYY') +
                  '_' +
                  getProductListName(wmsSinlgeMergeLayer?.second?.productId)
                    ?.treeTitleCode}
              </label>
              <span>
                <img src={EYE_BLUE} alt="Eye Blue" />
              </span>
            </div>
            <div className="opacity-range-slider">
              <Slider
                defaultValue={100}
                step={1}
                railStyle={{ background: '#D9D9D9', height: '5px' }}
                trackStyle={{ background: '#261E80', height: '5px' }}
                onChange={onChangeSecondRange}
              />
            </div>
          </div>
        )}
      </li>
      <li className="map-list full-screen">
        <div className="map-control">
          <img
            onClick={() => fullScreenview()}
            src={isFullScreen ? exitFullScreen : fullScreen}
            alt="fullScreen"
            className="control"
          />
        </div>
        <div className="control-hover">
          <label>{isFullScreen ? 'Exit Full Screen' : 'Full Screen'}</label>
          <span></span>
        </div>
      </li>
      <li className="map-list refocus">
        <div className="map-control">
          {compareLayerName || layerName || singleLayerName ? (
            <img
              onClick={() => flyToGeometry()}
              src={location}
              alt="location"
              className="control"
            />
          ) : (
            <img src={location_disabled} alt="location" className="control" />
          )}
        </div>
        <div className="control-hover">
          <label>Refocus</label>
          <span></span>
        </div>
      </li>
    </ul>
  );
};

export default MapControls;
