import { getRequest, postRequest, putRequest } from '../utils/http-helper';
import {
  UPDATE_PASSWORD_URL,
  USER_DETAILS,
  API_KEY_URL,
  SUBMIT_URL
} from '../utils/url-helper';
import { getUserDetails } from '../utils/user-helper';
import { USER_DETAILS_KEY } from '../utils/constants';
import { attachParams } from '../utils/user-helper';

export const getUserDetailsValue = async (emailId) => {
  try {
    return await getRequest({
      url: `${USER_DETAILS}/${emailId}`,
      headers: true,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const postUserDetailsValue = async (emailId, payload) => {
  try {
    return await postRequest({
      url: `${USER_DETAILS}/${emailId}`,
      headers: true,
      data: payload,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const postSubmitFormValue = async (payload,queryType) => {
  let userEmail = getUserDetails(USER_DETAILS_KEY)?.userEmail;
  try {
    return await postRequest({
      url: `${SUBMIT_URL}/${userEmail}/${queryType}/querysets`,
      headers: true,
      data: payload,
      Auth: true
    
    });
  } catch (e) {
    return e;
  }
};

export const changePassword = async (payload) => {
  try {
    let userData = getUserDetails(USER_DETAILS_KEY);

    return await postRequest({
      url: `${UPDATE_PASSWORD_URL}/${userData?.userEmail}`,
      data: payload,
      headers: true,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const getApiKeyValue = async () => {
  const userEamil = getUserDetails(USER_DETAILS_KEY)?.userEmail;

  try {
    return await getRequest({
      url: `${API_KEY_URL}/${userEamil}`,
      headers: true,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const postApiKeyValue = async (payload) => {
  try {
    const userEamil = getUserDetails(USER_DETAILS_KEY)?.userEmail;
    return await postRequest({
      url: `${API_KEY_URL}/${userEamil}`,
      data: payload,
      headers: true,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const putApiKeyValue = async (payload, apiKeyId) => {
  try {
    const userEamil = getUserDetails(USER_DETAILS_KEY)?.userEmail;
    let apiKeyUrl = `${API_KEY_URL}/${userEamil}`;
    let url = attachParams(apiKeyUrl, { id: apiKeyId });
    return await putRequest({
      url: url,
      data: payload,
      headers: true,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};
