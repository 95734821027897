import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Modal from 'react-modal';
// Helper functions
import { USER_DETAILS_KEY } from '../../utils/constants';
import { getRole, getIndustry } from '../../services/industry-role-services';
import {
  getUserDetailsValue,
  postUserDetailsValue
} from '../../services/setting-services';
import { getUserDetails } from '../../utils/user-helper';
import './index.css';
import EditLogo from '../../assets/images/icons/edit.svg';
//Importing notification library
import { toast } from 'react-toastify';

//Defines modal dialog box
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    borderRadius: '8px',
    textAlign: 'center'
  }
};

// Functional component to of profile
const Profile = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [industryOption, setIndustryOption] = useState([]);
  const [roleOption, setRoleOption] = useState([]);
  const [selectedOption, setSelectedOption] = useState({
    industry: null,
    role: null
  });

  const [profileError, setProfileError] = useState({
    firstName: '',
    lastName: '',
    industryId: '',
    industryName: '',
    roleId: '',
    roleName: ''
  });

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    userEmail: '',
    industryId: '',
    industryName: '',
    roleId: '',
    roleName: ''
    // company: "",
  });

  useEffect(() => {
    getIndusteryData();
    getRoleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (industryOption.length > 0 && roleOption.length > 0) {
      fetchUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [industryOption, roleOption]);


    /**
   * <function description>
   *  Asynchronous function to fetch industry
   * data through API using getIndustry
   */
  const getIndusteryData = async () => {
    const { data, status } = await getIndustry();
    if (status === 200) {
      let arr = data.data.map((item) => {
        return {
          label: item.name,
          value: item.id
        };
      });
      setIndustryOption([...arr]);
    }
  };

   /**
   * <function description>
   *  Asynchronous function to fetch role
   * data through API using getRole
   */
  const getRoleData = async (id) => {
    const { data, status } = await getRole();
    if (status === 200) {
      let arr = data.data.map((item) => {
        return {
          label: item.name,
          value: item.id
        };
      });
      setRoleOption([...arr]);
    }
  };

   /**
   * <function description>
   *  Asynchronous function to fetch details from API
   * & sets the form data and selected options based on the API response.
   */
  const fetchUserDetails = async () => {
    let user = getUserDetails(USER_DETAILS_KEY);
    const { data, status } = await getUserDetailsValue(user?.userEmail);
    if (status === 200) {
      setFormData({
        ...formData,
        firstName: data.data.first_name,
        lastName: data.data.last_name,
        userEmail: data.data.email,
        industryId: data.data.industry_id,
        industryName: data.data.industry_name,
        roleId: data.data.role_id,
        roleName: data.data.role_name
      });
      setSelectedOption({
        ...selectedOption,
        industry: industryOption.filter(
          (item) => item.value === data.data.industry_id
        )[0],
        role: roleOption.filter((item) => item.value === data.data.role_id)[0]
      });
    }
  };

  /**
   * <function description>
   * Function to set the form data and clear the corresponding error msgs
   * whenever the value of input field changes
   */
  const onchangeHandler = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      };
    });
    setProfileError((prev) => {
      return {
        ...prev,
        [e.target.name]: ''
      };
    });
  };

  /**
   * <function description>
   * Function to change the input field whenever
   * corresponding dropdown shifts to "Other"
   */
  const onChangeSelect = (e, type) => {
    if (e.value !== 4) {
      setFormData({ ...formData, [type]: '' });
    }
  };

  
  /**
   * <function description>
   * Function to validate the user input data in the form
   * sets error msgs accordingly
   */
  function profileValidations() {
    let userError = { ...profileError };
    if (formData.firstName === '') {
      userError.firstName = 'Enter First Name';
    }
    if (formData.lastName === '') {
      userError.lastName = 'Enter Last Name';
    }
    if (selectedOption.industry.value === 4) {
      if (formData.industryName === '') {
        userError.industryName = 'Enter Industry Name';
      }
    }
    if (selectedOption.role.value === 4) {
      if (formData.roleName === '') {
        userError.roleName = 'Enter Role Name';
      }
    }
    setProfileError(userError);

    if (
      (formData.firstName !== '' &&
        formData.lastName !== '' &&
        selectedOption.industry.value === 4 &&
        formData.industryName !== '' &&
        selectedOption.role.value === 4 &&
        formData.roleName !== '') ||
      (formData.firstName !== '' &&
        formData.lastName !== '' &&
        selectedOption.industry.value !== 4 &&
        selectedOption.role.value === 4 &&
        formData.roleName !== '') ||
      (formData.firstName !== '' &&
        formData.lastName !== '' &&
        selectedOption.industry.value === 4 &&
        formData.industryName !== '' &&
        selectedOption.role.value !== 4) ||
      (formData.firstName !== '' &&
        formData.lastName !== '' &&
        selectedOption.industry.value !== 4 &&
        selectedOption.role.value !== 4)
    ) {
      setIsOpen(true);
    }
  }

   /**
   * <function description>
   * Asynchronous function that retrieves user details from local storage using a key
   * displays a success toast message
   */
  const profileUpdate = async () => {
    let profileDetails = {
      user_name: formData.firstName.concat(' ').concat(formData.lastName),
      industry_id: selectedOption.industry?.value,
      industry_name: formData.industryName,
      role_id: selectedOption.role?.value,
      role_name: formData.roleName
    };
    let user = getUserDetails(USER_DETAILS_KEY);
    const { status } = await postUserDetailsValue(
      user?.userEmail,
      profileDetails
    );
    if (status === 200) {
      setIsOpen(false);
      toast.success('Successfully Updated..!');
    }
  };

  /**
   * <function description>
   * Function to disable the update button
   * whenever the fields are empty
   */
  const getDisable = () => {
    if (
      (formData.firstName !== '' &&
        formData.lastName !== '' &&
        selectedOption.industry.value === 4 &&
        formData.industryName !== '' &&
        selectedOption.role.value === 4 &&
        formData.roleName !== '') ||
      (formData.firstName !== '' &&
        formData.lastName !== '' &&
        selectedOption.industry.value !== 4 &&
        selectedOption.role.value === 4 &&
        formData.roleName !== '') ||
      (formData.firstName !== '' &&
        formData.lastName !== '' &&
        selectedOption.industry.value === 4 &&
        formData.industryName !== '' &&
        selectedOption.role.value !== 4) ||
      (formData.firstName !== '' &&
        formData.lastName !== '' &&
        selectedOption.industry.value !== 4 &&
        selectedOption.role.value !== 4)
    ) {
      return false;
    }
    return true;
  };

  return (
    <div className="profile-container">
      <div className="profile-header">
        <h1>Change your details</h1>
        <hr></hr>
      </div>
      <div className="profile-list">
        <div className="profile-edit">
          <div className="name-first-last">
            <div className="profile-form common-width-name">
              <label>First name</label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                className={
                  profileError.firstName ? 'profile-input red' : 'profile-input'
                }
                onChange={(e) => onchangeHandler(e)}
              />
            </div>
            <div className="profile-form common-width-name">
              <label>Last name</label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                className={
                  profileError.lastName ? 'profile-input red' : 'profile-input'
                }
                onChange={(e) => onchangeHandler(e)}
              />
            </div>
          </div>
          <div className="profile-form">
            <label>Email</label>
            <span className="email-disabled">{formData.userEmail}</span>
          </div>
          {/* <div className="profile-form">
                        <label>Phone number</label>
                        <input
                            type="number"
                            name="fname"
                            max="10"
                            className='profile-input'
                        />
                    </div> */}
          <div className="profile-form">
            <label>Industry</label>
            <Select
              placeholder="Select Industry"
              classNamePrefix={'react-select'}
              value={selectedOption.industry}
              options={industryOption}
              onChange={(e) => {
                onChangeSelect(e, 'industryName');
                setSelectedOption({ ...selectedOption, industry: e });
              }}
            />
            {selectedOption?.industry?.label === 'Others' && (
              <input
                type="text"
                name="industryName"
                className={
                  profileError.industryName
                    ? 'profile-input red'
                    : 'profile-input'
                }
                value={formData.industryName}
                placeholder="Please Specify..."
                onChange={(e) => {
                  onchangeHandler(e);
                }}
              />
            )}
          </div>
          <div className="profile-form">
            <label>Role</label>
            <Select
              placeholder="Select Role"
              classNamePrefix={'react-select'}
              options={roleOption}
              value={selectedOption.role}
              onChange={(e) => {
                onChangeSelect(e, 'roleName');
                setSelectedOption({ ...selectedOption, role: e });
              }}
            />
            {selectedOption?.role?.label === 'Others' && (
              <input
                type="text"
                name="roleName"
                placeholder="Please Specify..."
                value={formData.roleName}
                className={
                  profileError.roleName ? 'profile-input red' : 'profile-input'
                }
                onChange={(e) => {
                  onchangeHandler(e);
                }}
              />
            )}
          </div>
          {/* <div className="profile-form">
                        <label>Company</label>
                        <input
                            type="text"
                            name="company"
                            className='profile-input' value={formData.company}
                            onChange={(e) => {
                                onchangeHandler(e);
                            }}
                        />
                    </div> */}
          <button
            disabled={getDisable()}
            onClick={() => profileValidations()}
            className="save-btn">
            Save
          </button>
        </div>
        <vl className="border-divider"></vl>
        <div className="profile-name">
                      {/* Setting name as a profile description */}
          <div className="profile-description">
            <label>
              {formData.firstName.charAt(0).toLocaleUpperCase() +
                formData.lastName.charAt(0).toLocaleUpperCase()}
            </label>
            {/* <div className="edit-profile">
              <img alt="Edit Logo" src={EditLogo} />
            </div> */}
          </div>
        </div>
      </div>
            {/* Setting modal component for saving info */}
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        contentLabel="Example Modal">
        <div className="common-modal-container">
          <div className="merge-content">
            <p>Do you want to save changes ?</p>
          </div>
          <div className="modal-btn">
            <button
              className="modal-save-btn"
              onClick={() => {
                profileUpdate();
              }}>
              Save
            </button>
            <button className="discard-btn" onClick={() => setIsOpen(false)}>
              Discard
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Profile;
