import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import TablePagination from '@mui/material/TablePagination';
import Fuse from 'fuse.js';

import './request.css';
import moment from 'moment';
import Back from '../../assets/images/back.svg';
import Search from '../../assets/images/search-icon.svg';
// import Filter from '../../assets/images/filter.svg';

import { getListofRequest } from '../../services/request-services';
import RequestRow from '../../components/request-row/request-row';
import { getUserDetails } from '../../utils/user-helper';
import { SUPER_ADMIN, USER_DETAILS_KEY, USER } from '../../utils/constants';
import { onGetApiKeyList } from '../../services/product-crop-services';
import { getProductListName, getProductId } from '../../utils/user-helper';
import Loader from '../../components/common-loader';

const Requests = () => {
  const isAdmin = getUserDetails(USER_DETAILS_KEY)?.user_type;
  const navigate = useNavigate();
  const [apiKeyOption, setApiKeyOption] = useState([]);
  const [requestChilApiKeyOption, setRequestChilApiKeyOption] = useState([]);
  const [search, setSearch] = useState('');
  // const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState('');
  const [tableAllData, setAllTableData] = useState([]);
  const [tableRender, setTableRender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    page_no: 1,
    page_size: 10
  });
  const [totalCount, setTotalCount] = useState(0);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortRequestor, setSortRequestor] = useState('asc');
  const [sortDateRequest, setSortDateRequest] = useState('asc');

  useEffect(() => {
    getApiKeyList();
    sortTableByRequestor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (apiKeyOption && apiKeyOption.length !== 0) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRender, apiKeyOption, pagination]);

  const fetchData = async () => {
    setLoading(true);
    const { data, status, error } = await getListofRequest(pagination);
    if (status === 200) {
      setLoading(false);
      setTableData(data?.data);
      setAllTableData(data?.data);
      setTotalCount(data?.total);
    }
    if (error) {
      setLoading(false);
    }
  };

  const getApiKeyList = async () => {
    try {
      const { data, status } = await onGetApiKeyList();
      if (status === 200) {
        setApiKeyOption(
          data?.data.map((item) => {
            return {
              label: item.name,
              value: item.value
            };
          })
        );
        setRequestChilApiKeyOption(
          data?.data.map((item) => {
            return {
              id: item.id,
              label: item.name,
              value: item.value
            };
          })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSearchHandler = (e) => {
    setSearch(e.target.value);
    if (e.target.value !== '') {
      const options = {
        keys: ['file_name', 'product_id', 'requested_by']
      };

      var new_data = JSON.parse(JSON.stringify(tableAllData)).map((item) => {
        item['product_id'] = getProductListName(item.product_id)?.treeTitleCode;
        return item;
      });

      const fuse = new Fuse(new_data, options);
      const result = fuse.search(e.target.value);

      setTableData(
        result.map((item) => {
          item.item['product_id'] = getProductId(
            item.item.product_id
          )?.productId;
          return item.item;
        })
      );
    } else {
      setTableData(tableAllData);
    }
  };

  const sortTable = () => {
    const sortedDates = [...tableData].sort((a, b) => {
      const numb_dateA = moment
        .utc(a.date_of_request)
        .format('DD/MM/YYYY HH:mm:ss');
      const numb_dateB = moment
        .utc(b.date_of_request)
        .format('DD/MM/YYYY HH:mm:ss');
      const dateA = new Date(
        numb_dateA.split(' ')[0].split('/').reverse().join('/')
      );
      const dateB = new Date(
        numb_dateB.split(' ')[0].split('/').reverse().join('/')
      );
      if (sortDateRequest === 'asc') {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });
    setTableData(sortedDates);
    setSortDateRequest(sortDateRequest === 'asc' ? 'desc' : 'asc');
  };

  const sortTableByRequestor = () => {
    const sortedData = [...tableData].sort((a, b) => {
      const requestorA = a.requested_by.toLowerCase();
      const requestorB = b.requested_by.toLowerCase();
      if (sortRequestor === 'asc') {
        if (requestorA < requestorB) return -1;
        if (requestorA > requestorB) return 1;
        return 0;
      } else {
        if (requestorA > requestorB) return -1;
        if (requestorA < requestorB) return 1;
        return 0;
      }
    });
    setTableData(sortedData);
    setSortRequestor(sortRequestor === 'asc' ? 'desc' : 'asc');
  };

  const sortDeliveryDateTable = () => {
    const sortedDates = [...tableData].sort((a, b) => {
      if (a.date_of_expiry && b.date_of_expiry) {
        const numb_dateA = moment
          .utc(a.date_of_expiry)
          .format('YYYY-MM-DD HH:mm:ss');
        const numb_dateB = moment
          .utc(b.date_of_expiry)
          .format('YYYY-MM-DD HH:mm:ss');
        const dateA = new Date(
          numb_dateA.split(' ')[0].split('/').reverse().join('/')
        );
        const dateB = new Date(
          numb_dateB.split(' ')[0].split('/').reverse().join('/')
        );
        if (sortOrder === 'asc') {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      } else if (a.date_of_expiry && !b.date_of_expiry) {
        return -1;
      } else if (!a.date_of_expiry && b.date_of_expiry) {
        return 1;
      } else {
        return 0;
      }
    });

    setTableData(sortedDates);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  return (
    <>
      <div className="request-container">
        <div className="request-header">
          <div className="search-header">
            <div
              className="image-wrapper"
              onClick={() => navigate('/dashboard')}>
              <img src={Back} alt="back icon"></img>
            </div>
            <label className="request-heading">Requests</label>
            <div className="request-search">
              <input
                type="text"
                name="search"
                className="search-input"
                onChange={(e) => onSearchHandler(e)}
                value={search}
                placeholder="Search by AOI filename or  Product name"
              />
              <img src={Search} alt="search icon" />
            </div>
          </div>
          {/* <div className="filter-column">
            <div className="column-wrapper" onClick={() => setOpen(!open)}>
              <span>Columns</span>
              <label>
                <img src={Filter} alt="filter icon" />
              </label>
            </div>

            <div className={open ? 'filter-popup show' : 'filter-popup'}>
              <div className="filter-wrapper">
                <ul>
                  <li>
                    <span>
                      <input type="checkbox" name="" id="" />
                    </span>
                    <label>Filename</label>
                  </li>
                  <li>
                    <span>
                      <input type="checkbox" name="" id="" />
                    </span>
                    <label>AOI</label>
                  </li>
                  <li>
                    <span>
                      <input type="checkbox" name="" id="" />
                    </span>
                    <label>Date Range</label>
                  </li>
                  <li>
                    <span>
                      <input type="checkbox" name="" id="" />
                    </span>
                    <label>Date of Delivery</label>
                  </li>
                  <li>
                    <span>
                      <input type="checkbox" name="" id="" />
                    </span>
                    <label>Date of Expiry</label>
                  </li>
                  <li>
                    <span>
                      <input type="checkbox" name="" id="" />
                    </span>
                    <label>API key</label>
                  </li>
                </ul>
                <ul>
                  <li>
                    <span>
                      <input type="checkbox" name="" id="" />
                    </span>
                    <label>API Request</label>
                  </li>
                  <li>
                    <span>
                      <input type="checkbox" name="" id="" />
                    </span>
                    <label>API Response</label>
                  </li>
                  <li>
                    <span>
                      <input type="checkbox" name="" id="" />
                    </span>
                    <label>API Calls Made</label>
                  </li>
                  <li>
                    <span>
                      <input type="checkbox" name="" id="" />
                    </span>
                    <label>Status</label>
                  </li>
                  <li>
                    <span>
                      <input type="checkbox" name="" id="" />
                    </span>
                    <label>Doc</label>
                  </li>
                  <li>
                    <span>
                      <input type="checkbox" name="" id="" />
                    </span>
                    <label>Requestor</label>
                  </li>
                </ul>
              </div>
              <div className="button-group">
                <button
                  className="button-cancel"
                  onClick={() => setOpen(false)}>
                  Cancel
                </button>
                <button className="button-apply">Apply</button>
              </div>
            </div>
          </div> */}
        </div>
        <div className="table-container">
          <table className="table">
            <thead className="table-head">
              <tr>
                <th>FILE NAME</th>
                {isAdmin === SUPER_ADMIN && (
                  <th>
                    REQUESTOR
                    <button onClick={sortTableByRequestor}>
                      {sortRequestor === 'asc' ? '↓' : '↑'}
                    </button>
                  </th>
                )}
                <th>PRODUCT</th>
                <th className="daterange">DATE RANGE</th>
                <th className="sortable-header">
                  DATE OF REQUEST
                  <button onClick={sortTable}>
                    {sortDateRequest === 'asc' ? '↑' : '↓'}
                  </button>
                </th>
                <th className="status">STATUS</th>
                <th>DATE OF EXPIRY</th>
                <th>AOI NAME</th>
                {isAdmin === USER && (
                  <>
                    <th>API KEY</th>
                    <th>API REQUEST</th>
                    <th>API RESPONSE</th>
                  </>
                )}
                <th>API CALLS MADE</th>
                <th>
                  DATE OF DELIVERY
                  <button onClick={sortDeliveryDateTable}>
                    {sortOrder === 'asc' ? '↑' : '↓'}
                  </button>
                </th>
                {isAdmin === SUPER_ADMIN && <th>ACTION</th>}
                {/* <th className="docs">DOC</th> */}
              </tr>
            </thead>
            <tbody className="table-body">
              {loading ? (
                <tr className="loading">
                  <Loader loader="table-loader"></Loader>
                </tr>
              ) : tableData && tableData.length !== 0 ? (
                tableData.map((data) => {
                  return (
                    <RequestRow
                      allRequestParentData={data}
                      setTableRender={setTableRender}
                      tableRender={tableRender}
                      apiKeyOption={apiKeyOption}
                      requestChilApiKeyOption={requestChilApiKeyOption}
                    />
                  );
                })
              ) : (
                tableData !== '' && (
                  <tr className="empty-row">No Request Available</tr>
                )
              )}
            </tbody>
          </table>
          <TablePagination
            className="pagination"
            page={pagination.page_no - 1}
            count={totalCount}
            rowsPerPageOptions={[10, 20, 50, 100]}
            rowsPerPage={pagination.page_size}
            onPageChange={(e, newpage) =>
              setPagination({ ...pagination, page_no: newpage + 1 })
            }
            onRowsPerPageChange={(e) =>
              setPagination({ ...pagination, page_size: e.target.value })
            }
          />
        </div>
      </div>
    </>
  );
};

export default Requests;
