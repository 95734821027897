import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { onReSendverificationEmail } from '../../services/auth-services';

// Images
import SatsureLogo from '../../assets/images/logo/satsure-logo.svg';
import EmailVerificationLogo from '../../assets/images/email-verification.svg';
import spartaLogo from '../../assets/images/logo/Sparta 3.svg';
import backgroundBanner from '../../assets/images/banner/landng_banner.png';
// CSS
import './email-verification.css';

const EmailVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location?.state?.email) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resendVerification = async () => {
    try {
      const { status } = await onReSendverificationEmail(
        location?.state?.email
      );
      if (status === 200) {
        toast.success('Please check you email...!');
      }
    } catch (e) {
      // console.log(e);
    }
  };

  return (
    <div className="login-container">
      <div className="banner-sections">
        <div className='banner-img'>
          <img alt="sparta Logo" src={backgroundBanner} />
        </div>
        <div className="sparta-logo-sections">
          <div className="sparta-logo">
            <img className="" alt="sparta Logo" src={spartaLogo} />
          </div>
        </div>
      </div>

      <div className="form-container">
        <div className="common-login-field email-verifications">
          <h1 className="email-verification-heading">Confirm Your Email</h1>
          <p className="email-description">
            Please Check Your Email.
            <br /> Click on the verification link to activate your account. <br />
            Hurry! The verification link expires in 24 hours.
          </p>
          <label className="email-logo-wrapper">
            <img src={EmailVerificationLogo} alt="email verification logo" />
          </label>
          <div className="resend-email-button">
            <button className="btn-register" onClick={() => resendVerification()}>
              Resend Email
            </button>
          </div>
        </div>
        <div className="powered-by">
          <div className="powered-by-wrapper">
            <label>Powered by</label>
            <div>
              <img src={SatsureLogo} alt="Satsure Logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
