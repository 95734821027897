import React from 'react';
import Modal from 'react-modal';
import MapLayer from '../../components/map-layer';

import './index.css';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '60%',
        padding: '0px',
        borderRadius: '8px'
    }
};

const PopupModal = ({ showModal, setShowModal, particularDateLayer, particularChildlayerFileName, layerParentProductId }) => {

    function closeModal() {
        setShowModal(false);
    }

    return (
        <Modal
            isOpen={showModal}
            ariaHideApp={false}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal">
            <div className="map-modal-popup">
                <MapLayer
                    treeParentIdValue={layerParentProductId.product_id}
                    particularChildlayerFileName={particularChildlayerFileName}
                    setShowModal={setShowModal}
                    showModal={showModal}
                    particularPopUpWms={particularDateLayer}
                    type="allRequestPopupLayer"
                />

            </div>
        </Modal>
    );
};

export default PopupModal;
