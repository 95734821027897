import React, { useState, forwardRef, useEffect } from 'react';

import DatePicker from 'react-datepicker';
import Loader from '../../components/common-loader';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import RejectionModal from 'react-modal';
import { toast } from 'react-toastify';
import Select from 'react-select';

import RightArrow from '../../assets/images/right-arrow.svg';
import ExpandArrow from '../../assets/images/action-arrow.svg';
// import Success from '../../assets/images/request-status-completed.svg';
// import Requested from '../../assets/images/request-status-requested.svg';
// import DownloadDocumnet from '../../assets/images/requests/document-download-ready.svg';
// import DownloadDocs from '../../assets/images/document-download.svg';
// import View from '../../assets/images/requests/view.svg';
// import Upload from '../../assets/images/requests/upload.svg';
// import UploadBlack from '../../assets/images/requests/upload-black.svg';

import Close from '../../assets/images/requests/close-cross.svg';
import Copy from '../../assets/images/copy-image.svg';
import {
  getStatusIcon,
  getUserDetails,
  getProductListName
} from '../../utils/user-helper';
import {
  COMPLETED,
  DECLINE,
  INPROGESS,
  REQUEST,
  SUPER_ADMIN,
  USER,
  USER_DETAILS_KEY,
  getApiRequest
} from '../../utils/constants';
import {
  updateActions,
  updateDeliveryDate
  // uploadFile
} from '../../services/request-services';
import { getDatasets } from '../../services/product-crop-services';
import RequestChildRow from './request-child-row';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    width: '375px',
    borderRadius: '8px'
  }
};

const RequestRow = ({
  allRequestParentData,
  setTableRender,
  tableRender,
  apiKeyOption,
  requestChilApiKeyOption
}) => {
  const isAdmin = getUserDetails(USER_DETAILS_KEY)?.user_type;
  const [expand, setExpand] = useState(false);
  const [showRejectPopup, setShowRejectPopup] = useState(false);
  const [reasonform, setReasonForm] = useState({
    reason_id: '',
    reason: ''
  });
  const [error, setError] = useState('');
  const [date, setDate] = useState(null);
  const [id, setId] = useState('');
  const [selectedApiKey, setSelectedApiKey] = useState(apiKeyOption[0]);
  const [loader, setLoader] = useState(false);
  const [childData, setChildData] = useState({});
  const [copyRequest, setCopyRequest] = useState('');
  const [copyResponse, setCopyResponse] = useState('');

  const [loading, setLoading] = useState({
    response: false,
    request: false
  });

  const copyToClipboard = async (text, key) => {
    if (key !== 'request') {
      let value = JSON.parse(JSON.stringify(text));
      let id = allRequestParentData.id;

      if (value?.data === undefined) {
        setLoading({ ...loading, [key]: true });
        const { data, status } = await getDatasets(id);
        if (status) {
          value = data;
          setChildData(data);
          setLoading({ ...loading, [key]: false });
        }
        if (error) {
          setChildData({});
          setLoading({ ...loading, [key]: false });
        }
      }

      if (
        value &&
        value?.data?.features &&
        value?.data?.features.length !== 0
      ) {
        value?.data.features.map((item) => {
          item.links[0].href = `${item.links[0].href}&api_key=${selectedApiKey.value}`;
          item.links[1].href = `${item.links[1].href}&api_key=${selectedApiKey.value}`;
          return item;
        });
        navigator.clipboard.writeText(JSON.stringify(value));
      }
    } else {
      navigator.clipboard.writeText(text);
    }
    if (key === 'request') {
      setCopyRequest('Copied..!');
      setTimeout(() => {
        setCopyRequest('');
      }, 3000);
    } else {
      setCopyResponse('Copied..!');
      setTimeout(() => {
        setCopyResponse('');
      }, 3000);
    }
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div onClick={onClick}>
      <label className="example-custom-input" ref={ref}>
        {value || 'Select Date'}
      </label>
      <img
        className="down-arrow-image"
        src={RightArrow}
        alt="right arrow"></img>
    </div>
  ));

  const onUpdateActions = async (id, type) => {
    let errorObj = error;
    if (type === 'reject' && reasonform.reason_id === '') {
      errorObj = 'Please select any one reason';
    }
    if (
      type === 'reject' &&
      reasonform.reason_id === 0 &&
      reasonform.reason === ''
    ) {
      errorObj = 'Please type the Reason';
    }
    setError(errorObj);
    if (type === 'reject' && errorObj === '') {
      const { status } = await updateActions(id, type, reasonform);
      if (status === 200) {
        setShowRejectPopup(false);
        setTableRender(!tableRender);
      }
    } else if (type === 'approve') {
      if (date) {
        const { status } = await updateActions(id, type, reasonform);
        if (status === 200) {
          setShowRejectPopup(false);
          setTableRender(!tableRender);
        }
      } else {
        toast.error('Cannot Approve the Request without a Delivery Date');
      }
    }
  };

  const onUpdateDeliveryDates = async (id, dates) => {
    let date_format = moment(dates).format('YYYY-MM-DD');
    const { status, error } = await updateDeliveryDate(id, {
      delivery_date: date_format
    });
    if (status === 200) {
      // setTableRender(!tableRender);
      setDate(dates);
    }
    if (error) {
      setDate(null);
    }
  };

  // const onUploadFile = async (id, e) => {
  //   const fileName = e.target.files[0].name;
  //   const fileExtension = fileName.split('.').pop().toLowerCase();

  //   if (fileExtension === 'pdf') {
  //     const file = new FormData();
  //     file.append('file', e.target.files[0]);

  //     const { status } = await uploadFile(id, file);
  //     if (status === 200) {
  //       toast.success('File successfully Uploaded..!');
  //       setTableRender(!tableRender);
  //     }
  //     e.target.value = null;
  //   } else {
  //     toast.error('You can only upload PDF Files..!');
  //   }
  // };

  const onGetDataset = async (id) => {
    if (childData?.data === undefined && !expand) {
      setLoader(true);
      const { data, status, error } = await getDatasets(id);
      if (status === 200) {
        setChildData(data);
        setLoader(false);
      }
      if (error) {
        toast.error(error.response?.data?.message);
        setLoader(false);
        setChildData({});
      }
    }
    if (expand) {
      setId('');
    } else {
      setId(allRequestParentData.id);
    }
  };

  useEffect(() => {
    if (allRequestParentData.date_of_delivery) {
      setDate(allRequestParentData.date_of_delivery);
    }
  }, [allRequestParentData.date_of_delivery]);

  return (
    <>
      <tr
        className={expand ? 'expandable-row parent' : 'expandable-row'}
        key={allRequestParentData.id}>
        <td>
          <div className="filename-wrapper">
            {isAdmin === SUPER_ADMIN &&
            allRequestParentData.status === REQUEST ? (
              <>
                {loader ? (
                  <span className="right-arrow">
                    <Loader loader="request-child-loader" />
                  </span>
                ) : (
                  <span
                    className={expand ? 'right-arrow rotate' : 'right-arrow'}
                    onClick={() => {
                      setExpand(!expand);
                      onGetDataset(allRequestParentData.id);
                    }}>
                    <img src={RightArrow} alt="arrow icon" />
                  </span>
                )}
              </>
            ) : allRequestParentData.status === INPROGESS ||
              allRequestParentData.status === COMPLETED ? (
              <>
                {loader ? (
                  <span className="right-arrow">
                    <Loader loader="request-child-loader" />
                  </span>
                ) : (
                  <span
                    className={expand ? 'right-arrow rotate' : 'right-arrow'}
                    onClick={() => {
                      setExpand(!expand);
                      onGetDataset(allRequestParentData.id);
                    }}>
                    <img src={RightArrow} alt="arrow icon" />
                  </span>
                )}
              </>
            ) : (
              <span></span>
            )}
            <label
              className="file-name"
              title={
                allRequestParentData?.file_name +
                '_' +
                moment(allRequestParentData?.from_date).format('DMMMYY') +
                '_' +
                getProductListName(allRequestParentData?.product_id)
                  ?.treeTitleCode +
                '_' +
                moment(allRequestParentData?.to_date).format('DMMMYY')
              }>
              {allRequestParentData.file_name +
                '_' +
                moment(allRequestParentData.from_date).format('DMMMYY') +
                '_' +
                getProductListName(allRequestParentData.product_id)
                  ?.treeTitleCode +
                '_' +
                moment(allRequestParentData.to_date).format('DMMMYY')}
            </label>
          </div>
        </td>
        {isAdmin === SUPER_ADMIN && (
          <td className="requestor">{allRequestParentData.requested_by}</td>
        )}
        <td className="requestor">
          {getProductListName(allRequestParentData.product_id)?.treeTitleCode}
        </td>
        <td className="daterange">
          {`${moment(allRequestParentData.from_date).format('DD/MM/YYYY')},
            ${moment(allRequestParentData.to_date).format('DD/MM/YYYY')}`}
        </td>
        <td>
          {moment
            .utc(allRequestParentData.date_of_request)
            .format('DD/MM/YYYY HH:mm:ss')}
        </td>

        <td className="status">
          <label>
            <img
              src={getStatusIcon(allRequestParentData.status).img}
              alt="status icons"
            />
          </label>
          <div
            className={`status-text ${
              getStatusIcon(allRequestParentData.status).title
            }`}>
            <p>{getStatusIcon(allRequestParentData.status).title}</p>
          </div>
        </td>

        <td>
          {isAdmin === USER &&
          allRequestParentData.date_of_expiry &&
          allRequestParentData.status !== DECLINE
            ? allRequestParentData?.date_of_expiry
              ? moment(allRequestParentData?.date_of_expiry).format(
                  'DD/MM/YYYY'
                )
              : '-'
            : '-'}
        </td>

        <td>
          <div
            className="filename-wrapper"
            title={allRequestParentData?.file_name}>
            <label className="file-name-aoi-name">
              {allRequestParentData?.file_name}
            </label>
          </div>
        </td>
        {isAdmin === USER && (
          <>
            <td className="api-key">
              {allRequestParentData.status !== DECLINE ? (
                <Select
                  classNamePrefix="key-select"
                  options={apiKeyOption}
                  value={selectedApiKey}
                  onChange={(e) => setSelectedApiKey(e)}
                />
              ) : (
                '-'
              )}
            </td>
            <td>
              {allRequestParentData.status !== DECLINE ? (
                <div className="text-tooltip">
                  <span
                    className="copy-img"
                    onClick={() =>
                      copyToClipboard(
                        getApiRequest(
                          selectedApiKey.value,
                          allRequestParentData.id
                        ),
                        'request'
                      )
                    }>
                    <img src={Copy} alt="copy" />
                  </span>
                  {copyRequest && <p className="copy-tooltip">{copyRequest}</p>}
                </div>
              ) : (
                '-'
              )}
            </td>
            <td>
              {allRequestParentData.status !== DECLINE ? (
                <div className="text-tooltip">
                  {loading.response ? (
                    <span className="copy-img">
                      <Loader loader="request-loader" />
                    </span>
                  ) : (
                    <span
                      className="copy-img"
                      onClick={() => copyToClipboard(childData, 'response')}>
                      <img src={Copy} alt="copy" />
                    </span>
                  )}
                  {copyResponse && (
                    <p className="copy-tooltip">{copyResponse}</p>
                  )}
                </div>
              ) : (
                '-'
              )}
            </td>
          </>
        )}
        <td>{/* 23/100 */}-</td>
        <td>
          {isAdmin === SUPER_ADMIN ? (
            <>
              {allRequestParentData.status !== DECLINE ? (
                <div className="request-table-calendar">
                  <DatePicker
                    selected={
                      date
                        ? new Date(date)
                        : allRequestParentData?.date_of_delivery
                        ? new Date(allRequestParentData?.date_of_delivery)
                        : null
                    }
                    className="request-calendar"
                    dateFormat="dd/MM/yyyy"
                    minDate={
                      allRequestParentData?.date_of_request
                        ? new Date(allRequestParentData?.date_of_request)
                        : null
                    }
                    placeholderText="-"
                    showYearDropdown
                    scrollableYearDropdown
                    customInput={<ExampleCustomInput />}
                    onChange={(date) => {
                      setDate(date);
                      onUpdateDeliveryDates(allRequestParentData.id, date);
                    }}
                  />
                </div>
              ) : (
                '-'
              )}
            </>
          ) : isAdmin === USER &&
            allRequestParentData.date_of_delivery &&
            allRequestParentData.status !== DECLINE ? (
            moment(allRequestParentData.date_of_delivery).format('DD/MM/YYYY')
          ) : (
            '-'
          )}
        </td>
        {isAdmin === SUPER_ADMIN && (
          <td>
            {allRequestParentData.status === COMPLETED ||
            allRequestParentData.status === INPROGESS ? (
              <span className="action-approve">Approved</span>
            ) : allRequestParentData.status === DECLINE ? (
              <span className="action-reject">Rejected</span>
            ) : (
              <span
                className="action dropdown-toggle"
                data-bs-toggle="dropdown"
                data-backdrop="static"
                data-keyboard="false"
                aria-expanded="false">
                Action
                <img src={ExpandArrow} alt="expand down arrow" />
                <ul className="dropdown-menu option-menu">
                  <li
                    className={
                      date ? 'menu-item approve' : 'menu-item approve-disable'
                    }
                    onClick={(event) => {
                      event.stopPropagation();
                      if (date) {
                        onUpdateActions(allRequestParentData?.id, 'approve');
                      }
                    }}>
                    <label className={date ? 'approve' : 'approve-disable'}>
                      Approve
                    </label>
                  </li>
                  <li
                    className="menu-item reject"
                    onClick={() => setShowRejectPopup(true)}>
                    <label className="reject">Reject</label>
                  </li>
                </ul>
              </span>
            )}
            {allRequestParentData.status === COMPLETED ||
            allRequestParentData.status === DECLINE ? (
              <div className="updated-by-info">
                {allRequestParentData.updated_by}
              </div>
            ) : null}
          </td>
        )}

        {/* {isAdmin === USER && data?.delievery_document !== null ? (
          <td className="docs">
            <a download href={data?.delievery_document}>
              <img src={DownloadDocs} alt="doc icon" />
            </a>
          </td>
        ) : data?.status === REQUEST || data?.status === DECLINE ? (
          <td className="docs">-</td>
        ) : data.status === INPROGESS && isAdmin === USER ? (
          <td className="docs">-</td>
        ) : isAdmin === SUPER_ADMIN ? (
          <td className="docs">
            {data?.delievery_document || data?.status === COMPLETED ? (
              <>
                <span
                  className="docs-image dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <img src={DownloadDocumnet} alt="doc icon" />
                </span>
                <ul className="dropdown-menu docs-menu">
                  <li className="menu-item">
                    <a href={data?.delievery_document} target='_blank'>
                      <span>
                        <img src={View} alt="view icon" />
                      </span>
                      <label>View</label>
                    </a>
                  </li>
                  <li
                    className="menu-item"
                    onClick={() => fileref.current.click()}>
                    <input
                      type="file"
                      ref={fileref}
                      onChange={(e) => onUploadFile(data?.id, e)}></input>
                    <span className="docs-image">
                      <img src={Upload} alt="upload" />
                    </span>
                    <label>Upload</label>
                  </li>
                </ul>
              </>
            ) : (
              <>
                <input
                  type="file"
                  id={data?.id}
                  onChange={(e) => onUploadFile(data?.id, e)}></input>
                <label className="docs-image" htmlFor={data?.id}>
                  <img src={UploadBlack} alt="doc icon" />
                </label>
              </>
            )}
          </td>
        ) : (
          <td className="docs"></td>
        )} */}
      </tr>
      {expand &&
      childData &&
      childData?.data?.features &&
      childData?.data?.features.length !== 0 ? (
        childData?.data?.features.map((item) => {
          return (
            <RequestChildRow
              RequestChildRow={item}
              RequestRowData={allRequestParentData}
              RequestRowIsAdmin={isAdmin}
              requestChilApiKeyOption={requestChilApiKeyOption}
            />
          );
        })
      ) : (
        <>
          {id && allRequestParentData.id === id && (
            <tr className="child-row">
              <td className="no_data_row" colSpan="2">
                No data available
              </td>
            </tr>
          )}
        </>
      )}

      <RejectionModal
        isOpen={showRejectPopup}
        ariaHideApp={false}
        onRequestClose={() => {
          setShowRejectPopup(false);
          setError('');
          setReasonForm({ ...reasonform, reason: '', reason_id: '' });
        }}
        style={customStyles}>
        <div className="popup-wrapper">
          <div className="rejection-header">
            <h1>Rejection reason</h1>
            <label
              onClick={() => {
                setError('');
                setReasonForm({ ...reasonform, reason: '', reason_id: '' });
                setShowRejectPopup(false);
              }}>
              <img src={Close} alt="close icon" />
            </label>
          </div>
          <div className="rejection-content">
            <div className="radio-group-wrapper">
              <div className="radio-group">
                <input
                  type="radio"
                  name="reason"
                  id="not available"
                  onChange={() => {
                    setError('');
                    setReasonForm({ ...reasonform, reason_id: 1 });
                  }}
                  value={reasonform.reason_id}
                />
                <label>Data is Not available</label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="reason"
                  id="error"
                  onChange={() => {
                    setError('');
                    setReasonForm({ ...reasonform, reason_id: 2 });
                  }}
                  value={reasonform.reason_id}
                />
                <label>Error in uploaded AOI</label>
              </div>
              <div className="radio-group">
                <input
                  type="radio"
                  name="reason"
                  id="Other"
                  onChange={() => {
                    setError('');
                    setReasonForm({ ...reasonform, reason_id: 0 });
                  }}
                  value={reasonform.reason_id}
                />
                <label>Other</label>
              </div>
            </div>
            <div className="text-area">
              <textarea
                className={
                  reasonform.reason_id === 0
                    ? 'reason-text selected'
                    : 'reason-text'
                }
                cols={30}
                rows="4"
                value={reasonform.reason}
                onChange={(e) => {
                  setReasonForm({ ...reasonform, reason: e.target.value });
                  setError('');
                }}
                placeholder="Specify details for rejection here.."
              />
              <label
                className={
                  error !== '' ? 'error-text visible' : 'error-text hidden'
                }>
                {error}
              </label>
            </div>
            <div className="button-group popup">
              <button
                className="button-apply"
                onClick={() =>
                  onUpdateActions(allRequestParentData.id, 'reject')
                }>
                Submit
              </button>
              <button
                className="button-cancel popup"
                onClick={() => {
                  setShowRejectPopup(false);
                  setReasonForm({ ...reasonform, reason: '', reason_id: '' });
                }}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      </RejectionModal>
    </>
  );
};

export default RequestRow;
