import { getRequest } from '../utils/http-helper';
import { ROLE_URL, INDUSTRY_URL } from '../utils/url-helper';

export const getIndustry = async () => {
  return await getRequest({
    url: INDUSTRY_URL
  });
};

export const getRole = async () => {
  return await getRequest({
    url: ROLE_URL
  });
};