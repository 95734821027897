import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../components/common-loader';

//Images
import SatsureLogo from '../../assets/images/logo/satsure-logo.svg';
import PasswordHide from '../../assets/images/icons/hide-eye.svg';
import PasswordShow from '../../assets/images/icons/show-eye.svg';
import ConfirmEmail from '../../assets/images/confirm-email-verified.svg';

//Api Services
import { resetPassword } from '../../services/auth-services';

//Helper functions
import { isPasswordValid } from '../../utils/regex-helper';

const ResetPasswordComponent = () => {
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [hideShowPassword, setHideShowPassword] = useState(true);
  const [hideShowConfirmPassword, setHideShowConfirmPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSucceeded, setIsSucceeded] = useState(false);

  const location = useLocation();
  const value = useState(new URLSearchParams(location.search).get('token'));

  const onPasswordChangeHandler = (e) => {
    setPassword(e.target.value);
    setPasswordError('');
  };

  const onConfirmPasswordChangeHandler = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordError('');
  };

  const onSubmit = async () => {
    if (password === '') {
      setPasswordError('Enter Password');
    } else if (password !== '' && !isPasswordValid(password)) {
      setPasswordError(
        'Password must contain at least 8 characters, one upper case, one lower case, one number and one special character.'
      );
    } else if (confirmPassword === '') {
      setConfirmPasswordError('Enter Confirm Password');
    } else if (confirmPassword !== password) {
      setConfirmPasswordError('Confirm Password is Not Matching');
    } else {
      setIsLoading(true);
      let payload = { password: password };
      let mailIdToken = value[0];
      const { status, error } = await resetPassword(payload, mailIdToken);
      if (status === 200) {
        setIsLoading(false);
        setIsSucceeded(true);
      }
      if (error) {
        setIsLoading(false);
        setIsSucceeded(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  return (
    <div className="form-container">
      <div className="common-login-field">
        {isSucceeded ? (
          <div className="reset-success">
            <div className="reset-password-success-header">
              <h1>Password Reset</h1>
              <p>
                Success!!
                <br /> Your password has been successfully reset.
              </p>
            </div>

            <label className="reset-password-success-logo-wrapper">
              <img src={ConfirmEmail} alt="success" />
            </label>

            <div className="reset-password-button-container reset-password-success-login-btn">
              <button onClick={() => navigate('/')}>
                <label> Login </label>
              </button>
            </div>
          </div>
        ) : (
          <>
            <div className="reset-password-header">
              <h1>Reset Password</h1>
              <span>Enter new password to set your password</span>
            </div>
            <div className="reset-password-sections">
              <div className="form-group">
                <span className="input-field-title">Enter New Password</span>
                <span className="input-field">
                  <input
                    type={hideShowPassword ? 'password' : 'text'}
                    className={passwordError ? 'input-text red' : 'input-text grey'}
                    placeholder="Password"
                    name="password"
                    onChange={(e) => onPasswordChangeHandler(e)}
                    onKeyDown={(e) => e.key === 'Enter' && onSubmit('next')}
                  />

                  <img
                    src={hideShowPassword ? PasswordHide : PasswordShow}
                    alt="passwordLogo"
                    onClick={() => setHideShowPassword((prev) => !prev)}
                    className="passwordIcon"
                  />
                </span>

                <label
                  className={
                    passwordError ? 'error-text visible' : 'error-text hidden'
                  }>
                  {passwordError}
                </label>
              </div>
              {password !== "" && (
                <ul className="password-checker">
                  <li className={/[!@#$%^&*()_+{}\[\]:;<>,.?~\\|]/.test(password) && "password-success"}>
                    <label>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M3 8L6 11L13 4" stroke="#95969D" stroke-width="1.5" stroke-linecap="round" />
                      </svg>
                    </label>
                    <span>One Special Character</span></li>
                  <li className={/\d/.test(password) && ("password-success")}>
                    <label>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M3 8L6 11L13 4" stroke="#95969D" stroke-width="1.5" stroke-linecap="round" />
                      </svg>
                    </label>
                    <span>One Number</span></li>
                  <li className={/[A-Z]/.test(password) && ("password-success")}>
                    <label>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M3 8L6 11L13 4" stroke="#95969D" stroke-width="1.5" stroke-linecap="round" />
                      </svg>
                    </label>
                    <span>One Uppercase Character</span></li>
                  <li className={password.length >= 8 && ("password-success")}>
                    <label>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M3 8L6 11L13 4" stroke="#95969D" stroke-width="1.5" stroke-linecap="round" />
                      </svg>
                    </label>
                    <span>8 Characters Minimum</span></li>
                </ul>
              )}
              <div className="form-group">
                <span className="input-field-title">Re-enter New Password <sup>*</sup></span>
                <span className="input-field">
                  <input
                    type={hideShowConfirmPassword ? 'password' : 'text'}
                    className={
                      confirmPasswordError ? 'input-text red' : 'input-text grey'
                    }
                    placeholder="Re-enter password"
                    name="confirmPassword"
                    onChange={(e) => onConfirmPasswordChangeHandler(e)}
                    onKeyDown={(e) => e.key === 'Enter' && onSubmit('next')}
                  />

                  <img
                    src={hideShowConfirmPassword ? PasswordHide : PasswordShow}
                    alt="passwordLogo"
                    onClick={() => setHideShowConfirmPassword((prev) => !prev)}
                    className="passwordIcon"
                  />
                </span>

                <label
                  className={
                    confirmPasswordError
                      ? 'error-text visible'
                      : 'error-text hidden'
                  }>
                  {confirmPasswordError}
                </label>
              </div>

              <div className="reset-password-button-container">
                <button onClick={() => onSubmit()}>
                  <label> Reset Password </label>

                  {isLoading ? (
                    <Loader loader={'common-btn-loader'}></Loader>
                  ) : null}
                </button>
              </div>
            </div>
          </>
        )}
        <div className="powered-by">
          <div className="powered-by-wrapper">
            <label>Powered by</label>
            <div>
              <img src={SatsureLogo} alt="Satsure Logos" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordComponent;
