import React, { useState } from 'react';

import Profile from '../../components/profile';
import Sidebar from '../../components/sidebar/sidebar';
import UpdatePassword from '../../components/updatepassword/updatepassowrd.jsx';
import ApiAnalytics from '../../components/api-analytics';
import ApiKey from '../../components/apiKey/apiKey';

import './index.css';

const Setting = () => {
  const [active, setActive] = useState('profile');

  return (
    <div className="setting-container">
      <div className="setting-sections">
        <Sidebar tab="setting" active={active} setActive={setActive} />
      </div>
      <div className="setting-list">
        {active === 'profile' && <Profile />}
        {active === 'password' && <UpdatePassword />}
        {active === 'apiAnalytics' && <ApiAnalytics />}
        {active === 'apiKey' && <ApiKey />}
      </div>
    </div>
  );
};

export default Setting;
