import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../components/common-loader';

//Api services
import { sendResetPasswordLink } from '../../services/auth-services';

// Images
import SatsureLogo from '../../assets/images/logo/satsure-logo.svg';

//Helper functions
import { isValidEmail as checkIsValidEmail } from '../../utils/regex-helper';

const ForgotPasswordComponent = () => {
  const [email, setEmail] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestFailed, setIsRequestFailed] = useState(false);
  const [isRequestSucceeded, setIsRequestSucceeded] = useState(false);

  const onChangeHandler = (e) => {
    setEmail(e.target.value);
    setIsValidEmail(false);
    setIsRequestFailed(false);
    setIsRequestSucceeded(false);
  };

  const onResetPassword = async () => {
    if (checkIsValidEmail(email)) {
      setIsLoading(true);
      const { status, error } = await sendResetPasswordLink(email);
      if (status === 200) {
        setIsRequestFailed(false);
        setIsLoading(false);
        setIsRequestSucceeded(true);
      }
      if (error) {
        setIsLoading(false);
        setIsRequestFailed(true);
        setIsRequestSucceeded(false);
        toast.error(error?.response?.data?.message);
      }
    } else {
      setIsValidEmail(true);
    }
  };

  return (
    <div className="form-container">
      <div className="common-login-field">
        <div className="forgot-password-header">
          <h1>Forgot Password ?</h1>
          <span>Enter email address you use to log in</span>
        </div>
        <div className="forgot-password-sections">
          <div className="form-group">
            <span className="input-field-title">Email <sup>*</sup></span>
            <span className="input-field">
              <input
                type="email"
                name="email"
                value={email}
                className={isValidEmail ? 'input-text red' : 'input-text grey'}
                placeholder="Email"
                onChange={(e) => onChangeHandler(e)}
                onKeyDown={(e) => e.key === 'Enter' && onResetPassword()}
              />
            </span>
            <label
              className={
                isValidEmail ? 'error-text visible' : 'error-text hidden'
              }>
              Please Enter Valid Email
            </label>
            {isRequestFailed ? (
              <p className="error-status-text">
                Error!!
                <br />
                The email address you entered is Incorrect or is not associated
                with SatSure Sparta.
              </p>
            ) : null}

            {isRequestSucceeded ? (
              <p className="success-status-text">
                Success!!
                <br />A link will be sent to your email address to reset the
                password.
              </p>
            ) : null}
          </div>

          <div className="forgot-password-button-container">
            <button onClick={() => onResetPassword()}>
              <label> Reset Password </label>
              {isLoading ? <Loader loader={'common-btn-loader'}></Loader> : null}
            </button>
            <div className="back-to-login-link">
              <Link className="href-tag" to="/">back to Log in</Link>
            </div>
          </div>
        </div>

        {isRequestFailed ? (
          <div className="support-wrapper">
            <label>For any further assistance reach out to us at</label>
            <a href="mailto:support@sparta.com">
              <u>support@sparta.com</u>
            </a>
          </div>
        ) : (
          <div className="powered-by">
            <div className="powered-by-wrapper">
              <label>Powered by</label>
              <div>
                <img src={SatsureLogo} alt="Satsure Logos" />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPasswordComponent;
