import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './apiKey.css';
import ApiKeyRow from "../apiKey-row"
import {
  getApiKeyValue,
  putApiKeyValue,
  postApiKeyValue
} from '../../services/setting-services';
import { toast } from 'react-toastify';

import AddLogo from '../../assets/images/icons/add.svg';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px', width: '375px',
    borderRadius: '8px',
  }
};
const ApiKey = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [keyPopupStatus, setKeyPopupStatus] = useState();
  const [keyPopupTitle, setKeyPopupTitle] = useState();
  const [keyPopupMessage, setKeyPopupMessage] = useState();
  const [apiKeyValue, setApiKeyValue] = useState([]);
  const [onChangeApiKeyValue, setOnChangeApiKeyValue] = useState("");
  const [apiKeyGenerated, setApiKeyGenerated] = useState();
  const [inputApiKeyName, setInputApiKeyName] = useState(false);

  useEffect(() => {
    getApiDataValue();
  }, []);

  const getApiDataValue = async () => {
    const { data, status, error } = await getApiKeyValue();
    if (status === 200) {
      setApiKeyValue(data.data);
    }
    if (error) {
      toast.error(error.response?.data?.message);
    }
  };

  const regenerationApiKey = async (keyStatus) => {
    let payload = {
      name: "string",
      is_regenerate: true
    }
    let apiKeyId = keyStatus.id;
    const { data, status } = await putApiKeyValue(payload, apiKeyId);
    if (status === 200) {
      setIsOpen(true);
      setApiKeyGenerated(data.data);
      setKeyPopupTitle(data.message);
      setKeyPopupMessage("regenerated");
    }
  };

  const handleKeyStatus = async (keyStatus) => {
    let payload = {
      name: "string",
      is_active: keyStatus.is_active ? false : true,
    }
    let apiKeyId = keyStatus.id;
    const { data, status } = await putApiKeyValue(payload, apiKeyId);
    if (status === 200) {
      const index = apiKeyValue.findIndex(item => item.id === data.data.id)
      const newArray = [...apiKeyValue]
      newArray.splice(index, 1, data.data)
      setApiKeyValue(newArray)
      setApiKeyGenerated(data.data);
      setKeyPopupTitle(data.message);
      setKeyPopupMessage("updated");
    }
  }

  const createApiKey = (key, title) => {
    setKeyPopupStatus(key);
    setKeyPopupTitle(title);
    setIsOpen(true);
    setKeyPopupMessage("created");
  }

  const createApiKeyUpdate = async (key) => {
    if (onChangeApiKeyValue.trim() !== "") {
      setKeyPopupStatus(key);
      let payload = {
        name: onChangeApiKeyValue
      };
      const { data, status, error } = await postApiKeyValue(payload);
      if (status === 200) {
        setApiKeyGenerated(data.data);
        setKeyPopupTitle(data.message);
      }
      if (error) {
        toast.error(error.response?.data?.message);
      }
    } else {
      setInputApiKeyName(true);
    }
  }

  const keyGenerationSuccess = async () => {
    setIsOpen(false);
    getApiDataValue();
    setOnChangeApiKeyValue("");
  }

  const apiKeyValueCancel = () => {
    setIsOpen(false);
    setOnChangeApiKeyValue("");
    setKeyPopupStatus("");
    setApiKeyGenerated("");
    setKeyPopupTitle("");
  }
  return (
    <div className="profile-container">
      <div className="apiKeyHeader">
        <div className='api-key-text'>
          <h1>API Key</h1>
          <p>Manage your API Keys</p>
        </div>
        <div className='create-api-key' onClick={() => { createApiKey("createApiKey", "Create New API Key") }}>
          <label>
            <img className="add-img" src={AddLogo} alt="Add Logo">
            </img>
          </label>
          <span>Create New API Key</span>
        </div>
      </div>

      <table className="product-table">
        <thead>
          <tr className="product-row">
            <th className="width-17">Label</th>
            <th className="width-32">API Key</th>
            <th className="width-17">Created</th>
            <th className="width-17">Status</th>
            <th className="width-17">Actions</th>
          </tr>
        </thead>
        <tbody>
          {apiKeyValue.map((key) => {
            return (
              <ApiKeyRow
                apiKey={key}
                handleKeyStatus={handleKeyStatus}
                regenerationApiKey={regenerationApiKey}
              />
            );
          })}
        </tbody>
      </table>

      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        style={customStyles}
        onRequestClose={apiKeyValueCancel}
        contentLabel="Example Modal"
      >
        <div className="api-key-modal-container">
          <p className="api-key-popup">
            {keyPopupTitle}
          </p>
          <div className='api-key-body'>
            {keyPopupStatus === "createApiKey" ?
              (<>
                <div className='key-name'>
                  <label>Key Name</label>
                  <span className="common-input-feild">
                    <input
                      type="text"
                      name="text"
                      value={onChangeApiKeyValue}
                      className={inputApiKeyName ? "input-text red" : "input-text"}
                      onChange={(e) => {
                        setInputApiKeyName(false);
                        setOnChangeApiKeyValue(e.target.value)
                      }}
                    />
                  </span>
                </div>
                <div className="common-modal-btn">
                  <button
                    className="color-btn margin-right"
                    onClick={() => {
                      createApiKeyUpdate("apiKeyGenerated");
                    }}>
                    Create
                  </button>
                  <button className="without-color-btn discard-btn" onClick={() => { apiKeyValueCancel() }}>
                    Cancel
                  </button>
                </div>
              </>
              ) : (
                <>
                  <ul className='key-success'>
                    <li>Your key has been {keyPopupMessage}.</li>
                    <li className='name-key'>
                      <label>Key Name :</label>
                      <span>{apiKeyGenerated?.name}</span>
                    </li>
                    <li className='name-key'>
                      <label>Key ID :</label>
                      <span>{apiKeyGenerated?.value}</span>
                    </li>
                  </ul>
                  <div className="common-modal-btn">
                    <button className="color-btn" onClick={() => { keyGenerationSuccess() }}>
                      Ok
                    </button>
                  </div></>)}
            <>
            </>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ApiKey;
