import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './index.css';
import { salesData } from '../../assets/json/industry';

const AgInputCompanies = () => {
  const location = useLocation();
  const [data, setData] = useState({ points: [] });

  const myRef = useRef(null);
  const sideNavRef = useRef(null);


  const handleSideNavScroll = (e, sectionId) => {
    e.preventDefault();
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (location.hash !== '') {
      setData(salesData.filter((item) => item.id === location.hash));
    }
  }, [location.hash]);
  useEffect(() => {
    if (myRef.current) {
      myRef.current.scrollIntoView();
    }
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="documentation-product-container">
      <div className="documentations-wrapper">
        <div className="document-content-sections">
          <div ref={myRef} className="document-agri-intro">
            <h4>
              Industry/ {data[0]?.head || ''} / {data[0]?.title || ''}
            </h4>
            <h2>{(data[0] && data[0]?.title) || ''}</h2>
            <hr />
          </div>
          <div className="intro-section">
            <label id="section-2" className="document-heading">
              Use Case
            </label>
            {data[0]?.intro_para && (
              <p className="document-agri-common-para">{data[0]?.intro_para}</p>
            )}
          </div>
          {data[0]?.intro_1 && (
            <p className="document-common-para margin-top">
              {data[0]?.intro_1}
            </p>
          )}

          <div className="intro-section">
            {data[0]?.method && (
              <p className="document-agri-common-para margin-40">
                {data[0]?.method}
              </p>
            )}

            {data[0]?.shares &&
              data[0]?.shares.map((shares, index) => (
                <div key={index}>
                  <h2 className="agri-head margin-top">{shares.title}</h2>
                  {Array.isArray(shares.description) ? (
                    <ul className="bullet-list">
                      {shares.description.map((point, pointIndex) => (
                        <li key={pointIndex}>{point}</li>
                      ))}
                    </ul>
                  ) : (
                    <p className="agri-para">{shares.description}</p>
                  )}
                </div>
              ))}

            {data[0]?.method_des && (
              <p className="document-common-para margin-top">
                {data[0]?.method_des}
              </p>
            )}

            <div className="document-common-para">
              <h2 id="section-3" className="document-agri-common-para margin-40">
                {data[0]?.challenge_head}
              </h2>
              {Array.isArray(data[0]?.challenges) &&
                data[0]?.challenges.map((challenges, index) => (
                  <div key={index}>
                    <h2 className="agri-head margin-top">{challenges.title}</h2>
                    <p className="agri-para ">{challenges.description}</p>
                  </div>
                ))}

              {data[0]?.chemChal &&
                data[0]?.chemChal.map((chemChal, index) => (
                  <div key={index}>
                    <h2 className="agri-head margin-top">{chemChal.title}</h2>
                    <ul className="bullet-list">
                      {chemChal.points?.map((point, pointIndex) => (
                        <li key={pointIndex}>{point}</li>
                      ))}
                    </ul>
                  </div>
                ))}
            </div>

            <div id="section-4" className="document-common-para">
              <h2 className="document-agri-common-para margin-40">
                {data[0]?.solution_head}
              </h2>
              {data[0]?.solution.map((solution, index) => (
                <div key={index}>
                  <h2 className="agri-head margin-top">{solution.title}</h2>
                  <p className="agri-para ">{solution.description}</p>
                </div>
              ))}
            </div>
            {location.hash === '#food' && (
              <div className="document-common-para">
                <label id="section-2" className="document-heading margin-40">
                  {data[0]?.title_2}
                </label>
                {data[0]?.intro_2_dis && (
                  <p className="document-agri-common-para">
                    {data[0]?.intro_2_dis}
                  </p>
                )}
                {data[0]?.intro_2 && (
                  <p className="document-common-para margin-top">
                    {data[0]?.intro_2}
                  </p>
                )}

                {data[0]?.method_2 && (
                  <p className="document-agri-common-para margin-40">
                    {data[0]?.method_2}
                  </p>
                )}
                {Array.isArray(data[0]?.shares_details) &&
                  data[0]?.shares_details.map((shares_details, index) => (
                    <div key={index}>
                      <p className="agri-para ">{shares_details.description}</p>
                    </div>
                  ))}
                <h2 className="document-agri-common-para margin-40">
                  {data[0]?.challenge_head}
                </h2>
                {data[0]?.challenges_det?.map((challenges_det, index) => (
                  <div key={index}>
                    <h2 className="agri-head margin-top">
                      {challenges_det.title}
                    </h2>
                    <p className="agri-para ">{challenges_det.description}</p>
                  </div>
                ))}
                <h2 className="document-agri-common-para margin-40">
                  {data[0]?.solution_head}
                </h2>
                {data[0]?.solution?.map((solution_2, index) => (
                  <div key={index}>
                    <h2 className="agri-head margin-top">{solution_2.title}</h2>
                    <p className="agri-para ">{solution_2.description}</p>
                  </div>
                ))}
                <h2 className="document-agri-common-para margin-40">
                  {data[0]?.benefits_head}
                </h2>
                {data[0]?.benefits_details?.map((benefits_details, index) => (
                  <div key={index}>
                    <ul className="bullet-list">
                      <li>{benefits_details.description}</li>
                    </ul>
                  </div>
                ))}
              </div>
            )}
          </div>
          <h3 className="read-more margin-top">Read More</h3>
        </div>
      </div>

      <div className="side-nav-container" ref={sideNavRef}>
        <h2 className="sidenav-header">On this Page</h2>
        <div>
          <li>
            <a
              href="#section-2"
              onClick={(e) => handleSideNavScroll(e, 'section-2')}>
              Use Case
            </a>
          </li>
          <li>
            <a
              href="#section-3"
              onClick={(e) => handleSideNavScroll(e, 'section-3')}>
              Challenges
            </a>
          </li>
          <li>
            <a
              href="#section-4"
              onClick={(e) => handleSideNavScroll(e, 'section-4')}>
              Sparta Solutions
            </a>
          </li>
        </div>
      </div>
    </div>
  );
};

export default AgInputCompanies;
