import React, { useState } from 'react';
import Select from 'react-select';
import Loader from '../../components/common-loader';
import { Link, useNavigate } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { toast } from 'react-toastify';

// Images
import satsureLogo from '../../assets/images/logo/satsure-logo.svg';
import passwordshow from '../../assets/images/icons/show-eye.svg';
import passwordhide from '../../assets/images/icons/hide-eye.svg';
// import Info from '../../assets/images/icons/info.svg';

// Api services
import { getNewCreateUser } from '../../services/auth-services';
import { getIndustry, getRole } from '../../services/industry-role-services';

// Helper functions
import { isPasswordValid, isValidEmail } from '../../utils/regex-helper';

const SignupComponent = () => {
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [hideShowPwd, setHideShowPwd] = useState(true);
  const [hideShowRePwd, setHideShowRePwd] = useState(true);
  const [tab, setTab] = useState(1);
  const [industryOption, setIndustryOption] = useState([]);
  const [roleOption, setRoleOption] = useState([]);
  const [selectedOption, setSelectedOption] = useState({
    industry: null,
    role: null
  });

  const [signupLoaderStatus, setSignUpLoaderStatus] = useState({
    userLoaderStatus: false
  });

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
    industry: '',
    role: '',
    industryName: '',
    roleName: ''
  });

  const [error, setError] = useState({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    confirmPassword: '',
    industry: '',
    role: '',
    industryName: '',
    roleName: ''
  });
  /**
   *
   * @param {*} e
   */

  const onchangeHandler = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    switch (name) {
      case 'firstname':
        setError((prevErrors) => ({
          ...prevErrors,
          firstname: value ? '' : 'First name is required.'
        }));
        break;
      case 'lastname':
        setError((prevErrors) => ({
          ...prevErrors,
          lastname: value ? '' : 'Last name is required.'
        }));
        break;
      case 'email':
        setError((prevErrors) => ({
          ...prevErrors,
          email: value ? '' : 'Email is required.'
        }));
        break;
      case 'password':
        setError((prevErrors) => ({
          ...prevErrors,
          password: value ? '' : 'Password is required.'
        }));
        break;
      case 'confirmPassword':
        setError((prevErrors) => ({
          ...prevErrors,
          confirmPassword: value ? '' : 'Please re-enter your password.'
        }));
        break;
      default:
        break;
    }
  };

  const getButtonDisabled = (formData) => {
    if (formData?.firstname !== '' &&
      formData?.lastname !== '' &&
      formData?.email !== '' &&
      formData?.password !== '' &&
      formData?.confirmPassword !== '') {
      return false;
    } else {
      return true;
    }
  }

  const getRegisterDisabled = (selectedOption) => {
    if (selectedOption?.industry !== null &&
      selectedOption?.role !== null) {
      return false;
    } else {
      return true;
    }
  }

  /**
   *
   *
   */

  const getIndustryOptions = async () => {
    const { data, status } = await getIndustry();
    if (status === 200) {
      let arr = data.data.map((item) => {
        return {
          label: item.name,
          value: item.id
        };
      });
      setIndustryOption([...arr]);
    }
  };

  const getRoleOptions = async () => {
    const { data, status } = await getRole();
    if (status === 200) {
      let arr = data.data.map((item) => {
        return {
          label: item.name,
          value: item.id
        };
      });
      setRoleOption([...arr]);
    }
  };

  const onSubmit = async (type) => {
    if (type === 'next') {
      let obj = { ...error };
      if (formData.firstname === '') {
        obj.firstname = 'Enter First Name';
      }
      if (formData.lastname === '') {
        obj.lastname = 'Enter Last Name';
      }
      if (formData.email === '') {
        obj.email = 'Enter Email Address';
      }
      if (formData.email !== '' && !isValidEmail(formData.email)) {
        obj.email = 'Enter Valid Email Address';
      }
      if (formData.password === '') {
        obj.password = 'Enter Password';
      }
      if (formData.password !== '' && !isPasswordValid(formData.password)) {
        obj.password =
          'Password must contain atleast 8 characters, one upper case, one lower case, one number and one special character (@$!%*?&^#).';
      }
      if (formData.confirmPassword === '') {
        obj.confirmPassword = 'Enter Confirm Password';
      }
      if (formData.confirmPassword !== formData.password) {
        obj.confirmPassword = 'Confirm Password is Not Matching';
      }
      setError(obj);

      if (
        obj.firstname === '' &&
        obj.lastname === '' &&
        obj.email === '' &&
        obj.password === '' &&
        obj.confirmPassword === ''
      ) {
        getIndustryOptions();
        getRoleOptions();
        setTab(2);
      }
    } else if (type === 'register') {
      let regobj = { ...error };
      if (selectedOption.industry === null) {
        regobj.industry = 'Please Select Industry';
      }
      if (selectedOption.role === null) {
        regobj.role = 'Please Select Role';
      }

      if (
        selectedOption?.industry?.value === 4 &&
        formData.industryName === ''
      ) {
        regobj.industryName = 'Enter Industry Name';
      }
      if (selectedOption?.role?.value === 4 && formData.roleName === '') {
        regobj.roleName = 'Enter Role';
      }
      setError(regobj);
      const token = await executeRecaptcha('register');
      let createSignupUser = {
        name: [formData.firstname, formData.lastname].join(' '),
        email: formData.email.toLowerCase(),
        mobile: '9994556719',
        password: formData.password,
        role_id: selectedOption.role?.value,
        role_name: formData.roleName,
        industry_id: selectedOption.industry?.value,
        industry_name: formData.industryName,
        captcha_token: token,
        google_token: ''
      };

      if (
        regobj.industry === '' &&
        regobj.industryName === '' &&
        regobj.role === '' &&
        regobj.roleName === ''
      ) {
        setSignUpLoaderStatus({ signupLoaderStatus, userLoaderStatus: true });
        const { data, status, error } = await getNewCreateUser(
          createSignupUser
        );
        if (status === 200) {
          setSignUpLoaderStatus({
            signupLoaderStatus,
            userLoaderStatus: false
          });
          if (data?.status) {
            if (data?.data?.isVerified === false) {
              navigate('/email-verification', {
                state: { email: data?.data?.userEmail }
              });
            }
          } else {
            toast.error(data?.message);
          }
        }
        if (error) {
          toast.error(error?.response?.data?.message);
        }
      }
    }
  };

  const onChangeIndustry = (e) => {
    setError({ ...error, industry: '' });

    if (e.value !== 4) {
      setFormData({ ...formData, industryName: '' });
    }
  };

  const onChangeRole = (e) => {
    setError({ ...error, role: '' });
    if (e.value !== 4) {
      setFormData({ ...formData, roleName: '' });
    }
  };

  const onChangeIndustryName = (e) => {
    setError({ ...error, industryName: '' });
  };

  const onChangeRoleName = (e) => {
    setError({ ...error, roleName: '' });
  };

  return (
    <div className="form-container">
      <div className="common-login-field">
        <h1 className="heading">Register Now</h1>
        {tab === 1 ? (
          <div className="login-content">
            <div className="name-wrapper">
              <div className="form-group">
                <label className="group-title">
                  First Name<sup>*</sup>
                </label>
                <span className="input-feild">
                  <input
                    type="text"
                    className={
                      error.firstname ? 'input-text red' : 'input-text grey'
                    }
                    placeholder="First Name"
                    name="firstname"
                    onChange={(e) => onchangeHandler(e)}
                    onKeyDown={(e) => e.key === 'Enter' && onSubmit('next')}
                  />
                </span>

                <label
                  className={
                    error.firstname ? 'error-text visible' : 'error-text hidden'
                  }>
                  {error.firstname}
                </label>
              </div>
              <div className="form-group">
                <label className="group-title">
                  Last Name<sup>*</sup>
                </label>
                <span className="input-feild">
                  <input
                    type="text"
                    className={
                      error.lastname ? 'input-text red' : 'input-text grey'
                    }
                    placeholder="Last Name"
                    name="lastname"
                    onChange={(e) => onchangeHandler(e)}
                    onKeyDown={(e) => e.key === 'Enter' && onSubmit('next')}
                  />
                </span>
                <label
                  className={
                    error.lastname ? 'error-text visible' : 'error-text hidden'
                  }>
                  {error.lastname}
                </label>
              </div>
            </div>
            <div className="form-group">
              <label className="group-title">
                Email<sup>*</sup>
              </label>
              <span className="input-feild">
                <input
                  type="email"
                  className={error.email ? 'input-text red' : 'input-text grey'}
                  placeholder="Enter email address"
                  name="email"
                  onChange={(e) => onchangeHandler(e)}
                  onKeyDown={(e) => e.key === 'Enter' && onSubmit('next')}
                />
              </span>
              <label
                className={
                  error.email ? 'error-text visible' : 'error-text hidden'
                }>
                {error.email}
              </label>
            </div>
            <div className="form-group">
              <label className="group-title">
                Password<sup>*</sup>
              </label>
              <span className="input-feild">
                <input
                  type={hideShowPwd ? 'password' : 'text'}
                  className={
                    error.password ? 'input-text red' : 'input-text grey'
                  }
                  placeholder="Enter password"
                  name="password"
                  onChange={(e) => onchangeHandler(e)}
                  onKeyDown={(e) => e.key === 'Enter' && onSubmit('next')}
                />
                <img
                  src={hideShowPwd ? passwordhide : passwordshow}
                  alt="passwordLogo"
                  onClick={() => setHideShowPwd((prev) => !prev)}
                  className="passwordIcon"
                />
              </span>
              {/* <label
                className={
                  error.password ? 'error-text visible' : 'error-text hidden'
                }>
                {error.password}
              </label> */}
            </div>
            {formData.password !== '' && (
              <ul className="password-checker">
                <li
                  className={
                    /[!@#$%^&*()_+{}\[\]:;<>,.?~\\|]/.test(formData.password) &&
                    'password-success'
                  }>
                  <label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none">
                      <path
                        d="M3 8L6 11L13 4"
                        stroke="#95969D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  </label>
                  <span>One Special Character</span>
                </li>
                <li
                  className={
                    /\d/.test(formData.password) && 'password-success'
                  }>
                  <label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none">
                      <path
                        d="M3 8L6 11L13 4"
                        stroke="#95969D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  </label>
                  <span>One Number</span>
                </li>
                <li
                  className={
                    /[A-Z]/.test(formData.password) && 'password-success'
                  }>
                  <label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none">
                      <path
                        d="M3 8L6 11L13 4"
                        stroke="#95969D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  </label>
                  <span>One Uppercase Character</span>
                </li>
                <li
                  className={
                    formData.password.length >= 8 && 'password-success'
                  }>
                  <label>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none">
                      <path
                        d="M3 8L6 11L13 4"
                        stroke="#95969D"
                        stroke-width="1.5"
                        stroke-linecap="round"
                      />
                    </svg>
                  </label>
                  <span>8 Characters Minimum</span>
                </li>
              </ul>
            )}

            <div className="form-group">
              <label className="group-title">
                Re-enter Password<sup>*</sup>
              </label>
              <span className="input-feild">
                <input
                  disabled={!isPasswordValid(formData.password)}
                  type={hideShowRePwd ? 'password' : 'text'}
                  className={
                    error.confirmPassword ? 'input-text red' : 'input-text grey'
                  }
                  placeholder="Re-enter password"
                  name="confirmPassword"
                  onChange={(e) => onchangeHandler(e)}
                  onKeyDown={(e) => e.key === 'Enter' && onSubmit('next')}
                />
                <img
                  src={hideShowRePwd ? passwordhide : passwordshow}
                  alt="passwordLogo"
                  onClick={() => setHideShowRePwd((prev) => !prev)}
                  className="passwordIcon"
                />
              </span>
              <label
                className={
                  error.confirmPassword
                    ? 'error-text visible'
                    : 'error-text hidden'
                }>
                {error.confirmPassword}
              </label>
            </div>

            <div className="register-button">
              <button
                className="btn-register"
                onClick={() => onSubmit('next')}
                disabled={getButtonDisabled(formData)}>
                <label>Continue</label>
              </button>
            </div>
            <div className="signIn-link">
              <label>Already have an account?</label>
              <Link to="/">Sign in</Link>
            </div>
          </div>
        ) : tab === 2 ? (
          <>
            <div className="industry-role">
              <label className="mail-text">{formData.email}</label>
              <div className="select-sections">
                <>
                  <label>
                    Industry<sup>*</sup>
                  </label>
                  <Select
                    placeholder="Select industry"
                    classNamePrefix={
                      error.industry
                        ? 'select_red react-select'
                        : 'react-select'
                    }
                    options={industryOption}
                    value={selectedOption.industry}
                    onChange={(e) => {
                      onChangeIndustry(e);
                      setSelectedOption({ ...selectedOption, industry: e });
                    }}
                  />
                  <span
                    className={
                      error.industry
                        ? 'error-text visible'
                        : 'error-text hidden'
                    }>
                    {error.industry}
                  </span>
                </>
                {selectedOption?.industry?.label === 'Others' && (
                  <>
                    <span className="input-feild">
                      <input
                        type="text"
                        className={
                          error.industryName
                            ? 'input-text red'
                            : 'input-text grey'
                        }
                        name="industryName"
                        value={formData.industryName}
                        placeholder="Please Specify..."
                        onChange={(e) => {
                          onchangeHandler(e);
                          onChangeIndustryName(e);
                        }}
                      />
                    </span>
                    <span
                      className={
                        error.industryName
                          ? 'error-text visible'
                          : 'error-text hidden'
                      }>
                      {error.industryName}
                    </span>
                  </>
                )}
              </div>
              <div className="select-sections">
                <>
                  <label>
                    Role<sup>*</sup>
                  </label>
                  <Select
                    placeholder="Select role"
                    classNamePrefix={
                      error.role ? 'select_red react-select' : 'react-select'
                    }
                    options={roleOption}
                    value={selectedOption.role}
                    onChange={(e) => {
                      onChangeRole(e);
                      setSelectedOption({ ...selectedOption, role: e });
                    }}
                  />
                  <span
                    className={
                      error.role ? 'error-text visible' : 'error-text hidden'
                    }>
                    {error.role}
                  </span>
                </>

                {selectedOption?.role?.label === 'Others' && (
                  <>
                    <span className="input-feild">
                      <input
                        type="text"
                        className={
                          error.roleName ? 'input-text red' : 'input-text grey'
                        }
                        placeholder="Please Specify..."
                        name="roleName"
                        value={formData.roleName}
                        onChange={(e) => {
                          onchangeHandler(e);
                          onChangeRoleName(e);
                        }}
                      />
                    </span>
                    <span
                      className={
                        error.roleName
                          ? 'error-text visible'
                          : 'error-text hidden'
                      }>
                      {error.roleName}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="register-button">
              <button
                className="btn-register"
                disabled={getRegisterDisabled(selectedOption)}
                onClick={() => onSubmit('register')}>
                <label>Register</label>
                {signupLoaderStatus.userLoaderStatus ? (
                  <Loader loader={'common-btn-loader'}></Loader>
                ) : null}
              </button>
            </div>
          </>
        ) : null}
        <div class="powered-by-wrapper">
        <label>Powered by</label>
          <div className="satsure-logo">
            <img alt="Satsure" src={satsureLogo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignupComponent;
