export const salesData = [
  {
    id: '#sales',
    head: 'Aginput Companies',
    title: 'Sales and Marketing',
    intro_para:
      'Improving Sales Efficiency for Farm Equipment-as-a-Service Model',
    intro_1:
      'Make data-driven decisions for the entire sales cycle, from demand estimation to sales liquidation through accurate spatial crop acreage and farm boundaries distribution. Get near real-time updates for timely sales and marketing intervention to make the most of in-season crop performance. Learn about how our client improved sales efficiency by solving their challenges of lack of credibility and on-time data on granular crop information.',
    method: 'Current Industry Process',
    method_des:
      'The Field team manually scouts and acquires data. Sales representatives then use these data to derive insights to identify customers, divide the entire region into zones, estimate demand from each zone and allocate the required field resources. Marketing and sales teams design their campaigns based on historical data and estimated demand from current data.',
    queryparams: '',
    challenge_head: 'Challenges',
    challenges: [
      {
        title: 'Demand Estimation',
        description:
          'Deploying a field team is costly, and needs extensive human intervention resulting in biased and inaccurate data.'
      },
      {
        title: 'Sales Planning',
        description:
          'Lack of real-time and unstructured data makes identifying potential clients and accurately estimating demand inside each zone challenging.'
      },
      {
        title: 'Field team management',
        description:
          'Lack of in-season farm-level data makes it challenging to direct the field team to the potential clients at the right time.'
      },
      {
        title: 'In-season sales and marketing interventions',
        description:
          'Misalignment between on-ground data and the sales strategy due to the lack of near-real-time data leading to low-impact campaigns.'
      }
    ],
    solution_head: 'SatSure Sparta Solutions',
    solution: [
      {
        title: 'Demand Estimation',
        description:
          'Spatially distributed Crop Classification Information (Historical And In-Season data) helps in demand estimation leading to supply chain optimisation by offering the best-fitted product, well suitable for a crop stage and location.'
      },
      {
        title: 'Sales Planning',
        description:
          'Crop Health  Detection at the Farm Level enabled by Automated Farm Boundary helps in product positioning empowering Targeted Sales.'
      },
      {
        title: 'Field team management',
        description:
          'Near-real-time Sowing And Harvest Progression with In-season continuous crop monitoring through SatSure Cygnus enables timely resource deployment to targeted customers, optimising field operations.'
      },
      {
        title: 'In-season sales and marketing interventions',
        description:
          'Near-real-time crop health updates enable the sales team to take the required in-season interventions.'
      }
    ],
    child: []
  },

  {
    id: '#chemical',
    head: 'Aginput Companies',
    title: 'R&D of seed companies',
    intro_para:
      'Field testing of new products by creating digital twins using remote sensing',
    intro_1:
      'Get on-demand, near real-time and accurate location-specific crop monitoring capability for efficient R&D operations. Reduce the lab to market time for new input products by continuous crop monitoring in all weather conditions with our patented solution of cloud elimination - SatSure Cygnus.',
    method: 'Current Industry Process',
    shares: [
      {
        title: 'Current technology in use: Remote sensing & IoT Devices.',
        description:
          'Remote sensing technologies, such as satellites, drones (UAVs), capture imagery and collect data on crop health and performance. These sensors, including multispectral, hyperspectral, and thermal cameras, provide information on plant vigour, water stress, and pest/disease infestation. This data assists in selecting and prioritising geographical areas. Additionally, sensor networks in the field enable continuous monitoring of various crop parameters, including soil moisture, temperature, humidity, rainfall, and other relevant climate factors.'
      },
      {
        title: 'Field Scouting and Manual Observations',
        description:
          'Field scouts and agronomists are employed to visually assess crops in the field, recording observations on plant height, leaf colour, disease symptoms, weed pressure, and other visual indicators of plant health and performance. They may use specialised tools like handheld sensors to measure factors like chlorophyll content or leaf area index. Alternative data are also used, which are collected by IoT devices, commercial satellites and Government databases.'
      },
      {
        title: 'Building Data Reports and Distribution Channels',
        description:
          'Data is processed and organised for analysis, removing outliers and errors. Visualisation methods, such as charts or graphs, present the analysed data. Advanced techniques like statistical models and machine learning identify patterns and trends. Reports summarise findings and offer recommendations for improved crop health. Most of these processes are currently manually performed and analysed.'
      }
    ],
    queryparams: '',
    challenge_head: 'Challenges',
    chemChal: [
      {
        title: 'Current technology in use: Remote sensing & IoT Devices:',
        points: [
          'Limited Resolution',
          'Cloud Cover',
          'Cost and Accessibility',
          'Data Processing and Interpretation'
        ]
      },
      {
        title: 'Field Scouting and Manual Observations:',
        points: [
          'Subjectivity and Bias',
          'Labour and Time-Intensive',
          'Limited Coverage',
          'Unreliable & Unstructured'
        ]
      },
      {
        title: 'Building Data Reports and Distribution Channels',
        points: [
          'Managing large volumes of diverse data ',
          'Ensuring data quality and accuracy ',
          'Selecting appropriate visualisation techniques to represent the analysed data effectively'
        ]
      }
    ],
    solution_head: 'SatSure Sparta Solutions',
    solution: [
      {
        title: 'Current technology in use: Remote sensing & IoT Devices: ',
        description:
          'Land monitoring data products at the farm level such as irrigation maps, cropping intensity temperature, rainfall trends and farm boundary can help identify the ideal farms for trails'
      },
      {
        title: 'Field Scouting and Manual Observations:',
        description:
          'SatSure Sparta provide crop monitoring data products such as crop area, sowing and harvest progression, and crop performance indices at both a macro and micro level (with granularity level up to 1 acre and accuracy of 90%+) '
      },
      {
        title: 'Building Data Reports and Distribution Channels: ',
        description:
          'APIs for easy integration with digital platforms, Custom data visualisation dashboards'
      }
    ],
    child: []
  },

  {
    id: '#food',
    head: 'Food and Commodity Companies',
    title: 'Procurement Department',
    intro_1:
      'Procurement can be made efficient and sustainable with satellite data by providing valuable, near real-time insights on crop growth stage, crop health and harvest progression to deploy field staff for timely procurement of the produce. Learn how our client made data-driven decisions to procure efficiently while maintaining product quality.',
    method: 'Current Industry Process',
    shares: [
      {
        title: 'Supply Estimate ',
        description:
          'The field team collects data which the procurement manager analyses to estimate the supply of produce geography-wise'
      },
      {
        title: 'Procurement Planning ',
        description:
          'The procurement manager selects the geographics for optimum procurement and deploys the field team with the goal of achieving the highest efficiency  '
      },
      {
        title: 'Procurement Execution ',
        description:
          'The field team conducts regular checks to be aware of any possible change in the plans and  keeps track of the harvest readiness of the produce to know the exact time of procurement '
      }
    ],
    challenge_head: 'Challenges',
    challenges: [
      {
        title: 'Labour intensive'
      },
      {
        title: 'Prone to manual errors'
      },
      {
        title: 'Lack of data standardisation and transparency'
      },
      {
        title: 'Cost-intensive'
      },
      {
        title: 'Time-consuming which lead to missed procurement opportunity'
      }
    ],
    solution_head: 'SatSure Sparta Solutions',
    solution: [
      {
        title: 'Supply Estimate',
        description:
          'Spatial distribution of crop area to identify dense crop clusters and plan logistics and collection centres strategically. Save cost and time by reducing manual work and errors induced by it. '
      },
      {
        title: 'Procurement Planning',
        description:
          'Continuous remote crop health monitoring to introduce easy and fast in-season interventions in the procurement plan.'
      },
      {
        title: 'Procurement Execution',
        description:
          'Near-real-time harvest data to deploy resources at the right time at the right locations helps achieve maximum resource utilisation '
      }
    ],

    title_2: 'Use Case 2',
    intro_2_dis: 'Increasing efficiency of farm operations.',
    intro_2:
      'Continuous crop monitoring at the farm level comes with a high field team cost, and sometimes the field team misses out on critical events, which impacts the overall yield. A feasible solution to tackle this challenge is adopting remote sensing data to help increase the field team’s efficiency by providing near real-time insights on crop performance, crop stages and other crop-related events.',
    method_2: 'Current Industry Process',
    shares_details: [
      {
        description:
          'The field team collects data which the procurement manager analyses to estimate the supply of produce geography-wise'
      },
      {
        description:
          'The procurement manager selects the geographics for optimum procurement and deploys the field team with the goal of achieving the highest efficiency  '
      },
      {
        description:
          'The field team conducts regular checks to be aware of any possible change in the plans and  keeps track of the harvest readiness of the produce to know the exact time of procurement '
      }
    ],
    challenges_det: [
      {
        title: 'Data Acquisition and Accessibility',
        description:
          'Obtaining timely and high-quality & structured data can be challenging, especially in remote or rural areas. Satellite imagery may be limited due to cloud cover, and frequent updates can be costly. Moreover, accessing and processing large datasets requires appropriate infrastructure and expertise '
      },
      {
        title: 'High operational cost',
        description:
          'Most agricultural companies cater to large geographical areas, making data collecting and processing by remote sensing or field teams prohibitively expensive.'
      },
      {
        title: 'Data Interpretation and Analysis',
        description:
          'Interpreting and analysing remote sensing data require specialised skills and knowledge. '
      },
      {
        title: 'Resolution and Accuracy',
        description:
          ' The resolution of remote sensing data might not always be sufficient for detailed analysis at the field level. Lower-resolution data may not capture small-scale variations, limiting the precision of decision-making. '
      }
    ],
    solution_2: [
      {
        title: 'Continuous crop monitoring',
        description:
          'SatSure’s patented solution - SatSure Cygnus, reconstructs lost data due to cloud cover to enable seamless continuous crop monitoring through satellite remote sensing. '
      },
      {
        title: 'In-season Interventions',
        description:
          'Near-real-time insights on crop events to enable faster and more accurate decision-making.'
      },
      {
        title: 'Field team deployment',
        description:
          'Crop growth progress enables the decision makers to make data-backed decisions to deploy the required number of field staff at the right time in the right location'
      }
    ],
    benefits_head: 'Benefits',
    benefits_details: [
      {
        description:
          'Save the cost of data acquisition with cloud cover and get continuous high-resolution data for better decision-making. '
      },
      {
        description:
          'In-season intervention allows users to take timely actions to address challenges, optimise inputs, and maximise crop performance during the growing season.  '
      },
      {
        description:
          'Timely resource deployment to targeted customers, optimising field operations'
      }
    ],
    child: []
  }
];
