import { React, useState, useEffect } from 'react';
import Select from 'react-select';
import './index.css';
import forwardimg from '../../assets/images/icons/arrow-right.svg';
import close from '../../assets/images/close_black.svg';
//Helper Functions
import { getCountry, getSolution } from '../../services/support-services';
import { postSubmitFormValue } from '../../services/setting-services';
import { toast } from 'react-toastify';

const ContactUs = ({ closeModal, activeForm }) => {
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    company: '',
    solution: '',
    category: '',
    details: ''
  });
  const [countryOption, setCountryOption] = useState([]);
  const [solutionOption, setSolutionOption] = useState([]);

 /**
   * <function description>
   *  Function to handle form submission
   */
  const submitHandler = async (e) => {
    e.preventDefault();
    let profileDetails = {
      first_name: formData.firstname,
      last_name: formData.lastname,
      email: formData.email,
      country: formData.country.label,
      solutions: formData.solution ? [formData.solution] : [],
      mobile: formData.phone,
      company: formData.company,
      category: formData.category,
      description: formData.details
    };

    var queryType = '';
    if (activeForm === 'requestDemo') {
      queryType = '1';
    } else if (activeForm === 'generalQuery') {
      queryType = '3';
    } else {
      queryType = '2';
    }

    const { status } = await postSubmitFormValue(profileDetails, queryType);
    if (status === 200) {
      closeModal(true);
      toast.success('Request Received..!');
    }
  };

   /**
   * <function description>
   *  Function to fetch country data
   */
  const getCountryData = async () => {
    const { data, status } = await getCountry();
    if (status === 200) {
      let arr = data.data.map((item) => {
        return {
          label: item.name,
          value: item.id
        };
      });
      setCountryOption([...arr]);
    }
  };

   /**
   * <function description>
   *  Function to fetch solution data
   */
  const getSolutionData = async () => {
    const { data, status } = await getSolution();
    if (status === 200) {
      let arr = data.data.map((item) => {
        return {
          label: item.name,
          value: item.id
        };
      });
      setSolutionOption([...arr]);
    }
  };

  useEffect(() => {
    if (activeForm === 'raiseQuery') {
      getCountryData();
    }
    if (activeForm === 'requestDemo') {
      getCountryData();
      getSolutionData();
    }
    if (activeForm === 'generalQuery') {
      getCountryData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <button
        className="close-button"
        aria-label="Close alert"
        type="button"
        onClick={() => closeModal(false)}
        data-close>
        <span aria-hidden="true">
          <img src={close} alt="close" />
        </span>
      </button>
      <div className="requestDem-container">
        {activeForm === 'requestDemo' ? (
          <div>
            <h1>Request a Demo</h1>
            <p>
              Take a closer look at our platform and get a personalised demo of
              our data products tailored to your industry.
            </p>
          </div>
        ) : (
          ''
        )}

        {activeForm === 'generalQuery' ? (
          <div>
            <h1>General Query</h1>
            <p>
              Thank you for reaching out to us, we will get in touch with you
              shortly.
            </p>
          </div>
        ) : (
          ''
        )}

        {activeForm === 'raiseQuery' ? (
          <div>
            <h1>Raise a Complaint</h1>
            <p>We express our regret. Please help us fix this for you.</p>
          </div>
        ) : (
          ''
        )}

        <div className="requestDem-list">
          <div className="contact-name-first-last">
            <div className="requestDem-form common-width">
              <input
                type="text"
                name="firstName"
                placeholder="First Name*"
                value={formData.firstname}
                onChange={(event) =>
                  setFormData({ ...formData, firstname: event.target.value })
                }
              />
            </div>
            <div className="requestDem-form common-width">
              <input
                type="text"
                name="lastName"
                placeholder="Last Name*"
                value={formData.lastname}
                onChange={(event) =>
                  setFormData({ ...formData, lastname: event.target.value })
                }
              />
            </div>
            <div className="requestDem-form common-width">
              <input
                type="text"
                name="email"
                placeholder="Email*"
                value={formData.email}
                onChange={(event) =>
                  setFormData({ ...formData, email: event.target.value })
                }
              />
            </div>
          </div>
          <div className="country-phone-company">
            <div className="requestDem-form common-width drop-content">
              <Select
                className="react-select"
                placeholder="Country"
                classNamePrefix={'react-select'}
                value={formData.country}
                options={countryOption}
                onChange={(selectedOption) =>
                  setFormData({ ...formData, country: selectedOption })
                }
              />
            </div>
            <div className="requestDem-form common-width">
              <input
                type="text"
                name="phone"
                placeholder="Phone Number"
                value={formData.phone}
                onChange={(event) =>
                  setFormData({ ...formData, phone: event.target.value })
                }
              />
            </div>
            <div className="requestDem-form common-width">
              <input
                type="text"
                name="company"
                placeholder="Company"
                value={formData.company}
                onChange={(event) =>
                  setFormData({ ...formData, company: event.target.value })
                }
              />
            </div>
          </div>
          {activeForm === 'requestDemo' ? (
            <div className="width-66 support-select margin-bottom ">
              <Select
                placeholder="Solutions *"
                value={formData.solution}
                onChange={(selectedOption) =>
                  setFormData({ ...formData, solution: selectedOption })
                }
                options={solutionOption}
                isMulti
                classNamePrefix="react-select"
              />
            </div>
          ) : (
            ''
          )}
          {activeForm === 'generalQuery' ? (
            <div className="requestDem-form width-66">
              <Select
                placeholder="Category"
                className="react-select"
                value={formData.category}
                onChange={(selectedOption) =>
                  setFormData({ ...formData, category: selectedOption })
                }
                classNamePrefix={'react-select'}
              />
            </div>
          ) : (
            ''
          )}
          <div className="width-66">
            <div className="form-container2">
              <div className="requestDem-form2">
                <textarea
                  className="text-area1"
                  rows="8"
                  type="textarea"
                  name="roleName"
                  placeholder="Please specify details related to the request..."
                  value={formData.details}
                  onChange={(event) =>
                    setFormData({ ...formData, details: event.target.value })
                  }
                />
              </div>
              <button className="request-btn" onClick={submitHandler}>
                {activeForm === 'requestDemo' ? 'Request' : 'Submit'}
                <label>
                  <img
                    className="forward-img"
                    src={forwardimg}
                    name="submit"
                    alt="forward"
                  />
                </label>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
