import React, { useState } from 'react';

/* Helper functions */
import { isPasswordValid } from '../../utils/regex-helper';
import { changePassword } from '../../services/setting-services';
import { toast } from 'react-toastify';

//IMAGES
import passwordshow from '../../assets/images/icons/show-eye.svg';
import passwordhide from '../../assets/images/icons/hide-eye.svg';

//CSS
import './updatepassword.css';

/**
 * <function description>
 *  Defining functional component using React hooks
 * which will manage form data of old and new password
 */
const UpdatePassword = () => {
  const [hideShowPwd, setHideShowPwd] = useState({
    password: false,
    newpassword: false
  });

  const [formData, setFormData] = useState({
    oldPassword: '',
    newpassword: '',
    confirmPassword: ''
  });

  const [error, setError] = useState({
    oldPassword: '',
    newpassword: '',
    confirmPassword: ''
  });

  /**
   * <function description>
   * Function to set the form data and clear the corresponding error msgs
   * whenever the value of input field changes
   */
  const onChangeHandler = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      };
    });
    setError((prev) => {
      return {
        ...prev,
        [e.target.name]: ''
      };
    });
  };

  /**
   * <function description>
   * Function to handle password update
   * Updating error state
   * dispalying success or failed toast accordingly
   */
  const onUpdatePassword = async () => {
    const errorObj = { ...error };
    if (formData.oldPassword === '') {
      errorObj.oldPassword = 'Enter Old Password ';
    }
    if (formData.newpassword === '') {
      errorObj.newpassword = 'Enter New Pasword';
    }
    if (formData.confirmPassword === '') {
      errorObj.confirmPassword = 'Enter Confirm Passowrd';
    }

    if (!isPasswordValid(formData.newpassword)) {
      errorObj.newpassword = 'Enter Valid Password';
    }

    if (formData.newpassword !== formData.confirmPassword) {
      errorObj.confirmPassword = 'Password Not Matching';
    }

    if (formData.newpassword === formData.confirmPassword) {
      errorObj.confirmPassword = '';
    }
    if (formData.oldPassword === formData.newpassword) {
      errorObj.newpassword = 'New password is same as Old password';
    }

    setError(errorObj);

    if (
      errorObj.oldPassword === '' &&
      errorObj.newpassword === '' &&
      errorObj.confirmPassword === ''
    ) {
      let payload = {
        old_password: formData.oldPassword,
        new_password: formData.newpassword
      };
      const { status, error } = await changePassword(payload);
      if (status === 200) {
        setFormData({
          ...formData,
          newpassword: '',
          oldPassword: '',
          confirmPassword: ''
        });
        toast.success('Password Successfully Updated...!');
      }
      if (error) {
        toast.error(error?.response?.data?.message);
      }
    }
  };

  /**
   * <function description>
   *   Function to disable submit button until all form inputs are entered
   */
  const getDisabled = () => {
    if (
      formData.oldPassword !== '' &&
      formData.newpassword !== '' &&
      formData.confirmPassword !== ''
    ) {
      return false;
    }
    return true;
  };

  return (
    <div className="profile-container">
      <div className="profile-header">
        <h1>Change your Password</h1>
        <hr></hr>
      </div>
      <div className="password-container">
        <div className="password-wrapper">
          <div className="password-group">
            <label>Enter old password</label>
            <span className="old-password-wrapper">
              <input
                className={
                  error.oldPassword ? 'password-input red' : 'password-input'
                }
                type={hideShowPwd.password ? 'text' : 'password'}
                name="oldPassword"
                value={formData.oldPassword}
                onChange={(e) => onChangeHandler(e)}
              />
              <img
                src={hideShowPwd.password ? passwordshow : passwordhide}
                alt="passwordLogo"
                onClick={() =>
                  setHideShowPwd({
                    ...hideShowPwd,
                    password: !hideShowPwd.password
                  })
                }
                className="password-icon"
              />
              <label
                className={
                  error.oldPassword ? 'error-text visible' : 'error-text hidden'
                }>
                {error.oldPassword}
              </label>
            </span>
          </div>
          <hr className="horizontal-line" />
          <div className="password-group">
            <label>New password</label>
            <div className="new-password-wrapper">
              <input
                className={
                  error.newpassword ? 'password-input red' : 'password-input'
                }
                type={hideShowPwd.newpassword ? 'text' : 'password'}
                name="newpassword"
                value={formData.newpassword}
                onChange={(e) => onChangeHandler(e)}
              />
              <img
                src={hideShowPwd.newpassword ? passwordshow : passwordhide}
                alt="passwordLogo"
                onClick={() =>
                  setHideShowPwd({
                    ...hideShowPwd,
                    newpassword: !hideShowPwd.newpassword
                  })
                }
                className="password-icon"
              />
              <label
                className={
                  error.newpassword ? 'error-text visible' : 'error-text hidden'
                }>
                {error.newpassword}
              </label>
            </div>
          </div>
          <hr className="horizontal-line" />
          <div className="password-group">
            <label>Re-enter password</label>
            <div className="new-password-wrapper">
              <input
                className={
                  error.confirmPassword
                    ? 'password-input red'
                    : 'password-input'
                }
                type={hideShowPwd.confirmPassword ? 'text' : 'password'}
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={(e) => onChangeHandler(e)}
              />
              <img
                src={hideShowPwd.confirmPassword ? passwordshow : passwordhide}
                alt="passwordLogo"
                onClick={() =>
                  setHideShowPwd({
                    ...hideShowPwd,
                    confirmPassword: !hideShowPwd.confirmPassword
                  })
                }
                className="password-icon"
              />
              <label
                className={
                  error.confirmPassword
                    ? 'error-text visible'
                    : 'error-text hidden'
                }>
                {error.confirmPassword}
              </label>
            </div>
          </div>
          <div className="btn-update-group">
            <button
              className="btn-update"
              disabled={getDisabled()}
              onClick={() => onUpdatePassword()}>
              Update
            </button>
          </div>
        </div>
        <div className="password-desc-wrapper">
          <h1 className="password-desc-heading">Password requirements</h1>
          <ul className="passowrd-order-list">
            <li>Must contain atleast 8 characters</li>
            <li>Must contain atleast one Upper case letter</li>
            <li>Must contain atleast one Lower case letter</li>
            <li>Must contain atleast one number</li>
            <li>Must contain atleast one special character</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UpdatePassword;
