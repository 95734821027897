import React, { useState, useEffect, useRef } from 'react';
import Map from 'ol/Map.js';
import GeoJSON from 'ol/format/GeoJSON.js';
// import MultiPoint from 'ol/geom/MultiPoint.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import { Fill, Stroke, Style } from 'ol/style.js';
import { Group as LayerGroup } from 'ol/layer.js';
// import OSM from 'ol/source/OSM.js';
import TileLayer from 'ol/layer/Tile.js';
import TileWMS from 'ol/source/TileWMS.js';
import View from 'ol/View.js';
import XYZ from 'ol/source/XYZ.js';
import {
  FullScreen,
  //   ScaleLine,
  defaults as defaultControls
} from 'ol/control.js';
import * as turf from '@turf/turf';
import { transformExtent } from 'ol/proj';
import { toast } from 'react-toastify';
import moment from 'moment';
import { getProductListName } from '../../utils/user-helper';
import { BASE_URL_GEO_SERVER } from '../../utils/url-helper';
import { getAoiProduct } from '../../services/product-crop-services';

// Components
import MapControls from '../../components/map-controls';
// import { defaults as defaultInteractions } from 'ol/interaction.js';
// import { USER_DETAILS_KEY, header } from '../../utils/constants';
// import { getUserDetails } from '../../utils/user-helper';

import CROP_INTENSITY from '../../assets/images/legends/map-legends/Crop_Intensity.svg';
import IRRIGATION_CONDITION from '../../assets/images/legends/map-legends/Irrigation_Condition.svg';
import CROP_SOWN_AREA from '../../assets/images/legends/map-legends/Crop_sown_area.svg';
import SINGLE_DATE_NDVI from '../../assets/images/legends/map-legends/S.D.S_NDVI.svg';
import SINGLE_DATE_NDWI from '../../assets/images/legends/map-legends/S.D.S_NDWI.svg';
import CYGNUS_NDVI from '../../assets/images/legends/map-legends/Cygnus_NDVI.svg';
import CYGNUS_NDWI from '../../assets/images/legends/map-legends/Cygnus_NDWI.svg';
import TEMP_COMPOSITE_NDVI from '../../assets/images/legends/map-legends/Temporal_composite_NDVI.svg';
import TEMP_COMPOSITE_NDWI from '../../assets/images/legends/map-legends/Temporal_composite_NDWI.svg';
import DOWN_ARROW from '../../assets/images/down-arrow.svg';
import ArrowUpDown from '../../assets/images/icons/arrow-white.svg';
import Close from '../../assets/images/close_black.svg';
import './index.css';

const style = new Style({
  fill: new Fill({
    color: '#eeeeee',
    opacity: 0.5
  }),
  stroke: new Stroke({
    color: '#fff',
    width: 0.5
  })
});

const MapLayer = ({
  dataAvailability,
  uuidCompareData,
  gridValue,
  aoi,
  selectedFilterOption,
  wmsLayerSecond,
  wmsLayerFirst,
  wmsLayerSingle,
  treeParentIdValue,
  treeParentIdValueSingle,
  selectedApiKey,
  wmsSinlgeMergeLayer,
  particularPopUpWms,
  particularChildlayerFileName,
  setShowModal,
  showModal,
  mapType,
  setMap2,
  setMap1
}) => {
  const [dataAvailabilityShow, setDataAvailabilityShow] = useState(false);
  const [mapObject, setMap] = useState();
  const [areaAoi, setAreaAoi] = useState(0);
  const [layerOpacity, setLayerOpacity] = useState(1);
  const [secondLayerOpacity, setSecondLayerOpacity] = useState(1);
  const [availableProducts, setAvailableProducts] = useState({});
  const [expand, setExpand] = useState(false);
  const [mapSingleExtent, setMapSingleExtent] = useState('');
  const [mapFirstExtent, setMapFirstExtent] = useState('');
  const [mapSingleMeregeExtent, setMapSingleMeregeExtent] = useState('');
  const [mapSecondExtent, setMapSecondExtent] = useState('');
  const [allReqMapExtent, setAllReqMapExtent] = useState('');
  const mapElement = useRef(null);
  const mapRef = useRef(null);
  mapRef.current = mapObject;
  const mountRef = useRef(false);

  useEffect(() => {
    if (!mapObject) {
      createMap();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mapObject && gridValue !== undefined) {
      // Vector Source
      const source = new VectorSource({
        features: new GeoJSON({
          defaultDataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857'
        }).readFeatures(gridValue)
      });

      // Grid Vector Layer
      const layer = new VectorLayer({
        source: source,
        style: function (feature) {
          const color = feature.get('COLOR') || 'transparent';
          style.getFill().setColor(color);
          return style;
        }
        // style: styles
      });
      layer.set('name', 'grid');

      mapObject.addLayer(layer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridValue, mapObject]);

  useEffect(() => {
    if (mapObject && uuidCompareData && uuidCompareData?.features?.length > 0) {
      mapObject.getLayers().forEach((layer) => {
        if (layer && layer.get('name') === 'geoJson') {
          mapObject.removeLayer(layer);
        }
      });
      const selectedSourceGeoJson = new VectorSource({
        features: new GeoJSON({
          defaultDataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857'
        }).readFeatures(uuidCompareData)
      });
      const selectedLayerGeoJson = new VectorLayer({
        source: selectedSourceGeoJson,
        style: function (feature) {
          const color = feature.get('COLOR') || 'transparent';
          style.getFill().setColor(color);
          return style;
        }
      });
      selectedLayerGeoJson.setOpacity(0.4);

      selectedLayerGeoJson.set('name', 'geoJson');

      mapObject.addLayer(selectedLayerGeoJson);
    } else if (mapObject) {
      mapObject.getLayers().forEach((layer) => {
        if (layer && layer.get('name') === 'geoJson') {
          mapObject.removeLayer(layer);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuidCompareData, mapObject]);

  useEffect(() => {
    if (mapObject && aoi && Object.keys(aoi).length > 0) {
      onPlotPolygon();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aoi, mapObject]);

  useEffect(() => {
    if (selectedFilterOption?.products && mapObject) {
      // const layers = [
      //   ...mapObject
      //     .getLayers()
      //     .getArray()
      //     .filter(
      //       (item) =>
      //         item.get('name') !== 'grid' && item.get('name') !== undefined
      //     )
      // ];
      // layers.forEach((layer) => mapObject.removeLayer(layer));
      setAvailableProducts([]);
    } else if (mapObject) {
      const layers = [
        ...mapObject
          .getLayers()
          .getArray()
          .filter(
            (item) =>
              item.get('name') !== 'grid' && item.get('name') !== undefined
          )
      ];
      layers.forEach((layer) => mapObject.removeLayer(layer));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilterOption?.products]);

  /**
   *
   */
  useEffect(() => {
    if (mountRef.current) {
      if (
        wmsLayerSingle?.parentId !== '' &&
        wmsLayerSingle?.wms &&
        wmsLayerSingle?.childId &&
        mapObject
      ) {
        let singleTransform = transformExtent(
          wmsLayerSingle?.wms?.bbox,
          'EPSG:4326',
          'EPSG:3857'
        );
        setMapSingleExtent(singleTransform);

        if (layerOpacity === 1) {
          setMapSingleExtent(singleTransform);
          mapObject.getView().fit(singleTransform, {
            duration: 2000,
            padding: [50, 50, 50, 50]
          });
        }
      } else if (
        wmsLayerSingle?.parentId === '' &&
        wmsLayerSingle?.wms === '' &&
        wmsLayerSingle?.childId === '' &&
        mapObject
      ) {
        var view = mapObject.getView();
        view.setCenter([8790109.819560181, 2343497.068402029]);
        view.setZoom(5);
      }
      mapObject.getLayers().forEach(function (layer) {
        if (layer && layer.get('name') === 'singleLayer') {
          mapObject.removeLayer(layer);
          // var view = mapObject.getView();
          // view.setCenter([8790109.819560181, 2343497.068402029]);
          // view.setZoom(5);
        }
      });
      setTimeout(() => {
        singleMapLayer();
      }, 2000);
    }
    mountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wmsLayerSingle?.childId, layerOpacity]);

  useEffect(() => {
    if (mountRef.current && mapObject !== undefined) {
      if (
        wmsLayerFirst?.parentId !== '' &&
        wmsLayerFirst?.wms &&
        wmsLayerFirst?.childId &&
        mapObject
      ) {
        let leftTransform = transformExtent(
          wmsLayerFirst?.wms?.bbox,
          'EPSG:4326',
          'EPSG:3857'
        );
        setMapFirstExtent(leftTransform);
        if (layerOpacity === 1) {
          mapObject
            .getView()
            .fit(leftTransform, { duration: 2000, padding: [50, 50, 50, 50] });
        }

        mapObject.getLayers().forEach(function (layer) {
          if (layer && layer.get('name') === 'firstLayer') {
            mapObject.removeLayer(layer);
          }
        });
      } else if (
        wmsLayerFirst?.parentId === '' &&
        wmsLayerFirst?.wms === '' &&
        wmsLayerFirst?.childId === '' &&
        mapObject
      ) {
        var view = mapObject.getView();
        view.setCenter([8790109.819560181, 2343497.068402029]);
        view.setZoom(5);
      }
      setTimeout(() => {
        firstMapLayer();
      }, 2000);
    }
    mountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wmsLayerFirst?.childId, mapObject, layerOpacity]);

  useEffect(() => {
    if (mountRef.current && mapObject !== undefined) {
      if (
        wmsLayerSecond?.parentId !== '' &&
        wmsLayerSecond?.wms &&
        wmsLayerSecond?.childId &&
        mapObject
      ) {
        let rightTransform = transformExtent(
          wmsLayerSecond?.wms?.bbox,
          'EPSG:4326',
          'EPSG:3857'
        );
        setMapSecondExtent(rightTransform);
        if (layerOpacity === 1) {
          mapObject
            .getView()
            .fit(rightTransform, { duration: 2000, padding: [50, 50, 50, 50] });
        }

        mapObject.getLayers().forEach(function (layer) {
          if (layer && layer.get('name') === 'secondLayer') {
            mapObject.removeLayer(layer);
          }
        });
      } else if (
        wmsLayerSecond?.parentId === '' &&
        wmsLayerSecond?.wms === '' &&
        wmsLayerSecond?.childId === '' &&
        mapObject
      ) {
        var view = mapObject.getView();
        view.setCenter([8790109.819560181, 2343497.068402029]);
        view.setZoom(5);
      }
      setTimeout(() => {
        secondMapLayer();
      }, 2000);
    }
    mountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wmsLayerSecond?.childId, mapObject, layerOpacity]);

  useEffect(() => {
    if (mountRef.current && mapObject !== undefined) {
      if (
        wmsSinlgeMergeLayer?.first &&
        wmsSinlgeMergeLayer?.first?.wms !== '' &&
        wmsSinlgeMergeLayer?.second &&
        wmsSinlgeMergeLayer?.second?.wms !== '' &&
        mapObject
      ) {
        let singleTransform = transformExtent(
          wmsSinlgeMergeLayer?.first?.wms?.bbox,
          'EPSG:4326',
          'EPSG:3857'
        );
        setMapSingleMeregeExtent(singleTransform);
        if (layerOpacity === 1 && secondLayerOpacity === 1) {
          mapObject.getView().fit(singleTransform, {
            duration: 2000,
            padding: [50, 50, 50, 50]
          });
        }
      }
      mapObject.getLayers().forEach(function (layer) {
        if (layer && layer.get('name') === 'singleMergeMapLayer') {
          mapObject.removeLayer(layer);
        }
      });
      setTimeout(() => {
        singleMergeMapLayer();
      }, 2000);
    }
    mountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    wmsSinlgeMergeLayer?.first?.childId,
    wmsSinlgeMergeLayer?.second?.childId,
    mapObject,
    layerOpacity,
    secondLayerOpacity
  ]);

  /**
   *
   */

  useEffect(() => {
    if (mapObject !== undefined) {
      AllRequestPopUpMapLayer();
    }
    mountRef.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [particularPopUpWms, mapObject, layerOpacity]);

  const createMap = () => {
    // const styles = [
    //   new Style({
    //     stroke: new Stroke({
    //       color: 'white',
    //       width: 0.5
    //     }),
    //     fill: new Fill({
    //       color: 'green'
    //     })
    //     // image: new CircleStyle({
    //     //   radius: 5,
    //     //   fill: null,
    //     //   stroke: new Stroke({
    //     //     color: 'orange',
    //     //   }),
    //     // }),
    //     // geometry: function (feature) {
    //     //   const coordinates = feature.getGeometry().getCoordinates()[0];
    //     //   return new MultiPoint(coordinates);
    //     // },
    //   })
    // ];

    // WMS layer
    // const wmsLayer = new TileLayer({
    //   // extent: [],
    //   source: new TileWMS({
    //     url: BASE_URL_GEO_SERVER,
    //     //"https://beta.odc.satsure.co/wms?",
    //     params: {
    //       request: 'GetMap',
    //       layers: 'CH',
    //       tiled: true,
    //       format: 'image/png',
    //       version: '1.3.0',
    //       // "zIndex": 99,
    //       crs: 'EPSG:4326',
    //       time: '2019-02-24 00:00:00',
    //       crop: 'CHWH010',
    //       service: 'wms'
    //     },
    //     // tileLoadFunction: function (tile, src) {
    //     //   var xhr = new XMLHttpRequest();
    //     //   xhr.open('GET', 'http://192.168.0.174:8080/v1/data/geoproxy/wms/', true);
    //     //   xhr.setRequestHeader('Authorization', getUserDetails(USER_DETAILS_KEY)?.accessToken);
    //     //   xhr.onload = function () {
    //     //     tile.getImage().src = src;
    //     //   };
    //     //   xhr.send();
    //     // },
    //     serverType: undefined,
    //     // Countries have transparency, so do not fade tiles:
    //     transition: 0
    //   })
    // });

    const map = new Map({
      target: mapElement.current,
      controls: defaultControls().extend([
        new FullScreen()
        // new ScaleLine()
      ]),
      interaction: null,
      // interactions: defaultInteractions({
      //   doubleClickZoom: true,
      //   dragAndDrop: true,
      //   dragPan: false,
      //   keyboardPan: false,
      //   keyboardZoom: false,
      //   mouseWheelZoom: false,
      //   pointer: false,
      //   select: false
      // }),
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}'
          })
          // source: new OSM(),
        })
        // wmsLayer,
        // layer,
        // selectedLayerGeoJson
      ],
      // target: 'map',
      view: new View({
        projection: 'EPSG:3857',
        // center: [78.9629, 20.5937], transform(78.9629, 20.5937, 'EPSG:4326', 'EPSG:3857');
        center: [8790109.819560181, 2343497.068402029],
        zoom: 5
      })
    });

    const featureOverlay = new VectorLayer({
      source: new VectorSource(),
      map: map,
      style: new Style({
        stroke: new Stroke({
          color: '#03a57b',
          width: 2
        })
      })
    });

    let highlight;
    const displayFeatureInfo = function (pixel) {
      const feature = map.forEachFeatureAtPixel(pixel, function (feature) {
        return feature;
      });

      // const info = document.getElementById('info');
      // if (feature) {
      //   info.innerHTML = feature.get('ECO_NAME') || '&nbsp;';
      // } else {
      //   info.innerHTML = '&nbsp;';
      // }

      if (feature !== highlight) {
        if (highlight) {
          featureOverlay.getSource().removeFeature(highlight);
        }
        if (feature) {
          featureOverlay.getSource().addFeature(feature);
        }
        highlight = feature;
      }
    };

    // map.removeLayer(vectorLayer);
    // map.addLayer(wmsLayer);

    map.on('pointermove', function (evt) {
      if (evt.dragging) {
        return;
      }
      const pixel = map.getEventPixel(evt.originalEvent);
      displayFeatureInfo(pixel);
    });

    // map.on('click', function (evt) {
    //   displayFeatureInfo(evt.pixel);
    // });
    map.on('loadstart', function () {
      map.getTargetElement().classList.add('spinner');
    });
    map.on('loadend', function () {
      map.getTargetElement().classList.remove('spinner');
    });

    map.getViewport().style.cursor = '-webkit-grab';
    map.on('pointerdrag', function (evt) {
      map.getViewport().style.cursor = '-webkit-grabbing';
    });

    map.on('pointerup', function (evt) {
      map.getViewport().style.cursor = '-webkit-grab';
    });

    if (mapType === 'first') {
      setMap1(map);
    }
    if (mapType === 'second') {
      setMap2(map);
    }
    setMap(map);
  };

  // Aoi Polygon Draw
  const onPlotPolygon = () => {
    mapObject.getLayers().forEach(function (layer) {
      if (layer && layer.get('name') === 'polygon') {
        mapObject.removeLayer(layer);
      }
    });

    const source = new VectorSource({
      features: new GeoJSON({
        defaultDataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      }).readFeatures(aoi)
    });

    const polygon = new VectorLayer({
      source: source,
      style: new Style({
        stroke: new Stroke({
          color: 'rgba(255, 255, 255, 0.7)',
          width: 3,
          zIndex: 999
        })
      })
    });

    polygon.set('name', 'polygon');
    getArea(aoi);

    mapObject.addLayer(polygon);
    mapObject.getView().fit(source.getExtent(), {
      size: mapObject.getSize(),
      maxZoom: 14,
      duration: 3000,
      padding: [50, 50, 50, 50]
      // minZoom: 10,
      // zoom: 7
    });

    if (
      selectedFilterOption.products === null &&
      selectedFilterOption.crop === null &&
      selectedFilterOption.dateRange.length === 0
    ) {
      getAoiProducts();
    }
  };

  const getAoiProducts = async () => {
    const { data, status, error } = await getAoiProduct({
      aoi: aoi.features[0]
    });
    if (status === 200) {
      setAvailableProducts(data?.data);
    }
    if (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  // Single Map Layer
  const singleMapLayer = () => {
    if (
      wmsLayerSingle?.parentId !== '' &&
      wmsLayerSingle?.wms &&
      wmsLayerSingle?.childId &&
      mapObject
    ) {
      let links = wmsLayerSingle?.wms?.links.filter(
        (type) => type.rel === 'wms_url'
      );

      let URL = `${BASE_URL_GEO_SERVER}?${links[0]?.href}&api_key=${selectedApiKey}`;

      const source = new VectorSource({
        features: new GeoJSON({
          defaultDataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857'
        }).readFeatures(wmsLayerSingle?.wms)
      });

      const wmsLayer = new LayerGroup({
        layers: [
          new TileLayer({
            source: new TileWMS({
              url: URL,
              serverType: undefined,
              transition: 0
            })
          }),
          new VectorLayer({
            source: source,
            style: new Style({
              stroke: new Stroke({
                color: 'rgba(255, 255, 255, 0.7)',
                width: 3,
                zIndex: 999
              })
            })
          })
        ]
      });
      const opacity = parseFloat(layerOpacity);
      wmsLayer.setOpacity(opacity);
      mapObject.addLayer(wmsLayer);
      wmsLayer.set('name', 'singleLayer');
      getArea(wmsLayerSingle?.wms);
    } else if (mapObject) {
      mapObject.getLayers().forEach(function (layer) {
        if (layer && layer.get('name') === 'singleLayer') {
          mapObject.removeLayer(layer);
        }
      });
      setMapSingleExtent('');
      setAreaAoi('');
    }
  };

  // Multi Map Layer
  // Left
  const firstMapLayer = () => {
    if (
      wmsLayerFirst?.parentId !== '' &&
      wmsLayerFirst?.wms &&
      wmsLayerFirst?.childId &&
      mapObject
    ) {
      let links = wmsLayerFirst?.wms?.links.filter(
        (type) => type.rel === 'wms_url'
      );

      let URL = `${BASE_URL_GEO_SERVER}?${links[0]?.href}&api_key=${selectedApiKey}`;

      const source = new VectorSource({
        features: new GeoJSON({
          defaultDataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857'
        }).readFeatures(wmsLayerFirst?.wms)
      });
      const wmsLayer = new LayerGroup({
        layers: [
          new TileLayer({
            // extent: [],
            source: new TileWMS({
              url: URL,
              //  '?service=wms&request=GetMap&layers=CS&CRS=EPSG:4326&format=image/png&version=1.3.0&TIME=2022-08-11 00:00:00&aoi_id=cf392a47-da64-4ea9-812d-35642f1d27ad',
              //"https://beta.odc.satsure.co/wms?",
              // params: {
              //   request: 'GetMap',
              //   layers: 'CH',
              //   tiled: true,
              //   format: 'image/png',
              //   version: '1.3.0',
              //   // "zIndex": 99,
              //   crs: 'EPSG:4326',
              //   time: '2019-02-24 00:00:00',
              //   crop: 'CHWH010',
              //   service: 'wms'
              // },
              // tileLoadFunction: function (tile, src) {
              //   var xhr = new XMLHttpRequest();
              //   xhr.open('GET', 'http://192.168.0.174:8080/v1/data/geoproxy/wms/', true);
              //   xhr.setRequestHeader('Authorization', getUserDetails(USER_DETAILS_KEY)?.accessToken);
              //   xhr.onload = function () {
              //     tile.getImage().src = src;
              //   };
              //   xhr.send();
              // },
              serverType: undefined,
              // Countries have transparency, so do not fade tiles:
              transition: 0
            })
          }),
          new VectorLayer({
            source: source,
            style: new Style({
              stroke: new Stroke({
                color: 'rgba(255, 255, 255, 0.7)',
                width: 3,
                zIndex: 999
              })
            })
          })
        ]
      });

      const opacity = parseFloat(layerOpacity);
      wmsLayer.setOpacity(opacity);
      wmsLayer.set('name', 'firstLayer');
      mapObject.addLayer(wmsLayer);
      getArea(wmsLayerFirst?.wms);
    } else if (mapObject) {
      mapObject.getLayers().forEach(function (layer) {
        if (layer && layer.get('name') === 'firstLayer') {
          mapObject.removeLayer(layer);
        }
      });
      setMapFirstExtent('');
      if (mapType === 'first') {
        setAreaAoi('');
      }
      // map.removeLayer(vectorLayer);
      // var extent = wmsLayer.getSource().getExtent();
      // map.getView().fit(extent, map.getSize());
    }
  };

  // Right
  const secondMapLayer = () => {
    if (
      wmsLayerSecond?.parentId !== '' &&
      wmsLayerSecond?.wms &&
      wmsLayerSecond?.childId &&
      mapObject
    ) {
      let links = wmsLayerSecond?.wms?.links.filter(
        (type) => type.rel === 'wms_url'
      );

      let URL = `${BASE_URL_GEO_SERVER}?${links[0]?.href}&api_key=${selectedApiKey}`;

      const source = new VectorSource({
        features: new GeoJSON({
          defaultDataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857'
        }).readFeatures(wmsLayerSecond?.wms)
      });

      const wmsLayer = new LayerGroup({
        layers: [
          new TileLayer({
            source: new TileWMS({
              url: URL,
              serverType: undefined,
              transition: 0
            })
          }),
          new VectorLayer({
            source: source,
            style: new Style({
              stroke: new Stroke({
                color: 'rgba(255, 255, 255, 0.7)',
                width: 3,
                zIndex: 999
              })
            })
          })
        ]
      });

      const opacity = parseFloat(layerOpacity);
      wmsLayer.setOpacity(opacity);
      mapObject.addLayer(wmsLayer);
      wmsLayer.set('name', 'secondLayer');
      getArea(wmsLayerSecond?.wms);
    } else if (mapObject) {
      mapObject.getLayers().forEach(function (layer) {
        if (layer && layer.get('name') === 'secondLayer') {
          mapObject.removeLayer(layer);
        }
      });
      if (mapType === 'second') {
        setAreaAoi('');
      }
      setMapSecondExtent('');
    }
  };

  // singleMergeMapLayer
  const singleMergeMapLayer = () => {
    if (
      wmsSinlgeMergeLayer?.first &&
      wmsSinlgeMergeLayer?.first?.wms !== '' &&
      wmsSinlgeMergeLayer?.second &&
      wmsSinlgeMergeLayer?.second?.wms !== '' &&
      mapObject
    ) {
      let links = wmsSinlgeMergeLayer?.first?.wms?.links.filter(
        (type) => type.rel === 'wms_url'
      );
      let secondLinks = wmsSinlgeMergeLayer?.second?.wms?.links.filter(
        (type) => type.rel === 'wms_url'
      );
      let URL = `${BASE_URL_GEO_SERVER}?${links[0]?.href}&api_key=${selectedApiKey}`;
      let LAYER_URL = `${BASE_URL_GEO_SERVER}?${secondLinks[0]?.href}&api_key=${selectedApiKey}`;

      const source = new VectorSource({
        features: new GeoJSON({
          defaultDataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857'
        }).readFeatures(wmsSinlgeMergeLayer?.first?.wms)
      });
      const wmsLayer = new LayerGroup({
        layers: [
          new TileLayer({
            source: new TileWMS({
              url: URL,
              serverType: undefined,
              transition: 0
            })
          }),
          new VectorLayer({
            source: source,
            style: new Style({
              stroke: new Stroke({
                color: 'rgba(255, 255, 255, 0.7)',
                width: 3,
                zIndex: 999
              })
            })
          })
        ]
      });

      const wmsSecondLayer = new LayerGroup({
        layers: [
          new TileLayer({
            source: new TileWMS({
              url: LAYER_URL,
              serverType: undefined,
              transition: 0
            })
          }),
          new VectorLayer({
            source: source,
            style: new Style({
              stroke: new Stroke({
                color: 'rgba(255, 255, 255, 0.7)',
                width: 3,
                zIndex: 999
              })
            })
          })
        ]
      });

      const opacity = parseFloat(layerOpacity);
      wmsLayer.setOpacity(opacity);
      const secondOpacity = parseFloat(secondLayerOpacity);
      wmsSecondLayer.setOpacity(secondOpacity);
      wmsLayer.set('name', 'singleMergeMapLayer');
      wmsSecondLayer.set('name', 'singleMergeMapLayer');
      mapObject.addLayer(wmsLayer);
      mapObject.addLayer(wmsSecondLayer);
      getArea(wmsSinlgeMergeLayer?.first?.wms);
    } else if (mapObject) {
      mapObject.getLayers().forEach(function (layer) {
        if (layer && layer.get('name') === 'singleMergeMapLayer') {
          mapObject.removeLayer(layer);
        }
      });
      setMapSingleMeregeExtent('');
      if (mapType === 'singleMergeMap') {
        setAreaAoi('');
      }
    }
  };

  // All Request Map Layer
  const AllRequestPopUpMapLayer = () => {
    if (particularPopUpWms && mapObject) {
      mapObject.getLayers().forEach(function (layer) {
        if (layer && layer.get('name') === 'allRequestPopupLayer') {
          mapObject.removeLayer(layer);
        }
      });

      let links = particularPopUpWms?.links.filter(
        (type) => type.rel === 'wms_url'
      );

      let URL = `${BASE_URL_GEO_SERVER}?${links[0]?.href}&api_key=lOkTS1WSwkYq5YSbmkz3EpeUv4i-yhbzTTaCZcJ8A9Y`;

      const source = new VectorSource({
        features: new GeoJSON({
          defaultDataProjection: 'EPSG:4326',
          featureProjection: 'EPSG:3857'
        }).readFeatures(particularPopUpWms)
      });

      const wmsLayer = new LayerGroup({
        layers: [
          new TileLayer({
            source: new TileWMS({
              url: URL,
              serverType: undefined,
              transition: 0
            })
          }),
          new VectorLayer({
            source: source,
            style: new Style({
              stroke: new Stroke({
                color: 'rgba(255, 255, 255, 0.7)',
                width: 3,
                zIndex: 999
              })
            })
          })
        ]
      });
      const opacity = parseFloat(layerOpacity);
      wmsLayer.setOpacity(opacity);
      mapObject.addLayer(wmsLayer);
      wmsLayer.set('name', 'allRequestPopupLayer');
      let singlePopupTransform = transformExtent(
        particularPopUpWms?.bbox,
        'EPSG:4326',
        'EPSG:3857'
      );

      setAllReqMapExtent(singlePopupTransform);

      if (layerOpacity === 1) {
        mapObject.getView().fit(singlePopupTransform, {
          duration: 3000,
          padding: [50, 50, 50, 50]
        });
      }

      getArea(particularPopUpWms);
    } else if (mapObject) {
      if (mapType === 'allRequestPopupLayer') {
        setAreaAoi('');
      }
      setAllReqMapExtent('');
      mapObject.getLayers().forEach(function (layer) {
        if (layer && layer.get('name') === 'allRequestPopupLayer') {
          mapObject.removeLayer(layer);
        }
      });
    }
  };

  // Area Calculations
  const getArea = (geojson) => {
    let area = turf.area(geojson);
    if (area > 1000000) {
      let output = Math.round(area / 1000000);
      setAreaAoi(output + ' ' + 'sq km');
    } else {
      let output = Math.round(area);
      setAreaAoi(output + ' ' + 'sq m');
    }
  };

  const onChangeRange = (values) => {
    setLayerOpacity(values / 100);
  };

  const onChangeSecondRange = (values) => {
    setSecondLayerOpacity(values / 100);
  };

  /**
   * Map layer centroid
   */
  const flyToGeometry = () => {
    if (mapType === 'single' && mapSingleExtent !== '') {
      mapObject
        .getView()
        .fit(mapSingleExtent, { duration: 3000, padding: [50, 50, 50, 50] });
    } else if (mapType === 'first' && mapFirstExtent !== '') {
      mapObject
        .getView()
        .fit(mapFirstExtent, { duration: 3000, padding: [50, 50, 50, 50] });
    } else if (mapType === 'second' && mapSecondExtent !== '') {
      mapObject
        .getView()
        .fit(mapSecondExtent, { duration: 3000, padding: [50, 50, 50, 50] });
    } else if (mapType === 'singleMergeMap' && mapSingleMeregeExtent !== '') {
      mapObject.getView().fit(mapSingleMeregeExtent, {
        duration: 3000,
        padding: [50, 50, 50, 50]
      });
    } else if (mapType === 'allRequestPopupLayer' && allReqMapExtent !== '') {
      mapObject
        .getView()
        .fit(allReqMapExtent, { duration: 3000, padding: [50, 50, 50, 50] });
    }
  };

  return (
    <div className="map-layout-container">
      {wmsLayerSingle &&
      wmsLayerSingle?.fileName !== '' &&
      wmsLayerSingle?.wms !== '' ? (
        <div className="legend-title-header">
          <h2>
            Request Name :
            {' ' +
              moment(
                wmsLayerSingle?.wms?.properties?.datetime.split('T')[0]
              ).format('DD-MM-YYYY') +
              '_' +
              wmsLayerSingle?.fileName +
              '_' +
              getProductListName(treeParentIdValue)?.treeTitleCode}
          </h2>
        </div>
      ) : wmsLayerFirst &&
        wmsLayerFirst?.fileName !== '' &&
        wmsLayerFirst?.wms !== '' ? (
        <div className="legend-title-header">
          <h2>
            Request Name :
            {moment(
              wmsLayerFirst?.wms?.properties?.datetime.split('T')[0]
            ).format('DD-MM-YYYY') +
              '_' +
              wmsLayerFirst?.fileName +
              '_' +
              getProductListName(wmsLayerFirst?.productId)?.treeTitleCode}
          </h2>
        </div>
      ) : wmsLayerSecond &&
        wmsLayerSecond?.fileName !== '' &&
        wmsLayerSecond?.wms !== '' ? (
        <div className="legend-title-header flexEnd">
          <h2>
            Request Name :
            {moment(
              wmsLayerSecond?.wms?.properties?.datetime.split('T')[0]
            ).format('DD-MM-YYYY') +
              '_' +
              wmsLayerSecond?.fileName +
              '_' +
              getProductListName(wmsLayerSecond?.productId)?.treeTitleCode}
          </h2>
        </div>
      ) : wmsSinlgeMergeLayer?.first &&
        wmsSinlgeMergeLayer?.first?.fileName !== '' &&
        wmsSinlgeMergeLayer?.first?.wms !== '' &&
        wmsSinlgeMergeLayer?.second &&
        wmsSinlgeMergeLayer?.second?.fileName !== '' &&
        wmsSinlgeMergeLayer?.second?.wms !== '' ? (
        <div className="single-merge-title">
          <div className="legend-title-header">
            <h2>
              Request Name :
              {moment(
                wmsSinlgeMergeLayer?.first?.wms?.properties?.datetime.split(
                  'T'
                )[0]
              ).format('DD-MM-YYYY') +
                '_' +
                wmsSinlgeMergeLayer?.first?.fileName +
                '_' +
                getProductListName(wmsSinlgeMergeLayer?.first?.productId)
                  ?.treeTitleCode}
            </h2>
          </div>
          <div className="legend-title-header">
            <h2>
              Request Name :
              {moment(
                wmsSinlgeMergeLayer?.second?.wms?.properties?.datetime.split(
                  'T'
                )[0]
              ).format('DD-MM-YYYY') +
                '_' +
                wmsSinlgeMergeLayer?.second?.fileName +
                '_' +
                getProductListName(wmsSinlgeMergeLayer?.second?.productId)
                  ?.treeTitleCode}
            </h2>
          </div>
        </div>
      ) : particularChildlayerFileName ? (
        <div className="legend-title-header">
          <h2>{particularChildlayerFileName}</h2>
          <div className="close-icon" onClick={() => setShowModal(!showModal)}>
            <img src={Close} alt="Close" />
          </div>
        </div>
      ) : (
        <div className="legend-title-header">
          <h2>No dataset selected</h2>
        </div>
      )}

      <div ref={mapElement} className="map-container" id="map">
        <MapControls
          map={mapObject}
          setMap={setMap}
          onChangeSecondRange={onChangeSecondRange}
          onChangeRange={onChangeRange}
          layerName={treeParentIdValue}
          singleLayerName={treeParentIdValueSingle}
          compareLayerName={false}
          flyToGeometry={flyToGeometry}
          wmsSinlgeMergeLayer={wmsSinlgeMergeLayer}
        />
        {wmsLayerFirst?.childId === '' && (
          <div className="map-overlay">
            <label>Select one more request for comparison</label>
          </div>
        )}
        {wmsLayerSecond?.childId === '' && (
          <div className="map-overlay">
            <label>Select one more request for comparison</label>
          </div>
        )}
        {dataAvailability ? (
          <div className="data-availability">
            {dataAvailabilityShow ? (
              <ul className="data-availability-list">
                <li>
                  <label></label>
                  <span>1 year or less</span>
                </li>
                <li>
                  <label></label>
                  <span>1 year</span>
                </li>
                <li>
                  <label></label>
                  <span>2 year</span>
                </li>
                <li>
                  <label></label>
                  <span>3 year or more</span>
                </li>
              </ul>
            ) : null}
            <div
              className="data-list-title"
              onClick={() => {
                setDataAvailabilityShow(!dataAvailabilityShow);
              }}>
              <label>Data Availability</label>
              <span
                className={
                  dataAvailabilityShow
                    ? 'availability-arrow rotate-arrow'
                    : 'availability-arrow'
                }>
                <img src={ArrowUpDown} alt="download" />
              </span>
            </div>
          </div>
        ) : (
          <div className="croparea-aoi">
            {areaAoi ? (
              <div className="aoi-sqm">
                <label>Area: </label>
                <span> ( {areaAoi} )</span>
              </div>
            ) : null}
          </div>
        )}
        {availableProducts && Object.keys(availableProducts).length !== 0 ? (
          <div className="product-available-wrapper">
            <div className="popup-heading" onClick={() => setExpand(!expand)}>
              <h1>Available data products</h1>
              <label
                className={expand ? 'arrow-image rotate-image' : 'arrow-image'}>
                <img src={DOWN_ARROW} alt="down arrow" />
              </label>
            </div>
            <ul className={expand ? 'popup-body show' : 'popup-body'}>
              {Object.values(availableProducts).map((item) => {
                return (
                  <>
                    <li>{item}</li>
                  </>
                );
              })}
            </ul>
          </div>
        ) : null}
      </div>

      {wmsLayerSingle &&
      wmsLayerSingle?.fileName !== '' &&
      wmsLayerSingle?.wms !== '' ? (
        <div className="map-descriptions">
          {/* <div className="map-notes-document">
            <p>Note: The above analysis is done by XYZ method.</p>
            <div className="delivery-document">
              <label>Delivery Document</label>
              <span>
                <img src={Download} alt="download" />
              </span>
            </div>
          </div> */}
          {treeParentIdValue === 3 && (
            <div className="product-colour-img">
              <img src={CROP_SOWN_AREA} alt="Crop Sown Area" />
            </div>
          )}
          {treeParentIdValue === 4 && (
            <div className="product-colour-img">
              <img src={TEMP_COMPOSITE_NDVI} alt="Temporal Composite NDVI" />
            </div>
          )}
          {treeParentIdValue === 5 && (
            <div className="product-colour-img">
              <img src={TEMP_COMPOSITE_NDWI} alt="Temporal Composite NDWI" />
            </div>
          )}
          {treeParentIdValue === 6 && (
            <div className="product-colour-img">
              <img src={CYGNUS_NDVI} alt="CYGNUS NDvI" />
            </div>
          )}
          {treeParentIdValue === 7 && (
            <div className="product-colour-img">
              <img src={CYGNUS_NDWI} alt="CYGNUS NDWI" />
            </div>
          )}
          {treeParentIdValue === 8 && (
            <div className="product-colour-img">
              <img src={SINGLE_DATE_NDVI} alt="Single Date Stack NDVI" />
            </div>
          )}
          {treeParentIdValue === 9 && (
            <div className="product-colour-img">
              <img src={SINGLE_DATE_NDWI} alt="Single Date Stack NDWI" />
            </div>
          )}
          {treeParentIdValue === 10 && (
            <div className="product-colour-img">
              <img src={CROP_INTENSITY} alt="Crop Intensity" />
            </div>
          )}
          {treeParentIdValue === 11 && (
            <div className="product-colour-img">
              <img src={IRRIGATION_CONDITION} alt="Irrigation Condition" />
            </div>
          )}
        </div>
      ) : wmsLayerFirst &&
        wmsLayerFirst?.fileName !== '' &&
        wmsLayerFirst?.wms !== '' ? (
        <div className="map-descriptions">
          {treeParentIdValue === 3 && (
            <div className="product-colour-img">
              <img src={CROP_SOWN_AREA} alt="Crop Sown Area" />
            </div>
          )}
          {treeParentIdValue === 4 && (
            <div className="product-colour-img">
              <img src={TEMP_COMPOSITE_NDVI} alt="Temporal Composite NDVI" />
            </div>
          )}
          {treeParentIdValue === 5 && (
            <div className="product-colour-img">
              <img src={TEMP_COMPOSITE_NDWI} alt="Temporal Composite NDWI" />
            </div>
          )}
          {treeParentIdValue === 6 && (
            <div className="product-colour-img">
              <img src={CYGNUS_NDVI} alt="CYGNUS NDvI" />
            </div>
          )}
          {treeParentIdValue === 7 && (
            <div className="product-colour-img">
              <img src={CYGNUS_NDWI} alt="CYGNUS NDWI" />
            </div>
          )}
          {treeParentIdValue === 8 && (
            <div className="product-colour-img">
              <img src={SINGLE_DATE_NDVI} alt="Single Date Stack NDVI" />
            </div>
          )}
          {treeParentIdValue === 9 && (
            <div className="product-colour-img">
              <img src={SINGLE_DATE_NDWI} alt="Single Date Stack NDWI" />
            </div>
          )}
          {treeParentIdValue === 10 && (
            <div className="product-colour-img">
              <img src={CROP_INTENSITY} alt="Crop Intensity" />
            </div>
          )}
          {treeParentIdValue === 11 && (
            <div className="product-colour-img">
              <img src={IRRIGATION_CONDITION} alt="Irrigation Condition" />
            </div>
          )}
        </div>
      ) : wmsLayerSecond &&
        wmsLayerSecond?.fileName !== '' &&
        wmsLayerSecond?.wms !== '' ? (
        <div className="map-descriptions">
          {treeParentIdValue === 3 && (
            <div className="product-colour-img">
              <img src={CROP_SOWN_AREA} alt="Crop Sown Area" />
            </div>
          )}
          {treeParentIdValue === 4 && (
            <div className="product-colour-img">
              <img src={TEMP_COMPOSITE_NDVI} alt="Temporal Composite NDVI" />
            </div>
          )}
          {treeParentIdValue === 5 && (
            <div className="product-colour-img">
              <img src={TEMP_COMPOSITE_NDWI} alt="Temporal Composite NDWI" />
            </div>
          )}
          {treeParentIdValue === 6 && (
            <div className="product-colour-img">
              <img src={CYGNUS_NDVI} alt="CYGNUS NDvI" />
            </div>
          )}
          {treeParentIdValue === 7 && (
            <div className="product-colour-img">
              <img src={CYGNUS_NDWI} alt="CYGNUS NDWI" />
            </div>
          )}
          {treeParentIdValue === 8 && (
            <div className="product-colour-img">
              <img src={SINGLE_DATE_NDVI} alt="Single Date Stack NDVI" />
            </div>
          )}
          {treeParentIdValue === 9 && (
            <div className="product-colour-img">
              <img src={SINGLE_DATE_NDWI} alt="Single Date Stack NDWI" />
            </div>
          )}
          {treeParentIdValue === 10 && (
            <div className="product-colour-img">
              <img src={CROP_INTENSITY} alt="Crop Intensity" />
            </div>
          )}
          {treeParentIdValue === 11 && (
            <div className="product-colour-img">
              <img src={IRRIGATION_CONDITION} alt="Irrigation Condition" />
            </div>
          )}
        </div>
      ) : wmsSinlgeMergeLayer?.first &&
        wmsSinlgeMergeLayer?.first?.fileName !== '' &&
        wmsSinlgeMergeLayer?.first?.wms !== '' &&
        wmsSinlgeMergeLayer?.second &&
        wmsSinlgeMergeLayer?.second?.fileName !== '' &&
        wmsSinlgeMergeLayer?.second?.wms !== '' ? (
        <div className="single-merge-legend">
          <div className="map-descriptions">
            {treeParentIdValueSingle.left === 3 && (
              <div className="product-colour-img">
                <img src={CROP_SOWN_AREA} alt="Crop Sown Area" />
              </div>
            )}
            {treeParentIdValueSingle.left === 4 && (
              <div className="product-colour-img">
                <img src={TEMP_COMPOSITE_NDVI} alt="Temporal Composite NDVI" />
              </div>
            )}
            {treeParentIdValueSingle.left === 5 && (
              <div className="product-colour-img">
                <img src={TEMP_COMPOSITE_NDWI} alt="Temporal Composite NDWI" />
              </div>
            )}
            {treeParentIdValueSingle.left === 6 && (
              <div className="product-colour-img">
                <img src={CYGNUS_NDVI} alt="CYGNUS NDvI" />
              </div>
            )}
            {treeParentIdValueSingle.left === 7 && (
              <div className="product-colour-img">
                <img src={CYGNUS_NDWI} alt="CYGNUS NDWI" />
              </div>
            )}
            {treeParentIdValueSingle.left === 8 && (
              <div className="product-colour-img">
                <img src={SINGLE_DATE_NDVI} alt="Single Date Stack NDVI" />
              </div>
            )}
            {treeParentIdValueSingle.left === 9 && (
              <div className="product-colour-img">
                <img src={SINGLE_DATE_NDWI} alt="Single Date Stack NDWI" />
              </div>
            )}
            {treeParentIdValueSingle.left === 10 && (
              <div className="product-colour-img">
                <img src={CROP_INTENSITY} alt="Crop Intensity" />
              </div>
            )}
            {treeParentIdValueSingle.left === 11 && (
              <div className="product-colour-img">
                <img src={IRRIGATION_CONDITION} alt="Irrigation Condition" />
              </div>
            )}
          </div>
          <div className="map-descriptions">
            {treeParentIdValueSingle.right === 3 && (
              <div className="product-colour-img">
                <img src={CROP_SOWN_AREA} alt="Crop Sown Area" />
              </div>
            )}
            {treeParentIdValueSingle.right === 4 && (
              <div className="product-colour-img">
                <img src={TEMP_COMPOSITE_NDVI} alt="Temporal Composite NDVI" />
              </div>
            )}
            {treeParentIdValueSingle.right === 5 && (
              <div className="product-colour-img">
                <img src={TEMP_COMPOSITE_NDWI} alt="Temporal Composite NDWI" />
              </div>
            )}
            {treeParentIdValueSingle.right === 6 && (
              <div className="product-colour-img">
                <img src={CYGNUS_NDVI} alt="CYGNUS NDvI" />
              </div>
            )}
            {treeParentIdValueSingle.right === 7 && (
              <div className="product-colour-img">
                <img src={CYGNUS_NDWI} alt="CYGNUS NDWI" />
              </div>
            )}
            {treeParentIdValueSingle.right === 8 && (
              <div className="product-colour-img">
                <img src={SINGLE_DATE_NDVI} alt="Single Date Stack NDVI" />
              </div>
            )}
            {treeParentIdValueSingle.right === 9 && (
              <div className="product-colour-img">
                <img src={SINGLE_DATE_NDWI} alt="Single Date Stack NDWI" />
              </div>
            )}
            {treeParentIdValueSingle.right === 10 && (
              <div className="product-colour-img">
                <img src={CROP_INTENSITY} alt="Crop Intensity" />
              </div>
            )}
            {treeParentIdValueSingle.right === 11 && (
              <div className="product-colour-img">
                <img src={IRRIGATION_CONDITION} alt="Irrigation Condition" />
              </div>
            )}
          </div>
        </div>
      ) : particularChildlayerFileName ? (
        <div className="map-descriptions">
          {treeParentIdValue === 3 && (
            <div className="product-colour-img">
              <img src={CROP_SOWN_AREA} alt="Crop Sown Area" />
            </div>
          )}
          {treeParentIdValue === 4 && (
            <div className="product-colour-img">
              <img src={TEMP_COMPOSITE_NDVI} alt="Temporal Composite NDVI" />
            </div>
          )}
          {treeParentIdValue === 5 && (
            <div className="product-colour-img">
              <img src={TEMP_COMPOSITE_NDWI} alt="Temporal Composite NDWI" />
            </div>
          )}
          {treeParentIdValue === 6 && (
            <div className="product-colour-img">
              <img src={CYGNUS_NDVI} alt="CYGNUS NDvI" />
            </div>
          )}
          {treeParentIdValue === 7 && (
            <div className="product-colour-img">
              <img src={CYGNUS_NDWI} alt="CYGNUS NDWI" />
            </div>
          )}
          {treeParentIdValue === 8 && (
            <div className="product-colour-img">
              <img src={SINGLE_DATE_NDVI} alt="Single Date Stack NDVI" />
            </div>
          )}
          {treeParentIdValue === 9 && (
            <div className="product-colour-img">
              <img src={SINGLE_DATE_NDWI} alt="Single Date Stack NDWI" />
            </div>
          )}
          {treeParentIdValue === 10 && (
            <div className="product-colour-img">
              <img src={CROP_INTENSITY} alt="Crop Intensity" />
            </div>
          )}
          {treeParentIdValue === 11 && (
            <div className="product-colour-img">
              <img src={IRRIGATION_CONDITION} alt="Irrigation Condition" />
            </div>
          )}
        </div>
      ) : (
        <div className="map-descriptions">
          <h2>No dataset selected</h2>
        </div>
      )}
    </div>
  );
};
export default MapLayer;
