import React from 'react';
import { Spring, config, animated } from 'react-spring';
import { Checkbox, Tooltip } from '@mui/material';
import { getStatusIcon, getProductListName } from '../../utils/user-helper';
import { getDatasets } from '../../services/product-crop-services';
import { toast } from 'react-toastify';
import Loader from '../common-loader/index';
import moment from 'moment';
import './index.css';
// Images
import imgDown from '../../assets/images/down-arrow.svg';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '45%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    width: '610px',
    borderRadius: '8px',
    backdrop: 'transparent'
  }
};

const styles = {
  tree: {
    position: 'relative',
    paddingTop: 14,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    verticalAlign: 'middle'
  },
  title: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  toggle: {
    marginRight: 12
  },
  pic: {
    width: 12,
    height: 12,
    marginRight: 10,
    marginLeft: 10
  },
  status: {
    width: 16,
    height: 16,
    marginRight: 12
  },
  contents: {
    marginLeft: '28px',
    willChange: 'transform, opacity, height'
  }
};

export default class Tree extends React.PureComponent {
  static defaultProps = { open: false, visible: true };

  constructor(props) {
    super(props);
    this.state = {
      selectedItem: '',
      open: props.open,
      prevOpen: props.open,
      visible: props.visible,
      immediate: false,
      item: [],
      loader: false,
      fileShowModal: false,
      url: '',
      down_data: '',
      zipFileShowModal: false,
      expanded: {}
    };
  }

  zipFileToggleModal = (data) => {
    this.setState({
      down_data: data
    });
    this.setState((prevState) => ({
      zipFileShowModal: !prevState.zipFileShowModal
    }));
  };

  fileToggleModal = (data) => {
    this.setState({
      down_data: data
    });
    this.setState((prevState) => ({
      fileShowModal: !prevState.fileShowModal
    }));
  };

  toggle = async (requestTreeId, treeStatus) => {
    if (!this.state.open) {
      this.props.setActive(requestTreeId);
      this.props.getTreeParentId(requestTreeId);
    } else if (this.state.open && this.props.active === requestTreeId) {
      this.props.setActive('');
    }

    const { selectedItem } = this.state; // New state variable for tracking the selected item
    this.setState((prevState) => ({
      expanded: {
        ...prevState.expanded, // leave other panels expanded
        open: false
      }
    }));

    if (selectedItem !== requestTreeId) {
      this.setState({ loader: true });

      try {
        const { data, status, error } = await getDatasets(requestTreeId);

        if (status === 200) {
          this.setState((state) => ({
            selectedItem: requestTreeId,
            open: true,
            immediate: false,
            item: data?.data?.features,
            loader: false
          }));
          this.props.setResponseDataStatus(treeStatus);
          this.props.getResponseData(data?.data?.features, requestTreeId);
        }
        if (error) {
          throw new Error(error?.response?.data?.message);
        }
      } catch (error) {
        this.setState({ loader: false });
        toast.error(error.message);
      }
    } else if (!this.state.open) {
      this.setState((state) => ({ open: !state.open, immediate: false }));
      this.props.setResponseDataStatus(treeStatus);
      this.props.getResponseData(this.state.item, requestTreeId);
    } else {
      this.setState((state) => ({
        selectedItem: '', // Reset the selected item
        open: false,
        immediate: false
      }));
      this.props.getResponseData('', requestTreeId);
      // this.props.getTreeParentId('');
    }
  };

  toggleVisibility = () => {
    this.setState(
      (state) => ({ visible: !state.visible, immediate: true }),
      () => this.props.onClick && this.props.onClick(this.state.visible)
    );
  };

  checkBoxOpen = (parentId, subItem, checkBoxOpenStatus) => {
    this.setState({ url: subItem?.links[1]?.href });
    if (checkBoxOpenStatus) {
      return this.props.selectedCheckboxes.includes(
        parentId + '-' + subItem?.properties?.datetime
      );
    } else {
      return (
        this.props.singleSelectedCheckboxes ===
        parentId + '-' + subItem?.properties?.datetime
      );
    }
  };

  handleFileDownload = (url) => {
    if (url !== '') {
      this.setState((prevState) => ({
        fileShowModal: !prevState.fileShowModal
      }));
      const link = document.createElement('a');
      link.setAttribute('download', 'file');
      link.href = url;
      link.click();
    }
  };

  onChangeOpen = (id, subItem, checkBoxOpenStatus, fileName, productId) => {
    this.setState({
      url: subItem?.links[1]?.href
    });
    if (checkBoxOpenStatus) {
      this.props.handleCheckboxChange(
        id,
        subItem?.properties?.datetime,
        subItem,
        fileName,
        productId
      );
      this.props.getTreeParentProductId(productId);
    } else {
      this.props.singleHandleCheckboxChange(
        id,
        subItem?.properties?.datetime,
        subItem,
        fileName,
        productId
      );

      this.props.getTreeParentProductId(productId);
    }
  };

  render() {
    const { open, selectedItem, immediate, fileShowModal, zipFileShowModal } =
      this.state;
    const { style, requestTreeArrayData } = this.props;

    return (
      <div style={{ ...styles.tree, ...style }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            transition: 'background-color 0.3s ease',
            borderRadius: '4px',
            backgroundColor:
              requestTreeArrayData?.id === this.props.active
                ? 'rgba(194, 235, 202, 0.4)'
                : 'transparent'
          }}
          className="treeview">
          <div
            style={{
              display: 'flex',
              alignItems: 'center'
            }}>
            {requestTreeArrayData?.status_id === 2 ||
            requestTreeArrayData?.status_id === 3 ? (
              <>
                {!this.state.loader ? (
                  <img
                    src={imgDown}
                    className="toggle"
                    onClick={() => {
                      this.toggle(
                        requestTreeArrayData?.id,
                        requestTreeArrayData?.status_id
                      );
                    }}
                    style={{
                      ...styles.toggle,
                      cursor: 'pointer',
                      transform: open ? 'rotate(0deg)' : 'rotate(-90deg)'
                    }}
                    alt={'down-arrow'}
                  />
                ) : (
                  <Loader loader="request-loader" />
                )}
              </>
            ) : (
              <span
                style={{
                  width: '36px',
                  height: '20px'
                }}></span>
            )}

            {requestTreeArrayData?.status_id && (
              <img
                alt={'request-status'}
                src={getStatusIcon(requestTreeArrayData?.status_id).img}
                style={{
                  ...styles.status,
                  cursor:
                    requestTreeArrayData?.status_id === 2 ||
                    requestTreeArrayData?.status_id === 3
                      ? 'pointer'
                      : 'not-allowed'
                }}
              />
            )}
          </div>

          <Tooltip
            placement="right"
            title={`${requestTreeArrayData?.file_name}_${
              requestTreeArrayData?.from_date
            }_${
              getProductListName(requestTreeArrayData?.product_id)
                ?.treeTitleCode
            }_${requestTreeArrayData?.to_date}`}>
            <span
              style={{
                ...styles.title,
                color:
                  requestTreeArrayData?.status_id === 2 ||
                  requestTreeArrayData?.status_id === 3
                    ? '#4F4F4F'
                    : '#E0E0E0',
                cursor:
                  requestTreeArrayData?.status_id === 2 ||
                  requestTreeArrayData?.status_id === 3
                    ? 'pointer'
                    : 'not-allowed'
              }}>
              {requestTreeArrayData?.file_name +
                '_' +
                requestTreeArrayData?.from_date +
                '_' +
                getProductListName(requestTreeArrayData?.product_id)
                  ?.treeTitleCode +
                '_' +
                requestTreeArrayData?.to_date}
            </span>
          </Tooltip>
          {/*<div className="req-title">*/}
          {/*  <p className="req-title-content">{requestTreeArrayData?.file_name}</p>*/}
          {/*</div>*/}
          <div>
            {/* {(requestTreeArrayData?.status_id === 3 || requestTreeArrayData?.status_id === 2) && (
            //main-header
            <div className="hover-container">
              <img
                className="download-button"
                src={getStatusIcon(requestTreeArrayData?.status_id).pic}
                onClick={() =>
                  this.zipFileToggleModal(
                    moment(
                      requestTreeArrayData?.file_name?.properties?.datetime.split('T')[0]
                    ).format('DD-MMM-YYYY') +
                    '_' +
                    requestTreeArrayData?.file_name
                  )
                }
                style={{
                  ...styles.pic
                }}></img>
            </div>
          )} */}
          </div>
        </div>
        <div>
          {/* {zipFileShowModal && (
          <Modal

            style={customStyles}
            isOpen={zipFileShowModal}
            ariaHideApp={false}
            onRequestClose={this.zipFileToggleModal}
            contentLabel="Example Modal">
            <div className="api-key-modal-container">
              <h2 className="api-key-popup">Download</h2>
              <div className="api-key-body">
                <div className="file-download-name">
                  <span
                    className="sub-request-name-popup"
                    title={requestTreeArrayData?.product_id}>
                    {requestTreeArrayData?.file_name}
                  </span>
                </div>
                <div className="input_field">
                  <input type="radio" checked />
                  <label>Zip File</label>
                </div>
                <div className="download-modal-btn">
                  <button
                    className="modal_download"
                    onClick={() =>
                      this.handleZipFileDownload(this.state.url)
                    }>
                    Download
                  </button>
                  <button className="modal_cancel" onClick={this.zipFileToggleModal}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        )} */}
        </div>
        {open && (
          <Spring
            native
            immediate={immediate}
            config={{ ...config.default, precision: 0.1 }}
            from={{ height: 0, opacity: 0, transform: 'translate3d(20px,0,0)' }}
            to={{
              height: open ? 'auto' : 0,
              opacity: open ? 1 : 0,
              transform: open ? 'translate3d(0px,0,0)' : 'translate3d(20px,0,0)'
            }}>
            {(style) => (
              <animated.div style={{ ...style, ...styles.contents }}>
                {this.state.item && this.state.item.length !== 0 ? (
                  this.state.item.map((subItem) => {
                    const isChecked = this.checkBoxOpen(
                      requestTreeArrayData?.id,
                      subItem,
                      this.props.checkBoxOpenStatus
                    );
                    return (
                      <div className="sub-request" key={subItem?.id}>
                        <img
                          src={getStatusIcon(3).img}
                          className="icon"
                          alt={'sub-request-status'}
                        />
                        <Tooltip
                          placement="bottom"
                          disableInteractive
                          title={`${moment(
                            subItem?.properties?.datetime.split('T')[0]
                          ).format('DD-MMM-YYYY')}_${
                            requestTreeArrayData?.file_name
                          }_${
                            getProductListName(requestTreeArrayData?.product_id)
                              ?.treeTitleCode
                          }`}>
                          <span
                            className={`sub-request-name ${
                              !this.props.ismergedMap ? 'pointer-event' : ''
                            }`}
                            // className={`sub-request-name ${
                            //   subItem.status !== 'completed'
                            //     ? 'sub-request-name-disable'
                            //     : ''
                            // }`}
                            onClick={() => {
                              this.onChangeOpen(
                                requestTreeArrayData?.id,
                                subItem,
                                this.props.checkBoxOpenStatus,
                                requestTreeArrayData?.file_name,
                                requestTreeArrayData?.product_id
                              );
                              let checked = this.checkBoxOpen(
                                requestTreeArrayData?.id,
                                subItem,
                                this.props.checkBoxOpenStatus
                              );
                              if (checked) {
                                this.props.getTreeParentProductId('');
                              }
                            }}
                            style={{
                              color: isChecked ? '#2F80ED' : '#4F4F4F',
                              cursor: 'pointer',
                              fontWeight: isChecked ? 700 : 500
                            }}>
                            {moment(
                              subItem?.properties?.datetime.split('T')[0]
                            ).format('DD-MMM-YYYY') +
                              '_' +
                              requestTreeArrayData?.file_name +
                              '_' +
                              getProductListName(
                                requestTreeArrayData?.product_id
                              )?.treeTitleCode}
                          </span>
                        </Tooltip>

                        {this.props.checkBoxOpenStatus && (
                          <Checkbox
                            value={subItem?.id}
                            style={{
                              borderRadius: 4,
                              padding: 0,
                              marginLeft: 7,
                              height: 16,
                              width: 16,
                              marginRight: 2
                            }}
                            checked={this.checkBoxOpen(
                              requestTreeArrayData?.id,
                              subItem,
                              this.props.checkBoxOpenStatus
                            )}
                            disabled={!this.props.ismergedMap}
                            onChange={() =>
                              this.onChangeOpen(
                                requestTreeArrayData?.id,
                                subItem,
                                this.props.checkBoxOpenStatus,
                                requestTreeArrayData?.file_name,
                                requestTreeArrayData?.product_id
                              )
                            }
                          />
                        )}

                        {!this.props.checkBoxOpenStatus && (
                          <>
                            {(requestTreeArrayData?.status_id === 2 ||
                              requestTreeArrayData?.status_id === 3) && (
                              <div className="hover-container">
                                <img
                                  className="download-button"
                                  src={
                                    getStatusIcon(
                                      requestTreeArrayData?.status_id
                                    ).pic
                                  }
                                  onClick={() => {
                                    this.fileToggleModal(
                                      moment(
                                        subItem?.properties?.datetime.split(
                                          'T'
                                        )[0]
                                      ).format('DD-MMM-YYYY') +
                                        '_' +
                                        requestTreeArrayData?.file_name
                                    );
                                  }}
                                  style={{
                                    ...styles.pic
                                  }}></img>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })
                ) : (
                  <p className="child_no_data">No Data Available</p>
                )}
              </animated.div>
            )}
          </Spring>
        )}

        {fileShowModal && (
          <Modal
            style={customStyles}
            isOpen={fileShowModal}
            ariaHideApp={false}
            onRequestClose={this.fileToggleModal}
            contentLabel="Example Modal">
            <div className="api-key-modal-container">
              <h2 className="api-key-popup">Download</h2>
              <div className="api-key-body">
                <div className="file-download-name">
                  <span
                    className="sub-request-name-popup"
                    title={requestTreeArrayData?.product_id}>
                    {this.state.down_data}
                  </span>
                </div>
                <div className="input_field">
                  <input type="radio" checked />
                  <label>File</label>
                </div>
                <div className="download-modal-btn">
                  <button
                    className="modal_download"
                    onClick={() => this.handleFileDownload(this.state.url)}>
                    Download
                  </button>
                  <button
                    className="modal_cancel"
                    onClick={this.fileToggleModal}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
