export const BASE_URL = process.env.REACT_APP_BASE_URL;

/**
 * Google Auth Client ID
 */

export const CLIENT_ID =
  '960465184139-bla0vqfg7msu3n32jjst10c69omsnh6g.apps.googleusercontent.com';

/**
 * Google Recaptcha Key
 */

export const GOOGLE_RECAPTCHA = '6Leys1ckAAAAAME7hlA2pIrchYc5NjnTsqTLdcv3';

/* eslint-disable no-useless-escape */

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:

export const PASSWORD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&^#]{8,}$/;

export const USER_DETAILS_KEY = 'user-data';
export const QUERY_SETS = 'querysets';
export const header = {
  'Content-Type': 'application/json',
  accept: 'application/json'
};

export const REQUEST = 1;
export const INPROGESS = 2;
export const COMPLETED = 3;
export const DECLINE = 4;

export const SUPER_ADMIN = 1;
export const ADMIN = 2;
export const USER = 3;

export const getApiRequest = (key, treeIdValue) => {
  return `import requests
url = '${BASE_URL}/v1/data/product/{user_email}/${treeIdValue}/datasets'
header = {}
header['Authorization'] = '${key}'
response = requests.get(url, headers=header)`;
};
