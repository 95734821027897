import React, { useState } from 'react';
import Select from 'react-select';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import './index.css';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);
var options = {
  responsive: true,
  plugins: {
    legend: {
      display: false
    }
  },
  scales: {
    y: {
      border: {
        // dash: [5],
        // width: 0
      },

      display: true,
      grid: {
        borderDash: [5],
        borderWidth: 1,
        drawBorder: false,
        drawTicks: false,
        dash: [5],
        color: '#E0E0E0'
      }
    },
    x: {
      grid: {
        display: false
      }
    }
  }
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      data: [12, 19, 3, 12, 19, 3, 5],
      borderColor: '#0DB02B',
      pointStyle: false,
      tension: 0.1
    }
  ]
};
const ApiAnalytics = () => {
  const [apiActive, setApiAnalytics] = useState('apicalls');
  const [analytics, setAnalytics] = useState('product');

  return (
    <div className="apiAnalyticsContainer">
      <div className="analyticsHeader">
        <h1>Analytics</h1>
        <a href="/dashboard">API Documentation</a>
      </div>
      <div className="product-apiKey">
        <ul className="select-radio">
          <li>
            <label>
              <input
                type="radio"
                name="action"
                value="product"
                onClick={() => setAnalytics('product')}
                checked={analytics === 'product'}
              />
            </label>
            <span>Product</span>
          </li>
          <li>
            <label>
              <input
                type="radio"
                name="action"
                value="apikey"
                onClick={() => setAnalytics('apiKey')}
                checked={analytics === 'apiKey'}
              />
            </label>
            <span>API Keys</span>
          </li>
        </ul>
        <label className="vr-line"></label>
        {analytics === 'product' && (
          <div className="product-timerange">
            <div className="select-list">
              <label>Product</label>
              <Select
                placeholder="Select Product"
                classNamePrefix={'react-select'}
                // value={selectedOption.industry}
                // options={industryOption}
                onChange={(e) => {
                  // onChangeIndustry(e);
                  // setSelectedOption({ ...selectedOption, industry: e });
                }}
              />
            </div>
            <div className="select-list">
              <label>Time Range</label>
              <Select
                placeholder="Select Time Range"
                classNamePrefix={'react-select'}
                // value={selectedOption.industry}
                // options={industryOption}
                onChange={(e) => {
                  // onChangeIndustry(e);
                  // setSelectedOption({ ...selectedOption, industry: e });
                }}
              />
            </div>
          </div>
        )}
        {analytics === 'apiKey' && (
          <div className="product-timerange">
            <div className="select-list">
              <label>API Keys</label>
              <Select
                placeholder="API Keys"
                classNamePrefix={'react-select'}
                // value={selectedOption.industry}
                // options={industryOption}
                onChange={(e) => {
                  // onChangeIndustry(e);
                  // setSelectedOption({ ...selectedOption, industry: e });
                }}
              />
            </div>
            <div className="select-list">
              <label>Time Range</label>
              <Select
                placeholder="Select Time Range"
                classNamePrefix={'react-select'}
                // value={selectedOption.industry}
                // options={industryOption}
                onChange={(e) => {
                  // onChangeIndustry(e);
                  // setSelectedOption({ ...selectedOption, industry: e });
                }}
              />
            </div>
          </div>
        )}
      </div>
      <ul className="select-api-list">
        <li
          className={apiActive === 'apicalls' ? 'api-card active' : 'api-card'}
          onClick={() => setApiAnalytics('apicalls')}>
          <h2>API Calls</h2>
          <label>Total API Calls</label>
          <span>20,34,222</span>
        </li>
        <li
          className={apiActive === 'errors' ? 'api-card active' : 'api-card'}
          onClick={() => setApiAnalytics('errors')}>
          <h2>Errors</h2>
          <label>Average Error Rate</label>
          <span>1.9%</span>
        </li>
        <li
          className={apiActive === 'latency' ? 'api-card active' : 'api-card'}
          onClick={() => setApiAnalytics('latency')}>
          <h2>Latency</h2>
          <label>Average Latency</label>
          <span>212.3ms</span>
        </li>
      </ul>
      <div class="api-analytics-chart">
        <Line class="api-line" options={options} data={data} />
      </div>
    </div>
  );
};

export default ApiAnalytics;
