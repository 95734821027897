import { USER_DETAILS_KEY } from '../utils/constants';
import { getRequest, postRequest } from '../utils/http-helper';
import {
  PRODUCT_CROP,
  PRODUCT_URL,
  DATA_REQUEST_FILTER_URL,
  AOI_PRODUCT_URL,
  DATASET_URL,
  DASHBOARD_BASE_URL,
  API_KEY_URL
} from '../utils/url-helper';

import { attachParams, getUserDetails } from '../utils/user-helper';

export const getProductCrops = async (productId) => {
  try {
    return await getRequest({
      url: `${PRODUCT_CROP}/${productId}/crops`,
      headers: true,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const getProducts = async () => {
  try {
    return await getRequest({
      url: PRODUCT_URL,
      headers: true,
      Auth: true
    });
  } catch (e) {
    return e;
  }
};

export const getProductAvailability = async (productId, payload) => {
  try {
    let relativeUrl = `${DATA_REQUEST_FILTER_URL}/${productId}/data-availability`;
    let url = attachParams(relativeUrl, payload);

    return await getRequest({
      url: url,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const getAvailableDates = async (productId, payload) => {
  try {
    return await postRequest({
      url: `${DATA_REQUEST_FILTER_URL}/${productId}/available-dates`,
      Auth: true,
      headers: true,
      data: payload
    });
  } catch (e) {
    return e;
  }
};

export const getRunRequest = async (requestId, payload) => {
  try {
    let email = getUserDetails(USER_DETAILS_KEY)?.userEmail;
    let url = `${DATA_REQUEST_FILTER_URL}/${requestId}/${email}/run-data-request`;
    return await postRequest({
      url: url,
      data: payload,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return;
  }
};

export const getAoiProduct = async (payload) => {
  try {
    return postRequest({
      url: AOI_PRODUCT_URL,
      data: payload,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const getDatasets = async (id) => {
  try {
    let userEmail = getUserDetails(USER_DETAILS_KEY)?.userEmail;
    return await getRequest({
      url: `${DATASET_URL}/${userEmail}/${id}/datasets`,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const dataReuestTree = async () => {
  try {
    let userEmail = getUserDetails(USER_DETAILS_KEY)?.userEmail;
    return await getRequest({
      url: `${DASHBOARD_BASE_URL}/${userEmail}/data-request-tree`,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};

export const onGetApiKeyList = async () => {
  try {
    let userEmail = getUserDetails(USER_DETAILS_KEY)?.userEmail;
    let url = attachParams(`${API_KEY_URL}/${userEmail}`, { isHome: true });
    return await getRequest({
      url: url,
      Auth: true,
      headers: true
    });
  } catch (e) {
    return e;
  }
};
