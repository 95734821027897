import axios from 'axios';
import { USER_DETAILS_KEY } from './constants';
import { REFRESH_TOKEN_URL } from './url-helper';

import { getToken } from './user-helper';
import { header } from '../utils/constants';

export const sendRequest = async (args) => {
  try {
    const { url, headers, Auth, refresh } = args;
    let headerParams;
    if (Auth) {
      if (headers) {
        let token = await getToken(USER_DETAILS_KEY);
        headerParams = {
          ...header,
          Authorization: `${token?.accessToken}`
        };
      }
      if (refresh) {
        let token = await getToken(USER_DETAILS_KEY);
        headerParams = {
          Authorization: `${token?.refreshToken}`
        };
      }
    }

    const response = await axios({
      ...args,
      headers: headerParams,
      url: url
    });

    return response;
  } catch (error) {
    const { config } = error;
    let status = error?.response?.status;
    // let message = error?.response?.data?.msg;

    return { error, status };
  }
};

export const getRequest = async (args) => {
  const { data, headers, error, status } = await sendRequest({
    ...args,
    method: 'get'
  });
  if (status === 200) {
    return {
      data,
      error: null,
      headers,
      status
    };
  }
  return {
    data,
    error: error || data,
    status
  };
};

export const postRequest = async (args) => {
  const { data, headers, error, status } = await sendRequest({
    ...args,
    method: 'post'
  });

  if ([200, 201, 204].indexOf(status) > -1) {
    return {
      data,
      error: null,
      headers,
      status
    };
  }
  return {
    data: null,
    error: error || data,
    status
  };
};

export const patchRequest = async (args) => {
  const { data, headers, error, status } = await sendRequest({
    ...args,
    method: 'patch'
  });
  if ([200, 201, 204].indexOf(status) > -1) {
    return {
      data,
      error: null,
      headers,
      status
    };
  }
  return {
    data: null,
    error: error || data,
    status
  };
};

export const deleteRequest = async (args) => {
  const { data, error, status, headers } = await sendRequest({
    ...args,
    method: 'delete'
  });
  if ([200, 201, 204].indexOf(status) > -1) {
    return {
      data,
      error: null,
      headers,
      status
    };
  }
  return {
    data: null,
    error: error || data,
    status
  };
};

export const putRequest = async (args) => {
  const { data, error, status, headers } = await sendRequest({
    ...args,
    method: 'put'
  });
  if ([200, 201, 204].indexOf(status) > -1) {
    return {
      data,
      error: null,
      headers,
      status
    };
  }
  return {
    data: null,
    error: error || data,
    status
  };
};

export const refreshTokenRequest = async () => {
  try {
    const header = {
      'Content-Type': 'application/json',
      accept: 'application/json'
    };
    header['Authorization'] = JSON.parse(
      localStorage.getItem(USER_DETAILS_KEY)
    )?.refreshToken;
    const response = await axios({
      url: REFRESH_TOKEN_URL,
      headers: header,
      method: 'POST'
    });
    return response;
  } catch (e) {
    return e;
  }
};
