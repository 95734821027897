export const POPULAR_TOPICS = [
    {
        id: 1,
        question: 'How do I contact SatSure Sparta Customer Support?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, ' +
          'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' +
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ' +
          'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ' +
          'Excepteur sint occaecat cupidatat non proident, ' +
          'sunt in culpa qui officia deserunt mollit anim id est laborum.'
    },
    {
        id: 2,
        question: 'Verifying your account',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, ' +
          'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' +
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ' +
          'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ' +
          'Excepteur sint occaecat cupidatat non proident, ' +
          'sunt in culpa qui officia deserunt mollit anim id est laborum.'
    },
    {
        id: 3,
        question: 'How to download delivery report?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, ' +
          'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' +
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ' +
          'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ' +
          'Excepteur sint occaecat cupidatat non proident, ' +
          'sunt in culpa qui officia deserunt mollit anim id est laborum.'
    },
    {
        id: 4,
        question: 'Where can I learn about data products in detail?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, ' +
          'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' +
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ' +
          'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ' +
          'Excepteur sint occaecat cupidatat non proident, ' +
          'sunt in culpa qui officia deserunt mollit anim id est laborum.'
    },
    {
        id: 5,
        question: 'Why I am not able to run new request ?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, ' +
          'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' +
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ' +
          'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ' +
          'Excepteur sint occaecat cupidatat non proident, ' +
          'sunt in culpa qui officia deserunt mollit anim id est laborum.'
    },
    {
        id: 6,
        question: 'Where can I see my all requested files?',
        answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, ' +
          'sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ' +
          'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. ' +
          'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. ' +
          'Excepteur sint occaecat cupidatat non proident, ' +
          'sunt in culpa qui officia deserunt mollit anim id est laborum.'
    },
]

export const API_KEYS = [
  {
    label: 'API Key 1',
    value: 1
  },
  {
    label: 'API Key 2',
    value: 2
  },
  {
    label: 'API Key 3',
    value: 3
  },
  {
    label: 'API Key 4',
    value: 4
  },
  {
    label: 'API Key 5',
    value: 5
  },
]
