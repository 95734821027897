import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Tree from '../treeview';
import { isEmpty } from 'lodash';
import imgCross from '../../assets/images/cross.svg';

import './index.css';

const RequestComparisonSection = ({
  requestTreeData,
  setCompareMap,
  setResponseData,
  mapstate,
  setMapState,
  setResponseDataStatus,
  singleMapState,
  setMapCompare,
  setSingleMapState,
  setTreeParentIdValue,
  setTreeIdValue,
  ismergedMap,
  selectedCheckboxes,
  setSelectedCheckboxes,
  setLegentType,
  show,
  setShow
}) => {
  const navigate = useNavigate();
  const [isCompareRequest, setIsCompareRequest] = useState(false);
  const [isCompare, setIsCompare] = useState(true);
  const [singleSelectedCheckboxes, setSingleSelectedCheckboxes] = useState([]);
  const [active, setActive] = useState('');

  const handleCheckBox = () => {
    setIsCompareRequest(!isCompareRequest);
    setCompareMap((prev) => !prev);
    setMapCompare(true);
    if (!isCompareRequest) {
      setMapState({ ...mapstate, first: singleMapState.single });
      selectedCheckboxes.push(singleMapState.single.childId);
      setLegentType({ left: singleMapState.single.productId, right: '' });
    }
    if (isCompareRequest) {
      setIsCompare(true);
      setLegentType({ left: '', right: '' });
      setSelectedCheckboxes([]);
      setMapState({
        first: {
          childId: '',
          parentId: '',
          wms: '',
          fileName: '',
          productId: ''
        },
        second: {
          childId: '',
          parentId: '',
          wms: '',
          fileName: '',
          productId: ''
        }
      });
      setLegentType({ left: '', right: '' });
    }

    setSingleSelectedCheckboxes();
    getTreeParentProductId('');
    setSingleMapState({
      single: {
        childId: '',
        parentId: '',
        wms: '',
        fileName: '',
        productId: ''
      }
    });
  };

  const singleHandleCheckboxChange = (
    parentId,
    childId,
    wmsLayerValue,
    fileName,
    productId
  ) => {
    const value = parentId + '-' + childId;
    if (singleSelectedCheckboxes === value) {
      setSingleSelectedCheckboxes();
      setIsCompare(true);
      setSingleMapState((prev) => ({
        ...prev,
        single: {
          childId: '',
          parentId: '',
          wms: '',
          fileName: '',
          productId: ''
        }
      }));
    } else {
      setSingleSelectedCheckboxes(value);
      setIsCompare(false);
      setSingleMapState((prev) => ({
        ...prev,
        single: {
          childId: value,
          parentId: parentId,
          wms: wmsLayerValue,
          fileName: fileName,
          productId: productId
        }
      }));
    }
  };

  const handleCheckboxChange = (
    parentId,
    childId,
    wmsLayerValue,
    fileName,
    productId
  ) => {
    const value = parentId + '-' + childId;
    if (
      selectedCheckboxes.length === 2 &&
      !selectedCheckboxes.includes(value)
    ) {
      setSelectedCheckboxes([selectedCheckboxes[1], value]);
      if (mapstate.first.childId !== selectedCheckboxes[1]) {
        setMapState((prev) => ({
          ...prev,
          first: {
            childId: value,
            parentId: parentId,
            wms: wmsLayerValue,
            fileName: fileName,
            productId: productId
          }
        }));
        setLegentType((prev) => ({ ...prev, left: productId }));
      } else {
        setMapState((prev) => ({
          ...prev,
          second: {
            childId: value,
            parentId: parentId,
            wms: wmsLayerValue,
            fileName: fileName,
            productId: productId
          }
        }));
        setLegentType((prev) => ({ ...prev, right: productId }));
      }
    } else if (selectedCheckboxes.includes(value)) {
      setSelectedCheckboxes(
        selectedCheckboxes.filter((item) => item !== value)
      );

      if (mapstate.first.childId === value) {
        setMapState((prev) => ({
          ...prev,
          first: {
            childId: '',
            parentId: '',
            wms: '',
            fileName: '',
            productId: ''
          }
        }));
        setLegentType((prev) => ({ ...prev, left: '' }));
      } else if (mapstate.second.childId === value) {
        setMapState((prev) => ({
          ...prev,
          second: {
            childId: '',
            parentId: '',
            wms: '',
            fileName: '',
            productId: ''
          }
        }));
      }
      setLegentType((prev) => ({ ...prev, right: '' }));
    } else {
      setSelectedCheckboxes([...selectedCheckboxes, value]);
      if (mapstate.first.childId === '' && mapstate.second.childId === '') {
        setMapState((prev) => ({
          ...prev,
          first: {
            childId: value,
            parentId: parentId,
            wms: wmsLayerValue,
            fileName: fileName,
            productId: productId
          }
        }));
        setLegentType((prev) => ({ ...prev, left: productId }));
      } else if (
        mapstate.first.childId !== '' &&
        mapstate.second.childId === ''
      ) {
        setMapState((prev) => ({
          ...prev,
          second: {
            childId: value,
            parentId: parentId,
            wms: wmsLayerValue,
            fileName: fileName,
            productId: productId
          }
        }));
        setLegentType((prev) => ({ ...prev, right: productId }));
      } else if (
        mapstate.second.childId !== '' &&
        mapstate.first.childId === ''
      ) {
        setMapState((prev) => ({
          ...prev,
          first: {
            childId: value,
            parentId: parentId,
            wms: wmsLayerValue,
            fileName: fileName,
            productId: productId
          }
        }));
        setLegentType((prev) => ({ ...prev, left: productId }));
      }
    }
  };

  const getResponseData = (data, id) => {
    if (data.length !== 0) {
      setResponseData({ response: data });
      setShow({ ...show, response: true, request: true });
    } else if (active === id && data === '') {
      setResponseData({ response: data });
      setShow({ ...show, response: false, request: false });
      getTreeParentId('');
      getResponseDataStatus('');
    } else if (data !== '' && data.length === 0) {
      setResponseData({ response: data });
      setShow({ ...show, response: true, request: true });
    }
  };

  const getTreeParentProductId = (parentProductId) => {
    setTreeParentIdValue(parentProductId);
  };

  const getTreeParentId = (parentId) => {
    setTreeIdValue(parentId);
  };

  const getResponseDataStatus = (status) => {
    setResponseDataStatus(status);
  };

  return (
    <div className="req-comp-sections">
      <div className="title-container">
        <label className="title">Requests</label>
        <button
          className={`compare-button ${isCompareRequest ? 'compare-button-selected' : ''
            }`}
          onClick={() => {
            handleCheckBox();
          }}
          disabled={isCompare}>
          Compare
          {isCompareRequest && (
            <img className="compare-button-cross" src={imgCross} alt="cross" />
          )}
        </button>
      </div>
      <div className="tree-container">
        {requestTreeData &&
          requestTreeData.length !== 0 &&
          requestTreeData.map((item) => (
            <Tree
              active={active}
              setActive={setActive}
              checkBoxOpenStatus={isCompareRequest}
              requestTreeArrayData={item}
              getTreeParentProductId={getTreeParentProductId}
              getTreeParentId={getTreeParentId}
              setResponseDataStatus={getResponseDataStatus}
              singleSelectedCheckboxes={singleSelectedCheckboxes}
              selectedCheckboxes={selectedCheckboxes}
              getResponseData={getResponseData}
              singleHandleCheckboxChange={singleHandleCheckboxChange}
              handleCheckboxChange={handleCheckboxChange}
              ismergedMap={ismergedMap}></Tree>
          ))}
      </div>
      <label className="view-all" onClick={() => navigate('/all-requests')}>
        View All
      </label>
      <div className="notes">
        <span className="note">
          {isEmpty(requestTreeData)
            ? '//No requests made. Find all your request made here.'
            : selectedCheckboxes.length > 0
              ? 'Note : You can select only 2 requests for comparison.'
              : ''}
        </span>
      </div>
    </div>
  );
};

export default RequestComparisonSection;
