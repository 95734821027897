import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Back from '../../assets/images/back.svg';
import DobuleArrow from '../../assets/images/double-arrow-right.svg';
import RightArrow from '../../assets/images/right-arrow.svg';

import './sidebar.css';

const Sidebar = ({ tab, active, setActive }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split('/');

  const [expand, setExpand] = useState(false);
  const [agInputOpen, setAgInputOpen] = useState(true);
  const [foodCommOpen, setFoodCommOpen] = useState(false);

  return (
    <div>
      <div className="heading-wrapper">
        <span
          className="image-wrapper"
          onClick={() => navigate('/dashboard', { replace: true })}>
          <img src={Back} alt="back button" />
        </span>

        <h1 className="setting-header">
          {tab === 'setting'
            ? `Settings`
            : tab === 'documentation'
              ? 'Documentation'
              : 'Support'}
        </h1>
      </div>
      {tab === 'setting' ? (
        <ul className="sidebar-tab-list">
          <li
            className={
              active === 'profile' ? 'sidebar-list active' : 'sidebar-list'
            }
            onClick={() => setActive('profile')}>
            <label className="label-text">Profile</label>
          </li>
          <li
            className={
              active === 'password' ? 'sidebar-list active' : 'sidebar-list'
            }
            onClick={() => setActive('password')}>
            <label className="label-text">Password</label>
          </li>
          <li>
            <div className="sidebar-list" onClick={() => setExpand(!expand)}>
              <span
                className={
                  expand ? 'list-image-wrapper' : 'list-image-wrapper rotate'
                }>
                <img src={DobuleArrow} alt="dobule arrow" />
              </span>
              <label>API Mangagement</label>
            </div>
            <ul className={expand ? 'api-managment collapse' : 'api-managment'}>
              <li
                className={
                  active === 'apiKey' ? 'sidebar-list active' : 'sidebar-list'
                }
                onClick={() => setActive('apiKey')}>
                API Key
              </li>
              {/* <li
                className={
                  active === 'apiAnalytics'
                    ? 'sidebar-list active'
                    : 'sidebar-list'
                }
                onClick={() => setActive('apiAnalytics')}>
                API Analytics
              </li> */}
            </ul>
          </li>
        </ul>
      ) : tab === 'documentation' ? (
        <ul className="sidebar-tab-list">
          <li>
            <div
              className="sidebar-list"
              onClick={() => {
                if (path[2] !== 'api-docs') {
                  navigate('/documentation/api-docs/#products');
                } else {
                  navigate('/documentation');
                }
              }}>
              <span
                className={
                  path[2] === 'api-docs'
                    ? 'list-image-wrapper rotate'
                    : 'list-image-wrapper '
                }>
                <img src={DobuleArrow} alt="dobule arrow" />
              </span>
              <label>API Docs</label>
            </div>
            <ul
              className={
                path[2] === 'api-docs'
                  ? 'api-managment'
                  : 'api-managment  collapse'
              }>
              <li
                className={
                  location.hash === '#products'
                    ? 'sidebar-list active'
                    : 'sidebar-list'
                }
                onClick={() => navigate('/documentation/api-docs/#products')}>
                Products
              </li>
              <li
                className={
                  location.hash === '#crops'
                    ? 'sidebar-list active'
                    : 'sidebar-list'
                }
                onClick={() => navigate('/documentation/api-docs/#crops')}>
                Crops
              </li>
              <li
                className={
                  location.hash === '#datasets'
                    ? 'sidebar-list active'
                    : 'sidebar-list'
                }
                onClick={() => navigate('/documentation/api-docs/#datasets')}>
                Datasets
              </li>
              <li
                className={
                  location.hash === '#run-request'
                    ? 'sidebar-list active'
                    : 'sidebar-list'
                }
                onClick={() =>
                  navigate('/documentation/api-docs/#run-request')
                }>
                Run Requests
              </li>
              <li
                className={
                  location.hash === '#wms'
                    ? 'sidebar-list active'
                    : 'sidebar-list'
                }
                onClick={() => navigate('/documentation/api-docs/#wms')}>
                WMS
              </li>
              <li
                className={
                  location.hash === '#wcs'
                    ? 'sidebar-list active'
                    : 'sidebar-list'
                }
                onClick={() => navigate('/documentation/api-docs/#wcs')}>
                WCS
              </li>
              {/* <li>
                <Child />
              </li> */}
            </ul>
          </li>
          {/* <li>
            <div className="sidebar-list">
              <span
                className={
                  expand ? 'list-image-wrapper rotate' : 'list-image-wrapper '
                }
                onClick={() => setExpand(!expand)}>
                <img src={DobuleArrow} alt="dobule arrow" />
              </span>
              <label>API Mangagement</label>
            </div>
            <ul
              className={expand ? 'api-managment' : 'api-managment  collapse'}>
              <li
                className={
                  active === 'apiKey' ? 'sidebar-list active' : 'sidebar-list'
                }>
                API Key
              </li>
              <li>
                <Child />
              </li>
            </ul>
          </li> */}
          {/* <li className="sidebar-list">
            <label className="label-text">API key 2</label>
          </li> */}
          {/* <li>
            <div className="sidebar-list">
              <span
                className={
                  expand ? 'list-image-wrapper rotate' : 'list-image-wrapper '
                }
                onClick={() => setExpand(!expand)}>
                <img src={DobuleArrow} alt="dobule arrow" />
              </span>
              <label>API Mangagement</label>
            </div>
            <ul
              className={expand ? 'api-managment' : 'api-managment  collapse'}>
              <li
                className={
                  active === 'apiKey' ? 'sidebar-list active' : 'sidebar-list'
                }>
                API Key
              </li>
              <li>
                <Child />
              </li>
            </ul>
          </li> */}

          <li>
            <div
              className="sidebar-list"
              onClick={() => {
                if (path[2] !== 'products') {
                  navigate('/documentation/products/#NDVI');
                } else {
                  navigate('/documentation');
                }
              }}>
              <span
                className={
                  path[2] === 'products'
                    ? 'list-image-wrapper rotate'
                    : 'list-image-wrapper '
                }>
                <img src={DobuleArrow} alt="dobule arrow" />
              </span>
              <label>Products</label>
            </div>
            <ul
              className={
                path[2] === 'products'
                  ? 'api-managment'
                  : 'api-managment  collapse'
              }>
              <li
                className={
                  location.hash === '#NDVI'
                    ? 'sidebar-list active'
                    : 'sidebar-list'
                }
                onClick={() => navigate('/documentation/products/#NDVI')}>
                NDVI
              </li>
              <li
                className={
                  location.hash === '#NDWI'
                    ? 'sidebar-list active'
                    : 'sidebar-list'
                }
                onClick={() => navigate('/documentation/products/#NDWI')}>
                NDWI
              </li>
              <li
                className={
                  location.hash === '#Crop-Sown-Area'
                    ? 'sidebar-list active'
                    : 'sidebar-list'
                }
                onClick={() =>
                  navigate('/documentation/products/#Crop-Sown-Area')
                }>
                Crop Sown Area
              </li>
              <li
                className={
                  location.hash === '#SatSure-Cygnus'
                    ? 'sidebar-list active'
                    : 'sidebar-list'
                }
                onClick={() =>
                  navigate('/documentation/products/#SatSure-Cygnus')
                }>
                SatSure Cygnus
              </li>
              {/* <li
                className={
                  location.hash === '#Irrigation-Condition'
                    ? 'sidebar-list active'
                    : 'sidebar-list'
                }
                onClick={() =>
                  navigate('/documentation/products/#Irrigation-Condition')
                }>
                Irrigation Conditon
              </li> */}
            </ul>
          </li>

          <li>
            <div
              className="sidebar-list"
              onClick={() => {
                if (path[2] !== 'industry') {
                  navigate('/documentation/industry/#sales');
                } else {
                  navigate('/documentation');
                }
              }}>
              <span
                className={
                  path[2] === 'industry'
                    ? 'list-image-wrapper rotate'
                    : 'list-image-wrapper '
                }>
                <img src={DobuleArrow} alt="double arrow" />
              </span>
              <label>Industry</label>
            </div>
            <ul
              className={
                path[2] === 'industry'
                  ? 'industry-managment'
                  : 'industry-managment collapse'
              }>
              <li>
                <div
                  className={
                    location.hash === '#aginput'
                      ? 'sidebar-list active'
                      : 'sidebar-list'
                  }
                  onClick={() => setAgInputOpen(!agInputOpen)}>
                  <span
                    className={
                      agInputOpen
                        ? 'list-image-wrapper rotate'
                        : 'list-image-wrapper'
                    }>
                    <img src={DobuleArrow} alt="double arrow" />
                  </span>
                  <label>Ag Input</label>
                </div>

                {agInputOpen && (
                  <ul className="ag-input">
                    <li
                      className={
                        location.hash === '#sales'
                          ? 'sidebar-list active'
                          : 'sidebar-list'
                      }
                      onClick={() =>
                        navigate('/documentation/industry/#sales')
                      }>
                      Sales and Market
                    </li>
                    <li
                      className={
                        location.hash === '#chemical'
                          ? 'sidebar-list active'
                          : 'sidebar-list'
                      }
                      onClick={() =>
                        navigate('/documentation/industry/#chemical')
                      }>
                      Chemical Comp.
                    </li>
                  </ul>
                )}
              </li>

              <li>
                <div
                  className={
                    location.hash === '#comm'
                      ? 'sidebar-list active'
                      : 'sidebar-list'
                  }
                  onClick={() => setFoodCommOpen(!foodCommOpen)}>
                  <span
                    className={
                      foodCommOpen
                        ? 'list-image-wrapper rotate'
                        : 'list-image-wrapper'
                    }>
                    <img src={DobuleArrow} alt="double arrow" />
                  </span>
                  <label>Food & Comm</label>
                </div>

                {foodCommOpen && (
                  <ul className="food-comm-list">
                    <li
                      className={
                        location.hash === '#food'
                          ? 'sidebar-list active'
                          : 'sidebar-list'
                      }
                      onClick={() => navigate('/documentation/industry/#food')}>
                      Procurement Dept.{' '}
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </li>
        </ul>
      ) : (
        <ul className="sidebar-tab-list">
          <li className="sidebar-list">
            <label className="label-text">Getting Started</label>
          </li>

          <li>
            <div className="sidebar-list">
              <span
                className={
                  expand ? 'list-image-wrapper rotate' : 'list-image-wrapper '
                }
                onClick={() => setExpand(!expand)}>
                <img src={DobuleArrow} alt="dobule arrow" />
              </span>
              <label>API Mangagement</label>
            </div>
            <ul
              className={expand ? 'api-managment' : 'api-managment  collapse'}>
              <li
                className={
                  active === 'apiKey' ? 'sidebar-list active' : 'sidebar-list'
                }>
                API Key
              </li>
              {/* <li>
                <Child />
              </li> */}
            </ul>
          </li>
          {/* <li>
            <div className="sidebar-list">
              <span
                className={
                  expand ? 'list-image-wrapper rotate' : 'list-image-wrapper '
                }
                onClick={() => setExpand(!expand)}>
                <img src={DobuleArrow} alt="dobule arrow" />
              </span>
              <label>Account</label>
            </div>
            <ul
              className={expand ? 'api-managment' : 'api-managment  collapse'}>
              <li
                className={
                  active === 'apiKey' ? 'sidebar-list active' : 'sidebar-list'
                }>
                Profile Setting
              </li>
              <li>
                <Child />
              </li>
            </ul>
          </li> */}
        </ul>
      )}
    </div>
  );
};

export default Sidebar;

export const Child = () => {
  const [expand, setExpand] = useState(false);
  return (
    <>
      <div className="child-sidebar-list">
        <span
          className={
            expand ? 'child-image-wrapper rotate' : 'child-image-wrapper '
          }
          onClick={() => setExpand(!expand)}>
          <img src={RightArrow} alt="right arrow" />
        </span>
        <label>API Analytics</label>
      </div>
      <ul className={expand ? 'api-managment' : 'api-managment  collapse'}>
        <li className="sidebar-list child">
          <label>API Key</label>
        </li>
        <li className="sidebar-list child">
          <label>API Analytics</label>
        </li>
      </ul>
    </>
  );
};
