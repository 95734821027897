import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Modal from 'react-modal';
import moment from 'moment';
import { subDays, addDays } from 'date-fns';
import { toast } from 'react-toastify';
import * as turf from '@turf/turf';
import toGeoJSON from '@mapbox/togeojson';

// Components
import MapLayer from '../../components/map-layer';
import MapCompare from '../../components/map-compare';
import RequestComparisonSection from '../../components/request-comp-sec';
import PopupModal from '../../components/modal/modal';
import Loader from '../../components/common-loader';

// Utils
import { getApiRequest } from '../../utils/constants';
import { getStatusIcon, storeUserDetails } from '../../utils/user-helper';

// Json
import MapJson from '../../assets/json/geojson.json';
import SampleGeoJson from '../../assets/json/sample_geojson.geojson';

// API Services
import {
  getProductAvailability,
  getProductCrops,
  getProducts,
  getAvailableDates,
  getRunRequest,
  dataReuestTree,
  onGetApiKeyList
} from '../../services/product-crop-services';

// Images
import Upload from '../../assets/images/upload.svg';
import Premium from '../../assets/images/icons/premium.svg';
import UpArrow from '../../assets/images/up.svg';
import Merge from '../../assets/images/icons/merge.svg';
import MergeBlack from '../../assets/images/icons/merge-black.svg';
import MergeSplit from '../../assets/images/icons/merge-split.svg';
import MergeSplitOverlay from '../../assets/images/icons/merge-overlay.svg';
// import Tick from '../../assets/images/tick.svg';
import Info from '../../assets/images/icons/info.svg';
import ExpandContent from '../../assets/images/expand_content.svg';
import MergerError from '../../assets/images/merge-popup-error.svg';

// CSS
import 'react-datepicker/dist/react-datepicker.css';
import './dashboard.css';

const gjv = require('geojson-validation');

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    borderRadius: '8px',
    textAlign: 'center'
  }
};

// const pythonOptions = [
//   { value: 'Option1', label: 'Option1' },
//   { value: 'Option2', label: 'Option2' },
//   {
//     value: 'Option3',
//     label: 'Option3'
//   }
// ];
const Dashboard = () => {
  const inputRef = useRef();
  const [treeParentIdValue, setTreeParentIdValue] = useState();
  const [legendType, setLegentType] = useState({
    left: '',
    right: ''
  });
  const [treeIdValue, setTreeIdValue] = useState('');
  const [dataAvailability, setDataAvailability] = useState(false);
  const [runRequestLoaderStatus, setRunRequestLoaderStatus] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [layerAoiStatus, setLayerAoiStatus] = useState(false);
  const [uuidCompareData, setUuidCompareData] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [particularDateRange, setParticularDateRange] = useState([null, null]);
  const [particularStartDate, particularEndDate] = particularDateRange;
  const [show, setShow] = useState({
    request: false,
    response: false,
    metaData: false
  });
  const [compareMap, setCompareMap] = useState(true);
  const [mapCompare, setMapCompare] = useState(true);
  const [mergeMapCompare, setMergeMapCompare] = useState(false);
  const [singleMapCompare, setSingleMapCompare] = useState(false);
  const [selectedFilterOption, setSelectedFilterOption] = useState({
    products: null,
    crop: null,
    aoi: null,
    dateRange: []
  });
  const [productsOption, setProductsOption] = useState([]);
  const [apiKeyOption, setApiKeyOption] = useState([]);
  const [selectedkey, setSelectedKey] = useState(null);
  const [highlightData, sethighlightData] = useState([]);
  const [cropOption, setCropOption] = useState([]);
  const [error, setError] = useState(false);
  const [fileName, setFileName] = useState('Upload AOI File');
  const [showModal, setShowModal] = useState(false);
  const [aoi, setAoi] = useState({});
  const [treeData, setTreeData] = useState([]);
  const [modalType, setModalType] = useState('');
  const [responseData, setResponseData] = useState({
    response: '',
    status: ''
  });
  const [responseDataStatus, setResponseDataStatus] = useState('');
  const [modalData, setModalData] = useState({
    response: '',
    status: ''
  });
  const [copied, setCopied] = useState({
    request: '',
    response: ''
  });
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [map1, setMap1] = useState();
  const [map2, setMap2] = useState();
  const [latestAvailableDate, setLatestAvailableDate] = useState();

  const [singleMapState, setSingleMapState] = useState({
    single: {
      childId: '',
      parentId: '',
      wms: '',
      fileName: '',
      productId: ''
    }
  });

  const [mapstate, setMapState] = useState({
    first: {
      childId: '',
      parentId: '',
      wms: '',
      fileName: '',
      productId: ''
    },
    second: {
      childId: '',
      parentId: '',
      wms: '',
      fileName: '',
      productId: ''
    }
  });

  /**
   * Get copied text & key values from a particular copy icon onClick
   * Also store and display the popup
   */

  const copyToClipboard = (text, key) => {
    navigator.clipboard.writeText(text);
    if (key === 'response') {
      setCopied({ ...copied, [key]: 'Copied' });
      setTimeout(() => {
        setCopied({ ...copied, [key]: '' });
      }, 3000);
    } else {
      setCopied({ ...copied, [key]: 'Copied' });
      setTimeout(() => {
        setCopied({ ...copied, [key]: '' });
      }, 3000);
    }
  };

  /**
   * When the component opens fetch product values
   * Also store setProductsOption and display the product select options
   */

  useEffect(() => {
    fetchProductData();
  }, []);

  const fetchProductData = async () => {
    const { data, status } = await getProducts();

    if (status === 200) {
      let arr = data.data.map((item) => {
        return {
          label: item.name,
          value: item.id,
          crop: item.is_crop_specific,
          // isDisabled: item.is_active,
          premium: item.is_premium,
          image: item.is_premium
        };
      });
      let products = data.data.map((item) => {
        return {
          treeTitleCode: item.code,
          title: item.name,
          productId: item.id
        };
      });
      storeUserDetails('data-products', JSON.stringify(products));

      setProductsOption([...arr]);
    }
  };

  const formatOptionLabel = ({ label, image }) => (
    <div className="custom-options-list">
      {label}
      {image && <img src={Premium} alt="product-premium" />}
    </div>
  );

  /**
   * When the component opens fetch the request data tree & response request API key
   * Also store setTreeData and display all available data
   * Also store setSelectedKey and display the API key select options
   */

  useEffect(() => {
    if (productsOption && productsOption.length !== 0) {
      getTreeData();
      getApiKeyList();
    }
  }, [productsOption]);

  const getTreeData = async () => {
    const { data, status } = await dataReuestTree();
    if (status === 200) {
      setTreeData(data?.data);
    }
  };

  const getApiKeyList = async () => {
    try {
      const { data, status } = await onGetApiKeyList();
      if (status === 200) {
        let options = data?.data.map((item) => {
          return {
            label: item.name,
            value: item.value
          };
        });
        setApiKeyOption(options);
        setSelectedKey(options[0]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * If selectedFilterOption of product & aoi is not equal to null means they call the available date API function
   * And then also store and display available products of date
   * So we highlighted the available date
   */

  useEffect(() => {
    if (
      selectedFilterOption?.products !== null &&
      selectedFilterOption?.aoi !== null &&
      selectedFilterOption?.aoi.length === 1
    ) {
      getHighlightDates();
    }
    // eslint-disable-next-line
  }, [selectedFilterOption?.products, selectedFilterOption?.aoi]);

  const getHighlightDates = async () => {
    let payload = {
      crop_id: selectedFilterOption?.crop?.value
        ? selectedFilterOption?.crop?.value
        : 0,
      aoi: {
        geometry: selectedFilterOption?.aoi[0]?.geometry
          ? selectedFilterOption?.aoi[0]?.geometry
          : ''
      }
    };
    const { data, status } = await getAvailableDates(
      selectedFilterOption?.products?.value,
      payload
    );

    if (status === 200) {
      setLatestAvailableDate(
        data?.data?.available_dates[data?.data?.available_dates.length - 1]
      );
      let highlightData = [
        {
          'react-datepicker__day--highlighted': data?.data?.available_dates.map(
            (item) => {
              return subDays(new Date(`${item}`), 0);
            }
          )
        },
        {
          'react-datepicker__day--highlighted-custom-1':
            data?.data?.future_dates.map((item) => {
              return addDays(new Date(`${item}`), 0);
            })
        }
      ];
      sethighlightData(highlightData);
    }
    if (error) {
      toast.error(error.response?.data.message);
    }
  };
  /**
   *
   */

  const includeDates = [];
  const selectedDays = [1, 11, 21];
  const pastYear = 20;
  const futureYear = 3;
  const currentDate = new Date();
  const startYear = currentDate.getFullYear() - pastYear;
  const endYear = currentDate.getFullYear() + futureYear;

  for (let year = startYear; year <= endYear; year++) {
    for (let month = 0; month < 12; month++) {
      selectedDays.forEach((day) => {
        includeDates.push(new Date(year, month, day));
      });
    }
  }

  const particularDateChange = (dates) => {
    setSelectedFilterOption({ ...selectedFilterOption, dateRange: dates });
  };

  /**
   * Once select the product if the product crop is true means we call the crop API functions
   * Are else we directly call product availability API functions
   * @param {string} e we get product details
   */
  const onChangeProduct = async (e) => {
    inputRef.current.value = null;
    // setFileName('Upload AOI File');
    setDateRange([null, null]);
    setParticularDateRange([null, null]);
    setAoi(aoi);
    sethighlightData('');
    setError(false);
    if (e.crop === true) {
      setSelectedFilterOption({
        ...selectedFilterOption,
        products: e,
        // aoi: null,
        crop: null,
        dateRange: []
      });
      const { data, status } = await getProductCrops(e.value);
      if (status === 200) {
        setCropOption(
          data?.data.map((item) => {
            return {
              label: item.crop_name,
              value: item.crop_id
            };
          })
        );
      }
    } else {
      setSelectedFilterOption({
        ...selectedFilterOption,
        products: e,
        crop: null,
        // aoi: null,
        dateRange: []
      });

      let payload = {
        crop_id: 0
      };
      const { data, status } = await getProductAvailability(e.value, payload);
      if (status === 200) {
        dataAvailabilityValue(data);
      }
      if (error) {
        toast.error(error?.response?.data.message);
        setUuidCompareData([]);
      }
    }
  };

  /**
   * Once you select crop value its successful after directly calling product availability API functions
   * @param {*} e We get crop details
   */
  const onChangeCrop = async (e) => {
    setSelectedFilterOption({ ...selectedFilterOption, crop: e });

    let payload = {
      crop_id: e.value
    };
    const { data, status, error } = await getProductAvailability(
      selectedFilterOption?.products?.value,
      payload
    );
    if (status === 200) {
      dataAvailabilityValue(data);
    }
    if (error) {
      toast.error(error?.response?.data.message);
      setUuidCompareData({});
    }
  };

  /**
   * We get availability data based on the product
   * Data availability has four different color
   * @param {string} data we get availability data
   */

  const dataAvailabilityValue = async (data) => {
    let uuidDataColour = {
      1: '#0FC831',
      2: '#A7A70B',
      3: '#FFFF5C',
      4: '#FF5454'
    };
    let uuidDataArray = Object.entries(data.data);
    let mergedUuidvalue = [];
    uuidDataArray.map((type) => {
      JSON.parse(JSON.stringify(MapJson)).features.map((item) => {
        if (item.properties.uuid === type[0]) {
          item.properties.COLOR = uuidDataColour[type[1]];
          mergedUuidvalue.push(item);
        }
        return null;
      });
      return null;
    });

    let selectedGrid = {
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: 'urn:ogc:def:crs:OGC:1.3:CRS84'
        }
      },
      features: mergedUuidvalue
    };

    setDataAvailability(true);
    setUuidCompareData(selectedGrid);
  };

  /**
   * Once upload the geojson or kml file after we are validating the geometry, it`s valid and then draw a polygon
   * Are else show error popup
   * @param {string} e getting file name & geometry data
   */

  const kmlParser = new DOMParser();
  const onChangeAoi = (e) => {
    sethighlightData('');
    const reader = new FileReader();
    setError(false);
    let file_name = e.target.files[0]?.name;
    let fileType = file_name.split('.')[1];

    reader.onload = async (e) => {
      const object = e.target.result;

      if (fileType === 'kml') {
        const kmlDocument = kmlParser.parseFromString(object, 'text/xml');
        const geoJSON = toGeoJSON.kml(kmlDocument);
        validationsKmlGeoJson(geoJSON, file_name, fileType);
      } else if (fileType === 'geojson') {
        let validJson = JSON.parse(object);
        validationsKmlGeoJson(validJson, file_name, fileType);
      } else {
        setFileName('Upload AOI File');
        setError(true);
        setSelectedFilterOption({
          ...selectedFilterOption,
          aoi: null
        });
      }
    };
    reader.readAsText(e.target.files[0]);
  };

  const validationsKmlGeoJson = (poygonData, file_name, fileType) => {
    let area = turf.area(poygonData);
    let areaSqKilo = Math.round(area / 1000000);
    var duplicatesValue = [];
    if (
      (gjv.isPolygon(poygonData?.features[0]?.geometry) ||
        gjv.isMultiPolygon(poygonData?.features[0]?.geometry)) &&
      poygonData?.features.length === 1
    ) {
      // if (areaSqKilo >= 121 && areaSqKilo <= 50000) {
      setFileName(file_name);
      onAvailableAoiProducts(poygonData);
      setSelectedFilterOption({
        ...selectedFilterOption,
        aoi: poygonData?.features
      });
      // } else {
      //   toast.error('AOI area must be between 121 sqkm to 50000 sqkm');
      //   setFileName('Upload AOI File');
      //   setError(true);
      //   setSelectedFilterOption({
      //     ...selectedFilterOption,
      //     aoi: null
      //   });
      // }
    } else if (
      (gjv.isPolygon(poygonData?.features[0]?.geometry) ||
        gjv.isMultiPolygon(poygonData?.features[0]?.geometry)) &&
      poygonData?.features.length > 1
    ) {
      // if (areaSqKilo >= 121 && areaSqKilo <= 50000) {
      poygonData?.features.map((feature) => {
        if (
          feature?.properties?.id !== '' &&
          feature?.properties?.id !== undefined
        ) {
          duplicatesValue.push(feature?.properties?.id);
          var array = duplicatesValue;
          var duplicates = findDuplicates(array);
          if (duplicates.length > 0) {
            toast.error("Column 'id' value should be unique");
            setFileName('Upload AOI File');
            setError(true);
            setSelectedFilterOption({
              ...selectedFilterOption,
              aoi: null
            });
          } else {
            if (fileType === 'kml') {
              if (
                Number(feature?.properties?.id) &&
                !Number.isInteger(feature?.properties?.id)
              ) {
                setFileName(file_name);
                onAvailableAoiProducts(poygonData);
                setSelectedFilterOption({
                  ...selectedFilterOption,
                  aoi: poygonData?.features
                });
              } else if (Number.isInteger(feature?.properties?.id)) {
                setFileName(file_name);
                onAvailableAoiProducts(poygonData);
                setSelectedFilterOption({
                  ...selectedFilterOption,
                  aoi: poygonData?.features
                });
              } else {
                toast.error('Column id must be an integer');
                setError(true);
                setFileName('Upload AOI File');
                setSelectedFilterOption({
                  ...selectedFilterOption,
                  aoi: null
                });
              }
            } else {
              if (Number.isInteger(feature?.properties?.id)) {
                setFileName(file_name);
                onAvailableAoiProducts(poygonData);
                setSelectedFilterOption({
                  ...selectedFilterOption,
                  aoi: poygonData?.features
                });
              } else {
                toast.error('Column id must be an integer');
                setError(true);
                setFileName('Upload AOI File');
                setSelectedFilterOption({
                  ...selectedFilterOption,
                  aoi: null
                });
              }
            }
          }
        } else {
          toast.error('Column id is required');
          setError(true);
          setFileName('Upload AOI File');
          setSelectedFilterOption({
            ...selectedFilterOption,
            aoi: null
          });
        }
        return null;
      });
      // } else {
      //   toast.error('AOI area must be between 121 sqkm to 50000 sqkm');
      //   setFileName('Upload AOI File');
      //   setError(true);
      //   setSelectedFilterOption({
      //     ...selectedFilterOption,
      //     aoi: null
      //   });
      // }
    } else {
      toast.error('Polyon Invalid');
      setFileName('Upload AOI File');
      setError(true);
      setSelectedFilterOption({
        ...selectedFilterOption,
        aoi: null
      });
    }
  };

  const findDuplicates = (array) => {
    var seenValues = {};
    var duplicates = [];
    for (var i = 0; i < array.length; i++) {
      var item = array[i];
      if (seenValues[item]) {
        if (duplicates.indexOf(item) === -1) {
          duplicates.push(item);
        }
      } else {
        seenValues[item] = true;
      }
    }

    return duplicates;
  };

  /**
   * setAoi of valid geo json
   * @param {string} geojson get valid geo-json
   */

  const onAvailableAoiProducts = (geojson) => {
    setAoi(geojson);
  };

  /**
   * change the date range picker
   * @param {string} e startdate & enddate
   */

  const onChangeDatePicker = (e) => {
    setSelectedFilterOption({ ...selectedFilterOption, dateRange: e });
  };

  /**
   *
   */

  const onVerifyRunRequest = async () => {
    setRunRequestLoaderStatus(true);
    if (
      (selectedFilterOption?.products?.crop &&
        selectedFilterOption?.crop &&
        selectedFilterOption?.aoi &&
        selectedFilterOption?.dateRange[0] &&
        selectedFilterOption?.dateRange[1]) ||
      (selectedFilterOption?.products?.crop === false &&
        selectedFilterOption?.aoi &&
        selectedFilterOption?.dateRange[0] &&
        selectedFilterOption?.dateRange[1])
    ) {
      selectedFilterOption?.aoi.map(async (aoiArray) => {
        let payload = {
          crop_id: selectedFilterOption?.crop
            ? selectedFilterOption?.crop?.value
            : 0,
          from_date: selectedFilterOption?.dateRange[0]
            ? moment(selectedFilterOption?.dateRange[0]).format('YYYY-MM-DD')
            : '',
          to_date: selectedFilterOption?.dateRange[1]
            ? moment(selectedFilterOption?.dateRange[1]).format('YYYY-MM-DD')
            : '',
          aoi: aoiArray?.geometry ? aoiArray?.geometry : '',
          file_name: fileName,
          feature_id:
            aoiArray?.properties?.id === undefined
              ? null
              : Number(aoiArray?.properties?.id)
        };
        const { data, status, error } = await getRunRequest(
          selectedFilterOption?.products?.value,
          payload
        );
        if (status) {
          toast.success(data?.message);
          setRunRequestLoaderStatus(false);
        }
        if (error) {
          toast.error(error.response?.data?.message);
        }
      });
      setSelectedFilterOption({
        products: null,
        aoi: null,
        crop: null,
        dateRange: []
      });
      setDateRange([null, null]);
      setParticularDateRange([null, null]);
      setFileName('Upload AOI File');
      setAoi(null);
      getTreeData();
      setUuidCompareData([]);
    }
  };

  /**
   * Run Request button check the details are available or not
   * @returns true or false
   */

  const getDisabled = () => {
    if (
      (selectedFilterOption?.products?.crop &&
        selectedFilterOption?.crop &&
        selectedFilterOption?.aoi &&
        selectedFilterOption?.dateRange[0] &&
        selectedFilterOption?.dateRange[1]) ||
      (selectedFilterOption?.products?.crop === false &&
        selectedFilterOption?.aoi &&
        selectedFilterOption?.dateRange[0] &&
        selectedFilterOption?.dateRange[1])
    ) {
      return false;
    } else {
      return true;
    }
  };

  /**
   *
   * @param {*} data
   * @returns
   */

  const addApiKey = (data) => {
    let datas = JSON.parse(JSON.stringify(data));
    if (
      datas &&
      datas.length !== 0 &&
      datas.features &&
      datas.features.length !== 0
    ) {
      return data.features.map((item) => {
        item.links[0].href = `${item.links[0].href}&api_key=${selectedkey.value}`;
        item.links[1].href = `${item.links[1].href}&api_key=${selectedkey.value}`;
        return item;
      });
    }
    return data;
  };

  const compareCoordinates = (coordinates1, coordinates2) => {
    const isEqual = turf.booleanEqual(coordinates1, coordinates2);
    setIsOpen(true);
    if (isEqual) {
      setLayerAoiStatus(true);
    } else {
      setLayerAoiStatus(false);
    }
  };

  useEffect(() => {
    if (map1 && map2) {
      map1.getView().on('propertychange', function (event) {
        switch (event.key) {
          case 'resolution':
            map2.getView().setCenter(event.target.values_.center);
            map2.getView().setResolution(event.target.values_.resolution);
            break;
          case 'center':
            map2.getView().setCenter(event.target.values_.center);
            map2.getView().setResolution(event.target.values_.resolution);
            break;
          default:
            break;
        }
      });
      // map2.getView().on('propertychange', function (event) {
      //   switch (event.key) {
      //     case 'resolution':
      //       map1.getView().setCenter(event.target.values_.center);
      //       map1.getView().setResolution(event.target.values_.resolution);
      //       break;
      //     case 'center':
      //       map1.getView().setCenter(event.target.values_.center);
      //       map1.getView().setResolution(event.target.values_.resolution);
      //       break;
      //   }
      // });
    }
  }, [map1, map2]);

  return (
    <>
      <div className="dashboard-wrapper">
        <div className="data-request-filter">
          <div className="filter-form-group margin-bottom">
            <label className="product-filter-title">
              Product<sup>*</sup>
              <div className="info-icon">
                <img src={Info} alt="Info" />
              </div>
              <div className="info-popup">
                <p className="info-content">
                  Select data product from the drop down menu. Check more about
                  use cases in documentation.
                </p>
                <span className="triangle-top"></span>
              </div>
            </label>
            <span className="product-filter">
              <Select
                classNamePrefix="react-select"
                placeholder="Select Data Product"
                options={productsOption}
                formatOptionLabel={formatOptionLabel}
                value={selectedFilterOption?.products}
                // isOptionDisabled={(option) => !option.isDisabled}
                onChange={(e) => {
                  onChangeProduct(e);
                }}
              />
            </span>
          </div>

          {selectedFilterOption?.products?.crop ? (
            <div className="crop filter-form-group margin-bottom">
              <label className="product-filter-title">
                Crop<sup>*</sup>
              </label>
              <span className="product-filter">
                <Select
                  classNamePrefix="react-select"
                  placeholder="Select Crop"
                  value={selectedFilterOption.crop}
                  onChange={(e) => onChangeCrop(e)}
                  options={cropOption}
                />
              </span>
            </div>
          ) : null}

          <div className="filter-form-group">
            <div className="aoi-title">
              <label className="product-filter-title">
                AOI<sup>*</sup>
                <div className="info-icon">
                  <img src={Info} alt="Info" />
                </div>
                <div className="aoi info-popup">
                  <p className="info-content">
                    Area of Interest (AOI) :Area of interest provided is a
                    representation of the geolocated polygon/ bounding box for
                    data delivery.
                  </p>
                  <span className="triangle-top"></span>
                </div>
              </label>
              <a
                className="sample-json"
                href={SampleGeoJson}
                download="sample_geojson.geojson">
                Download Sample AOI
              </a>
            </div>
            <label
              htmlFor="file-upload"
              className={`custom-file-upload
                ${
                  error
                    ? 'error'
                    : fileName === 'Upload AOI File'
                    ? 'custom-file-upload-placeholder'
                    : ''
                }`}>
              <p className={'custom-file-upload-text'}>{fileName}</p>
              <input
                id="file-upload"
                type="file"
                ref={(ref) => (inputRef.current = ref)}
                accept=".geojson,.kml"
                onChange={(e) => {
                  onChangeAoi(e);
                }}
              />
              <img src={Upload} alt="upload"></img>
            </label>
            <p className="aoi-format">Supported format : .geojson, .kml</p>
            <label className={error ? 'aoi-error visible' : 'aoi-error'}>
              Upload AOI in correct format.
            </label>
          </div>

          <div className="filter-form-group">
            <label className="product-filter-title">
              Date Range<sup>*</sup>
            </label>
            <span>
              {selectedFilterOption?.products?.value === 4 ||
              selectedFilterOption?.products?.value === 5 ? (
                <DatePicker
                  className={
                    particularStartDate === null && particularEndDate === null
                      ? 'daterange-picker'
                      : ''
                  }
                  selectsRange={true}
                  startDate={particularStartDate}
                  endDate={particularEndDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select Date Range"
                  onChange={(update) => {
                    setParticularDateRange(update);
                    particularDateChange(update);
                  }}
                  monthsShown={2}
                  highlightDates={highlightData}
                  includeDates={includeDates}
                  isClearable={true}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              ) : (
                <DatePicker
                  className={dateRange[0] == null ? 'daterange-picker' : ''}
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select Date Range"
                  onChange={(update) => {
                    setDateRange(update);
                    onChangeDatePicker(update);
                  }}
                  monthsShown={2}
                  highlightDates={highlightData}
                  isClearable={true}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              )}
            </span>
            <p
              className={`latest-available ${
                latestAvailableDate ? 'date-show' : 'date-hide'
              }`}>
              Latest data is available for{' '}
              {moment(latestAvailableDate).format('DD/MM/YYYY')}.
            </p>
          </div>

          <div className="run-request-btn">
            <button
              type="button"
              className="btn-register run-request"
              disabled={getDisabled()}
              onClick={() => onVerifyRunRequest()}>
              Run Request
              {runRequestLoaderStatus ? (
                <Loader loader="common-btn-loader"></Loader>
              ) : null}
            </button>
          </div>
        </div>

        <div className="data-map-wrapper">
          <RequestComparisonSection
            requestTreeData={treeData}
            setTreeParentIdValue={setTreeParentIdValue}
            setTreeIdValue={setTreeIdValue}
            setCompareMap={setCompareMap}
            setMapCompare={setMapCompare}
            setResponseData={setResponseData}
            setShow={setShow}
            show={show}
            mapstate={mapstate}
            setMapState={setMapState}
            singleMapState={singleMapState}
            setResponseDataStatus={setResponseDataStatus}
            setSingleMapState={setSingleMapState}
            ismergedMap={mapCompare}
            selectedCheckboxes={selectedCheckboxes}
            setSelectedCheckboxes={setSelectedCheckboxes}
            setLegentType={setLegentType}
          />

          {compareMap ? (
            <div className="component-wrapper">
              <div div className="data-map-container">
                <div className="data-res-met-sections">
                  <div className="expand-collapse-section">
                    <div
                      className={
                        show.request
                          ? 'request-wrapper'
                          : 'request-wrapper collapsed'
                      }>
                      <div className="request-head-item">
                        <label
                          className={
                            show.request
                              ? 'arrow-wrapper rotate'
                              : 'arrow-wrapper'
                          }
                          onClick={() =>
                            setShow({ ...show, request: !show.request })
                          }>
                          <img src={UpArrow} alt="up-arrow" />
                        </label>
                        <h1>Request</h1>
                        {responseDataStatus && (
                          <img
                            src={getStatusIcon(responseDataStatus).img}
                            alt="tick icon"
                          />
                        )}
                      </div>
                      <div className="request-head-item second">
                        <Select
                          classNamePrefix="key-select"
                          placeholder="Select Api key"
                          options={apiKeyOption}
                          value={selectedkey}
                          onChange={(e) => setSelectedKey(e)}
                        />

                        {/* <div>
                          <h3>Python</h3>
                         <Select
                              classNamePrefix="key-select"
                              placeholder="Python"
                              options={pythonOptions}
                            />
                         </div> */}
                        <div>
                          <div className="text-tooltip">
                            {treeIdValue && (
                              <span
                                className={`copy ${
                                  copied.request ? 'copied' : ''
                                }`}
                                onClick={() =>
                                  copyToClipboard(
                                    getApiRequest(
                                      selectedkey?.value,
                                      treeIdValue
                                    ),
                                    'request'
                                  )
                                }>
                                {copied.request ? 'Copied' : 'Copy'}
                              </span>
                            )}
                            {/* {copied.request && (
                            <p className="copy-tooltip">{copied.request}</p>
                          )} */}
                          </div>
                        </div>
                        <div
                          className="expand-content-wrapper"
                          onClick={() => {
                            setShowModal(true);
                            setModalType('request');
                          }}>
                          <img src={ExpandContent} alt="expand-content" />
                        </div>
                      </div>
                    </div>

                    <div
                      className={
                        show.request
                          ? 'request-wrapper-body margin-bottom'
                          : 'request-wrapper-body collapse'
                      }>
                      <p className="body-data request-body">
                        {treeIdValue !== '' ? (
                          getApiRequest(selectedkey?.value, treeIdValue)
                        ) : (
                          <h1 className="no-data">No Data Available</h1>
                        )}
                      </p>
                    </div>

                    <div
                      className={
                        show.response
                          ? 'request-wrapper'
                          : 'request-wrapper collapsed'
                      }>
                      <div className="request-head-item">
                        <label
                          className={
                            show.response
                              ? 'arrow-wrapper rotate'
                              : 'arrow-wrapper'
                          }
                          onClick={() =>
                            setShow({ ...show, response: !show.response })
                          }>
                          <img src={UpArrow} alt="up-arrow" />
                        </label>
                        <h1>Response</h1>
                        <span>
                          {responseDataStatus && (
                            <img
                              src={getStatusIcon(responseDataStatus).img}
                              alt="tick icon"
                            />
                          )}
                        </span>
                      </div>
                      <div className="request-head-item second">
                        <div className="text-tooltip">
                          {responseData?.response &&
                          responseData?.response?.length !== 0 ? (
                            <span
                              className={`copy ${
                                copied.response ? 'copied' : ''
                              }`}
                              onClick={() =>
                                copyToClipboard(
                                  JSON.stringify(responseData.response),
                                  'response'
                                )
                              }>
                              {copied.response ? 'Copied' : 'Copy'}
                            </span>
                          ) : null}
                        </div>
                        <div
                          className="expand-content-wrapper"
                          onClick={() => {
                            setShowModal(true);
                            var value = addApiKey(
                              JSON.parse(JSON.stringify(responseData.response))
                            );

                            setModalData({
                              response: value,
                              status: responseData.status
                            });
                            setModalType('response');
                          }}>
                          <img src={ExpandContent} alt="expand-content" />
                        </div>
                      </div>
                    </div>

                    <div
                      className={
                        show.response
                          ? 'request-wrapper-body'
                          : 'request-wrapper-body collapse'
                      }>
                      <pre className="body-data">
                        {responseData?.response &&
                        responseData?.response?.length !== 0 ? (
                          JSON.stringify(
                            addApiKey(
                              JSON.parse(JSON.stringify(responseData.response))
                            ),
                            undefined,
                            2
                          )
                        ) : (
                          <h1 className="no-data">No Data Available</h1>
                        )}
                      </pre>
                    </div>

                    {/* <div
                        className={
                          show.metaData
                            ? 'request-wrapper'
                            : 'request-wrapper collapsed'
                        }>
                        <div className="request-head-item">
                          <label
                            className={
                              show.metaData
                                ? 'arrow-wrapper rotate'
                                : 'arrow-wrapper'
                            }
                            onClick={() =>
                              setShow({ ...show, metaData: !show.metaData })
                            }>
                            <img src={UpArrow} alt="up-arrow" />
                          </label>
                          <h1>MetaData</h1>
                          <span>
                            <img src={Tick} alt="tick icon" />
                          </span>
                        </div>
                        <div className="request-head-item second">
                          <span className="copy">Copy</span>
                          <div
                            className="expand-content-wrapper"
                            onClick={() => setShowModal(true)}>
                            <img src={ExpandContent} alt="expand-content" />
                          </div>
                        </div>
                      </div> */}
                    <div
                      className={
                        show.metaData
                          ? 'request-wrapper-body'
                          : 'request-wrapper-body collapse'
                      }>
                      <p className="body-data"></p>
                    </div>
                  </div>
                </div>

                <div className="map-sections">
                  <MapLayer
                    treeParentIdValue={treeParentIdValue}
                    gridValue={MapJson}
                    dataAvailability={dataAvailability}
                    uuidCompareData={uuidCompareData}
                    aoi={aoi}
                    selectedApiKey={selectedkey?.value}
                    selectedFilterOption={selectedFilterOption}
                    wmsLayerSingle={singleMapState.single}
                    mapType="single"
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              {mapCompare ? (
                <div className="component-wrapper">
                  <div className="component-sections">
                    <div className="data-map-container compare-map">
                      <div className="map-sections">
                        <MapLayer
                          selectedApiKey={selectedkey?.value}
                          treeParentIdValue={legendType.left}
                          wmsLayerFirst={mapstate.first}
                          mapType="first"
                          setMap1={setMap1}
                        />
                      </div>
                      <div className="map-sections">
                        <MapLayer
                          selectedApiKey={selectedkey?.value}
                          treeParentIdValue={legendType.right}
                          wmsLayerSecond={mapstate.second}
                          mapType="second"
                          setMap2={setMap2}
                        />
                      </div>
                    </div>
                    <button
                      className="merge-map"
                      disabled={
                        mapstate?.first?.parentId === '' ||
                        mapstate?.second?.parentId === ''
                      }
                      onClick={() => {
                        compareCoordinates(
                          mapstate?.first?.wms?.geometry,
                          mapstate?.second?.wms?.geometry
                        );
                      }}>
                      <img src={Merge} alt="merge" />
                    </button>
                  </div>

                  <Modal
                    isOpen={modalIsOpen}
                    style={customStyles}
                    contentLabel="Example Modal">
                    <div className="common-modal-container">
                      {mapstate?.first.childId !== '' &&
                      mapstate?.second.childId !== '' &&
                      layerAoiStatus ? (
                        <>
                          <div>
                            <img src={MergeBlack} alt="Merge" />
                          </div>
                          <div className="merge-content">
                            <p>Do you want to merge maps </p>
                            <p>for better analysis for the same AOI ? </p>
                          </div>
                          <div className="modal-btn merge-btn">
                            <button
                              className="yes-btn"
                              onClick={() => {
                                setIsOpen(false);
                                setMergeMapCompare(true);
                                setMapCompare((prev) => !prev);
                              }}>
                              Yes
                            </button>
                            <button
                              className="skip-btn"
                              onClick={() => setIsOpen(false)}>
                              Skip
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <img src={MergerError} alt="Merge" />
                          </div>
                          <div className="merge-content">
                            <p>
                              Please select two requests with the same <br />
                              AOI to merge maps.
                            </p>
                          </div>
                          <div className="modal-btn merge-btn">
                            <button
                              className="yes-btn"
                              onClick={() => setIsOpen(false)}>
                              Okay
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </Modal>
                </div>
              ) : mergeMapCompare ? (
                <div className="component-wrapper merge-map-layer">
                  <MapCompare
                    mergeMapLayer={mapstate}
                    treeParentIdValue={legendType}
                  />
                  <div
                    className="merge-slpit"
                    onClick={() => {
                      setSingleMapCompare(true);
                      setMergeMapCompare(false);
                    }}>
                    <img src={MergeSplitOverlay} alt="merge split" />
                  </div>
                </div>
              ) : (
                singleMapCompare && (
                  <div className="component-wrapper single-map-sections">
                    <MapLayer
                      selectedApiKey={selectedkey?.value}
                      treeParentIdValueSingle={legendType}
                      wmsSinlgeMergeLayer={mapstate}
                      mapType="singleMergeMap"
                    />
                    <div
                      className="merge-slpit"
                      onClick={() => {
                        setMapCompare(true);
                      }}>
                      <img src={MergeSplit} alt="merge split" />
                    </div>
                  </div>
                )
              )}
            </>
          )}
        </div>
      </div>

      <PopupModal
        modalType={modalType}
        selectedApiKey={selectedkey}
        setSelectedKey={setSelectedKey}
        apiKeyOption={apiKeyOption}
        showModal={showModal}
        setShowModal={setShowModal}
        modalData={modalData}
        selectedkey={selectedkey}
        treeIdValue={treeIdValue}
      />
    </>
  );
};

export default Dashboard;
