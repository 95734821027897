import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import SDSControl from 'ol-side-by-side';
import Map from 'ol/Map';
import GeoJSON from 'ol/format/GeoJSON.js';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';
import XYZ from 'ol/source/XYZ';
import View from 'ol/View';
import { Group as LayerGroup } from 'ol/layer.js';
import { Stroke, Style } from 'ol/style.js';
import { transformExtent } from 'ol/proj';
import * as turf from '@turf/turf';
import 'ol/ol.css';
import './index.css';
import {
  FullScreen,
  //   ScaleLine,
  defaults as defaultControls
} from 'ol/control.js';
import MapControls from '../../components/map-controls';
import { getProductListName } from '../../utils/user-helper';
import { BASE_URL_GEO_SERVER } from '../../utils/url-helper';
// Images
import CROP_INTENSITY from '../../assets/images/legends/map-legends/Crop_Intensity.svg';
import IRRIGATION_CONDITION from '../../assets/images/legends/map-legends/Irrigation_Condition.svg';
import CROP_SOWN_AREA from '../../assets/images/legends/map-legends/Crop_sown_area.svg';
import SINGLE_DATE_NDVI from '../../assets/images/legends/map-legends/S.D.S_NDVI.svg';
import SINGLE_DATE_NDWI from '../../assets/images/legends/map-legends/S.D.S_NDWI.svg';
import CYGNUS_NDVI from '../../assets/images/legends/map-legends/Cygnus_NDVI.svg';
import CYGNUS_NDWI from '../../assets/images/legends/map-legends/Cygnus_NDWI.svg';
import TEMP_COMPOSITE_NDVI from '../../assets/images/legends/map-legends/Temporal_composite_NDVI.svg';
import TEMP_COMPOSITE_NDWI from '../../assets/images/legends/map-legends/Temporal_composite_NDWI.svg';

const MapCompare = ({ mergeMapLayer, treeParentIdValue }) => {
  const [mapObject, setMap] = useState();
  const [mergeAreaAoi, setMergeAreaAoi] = useState(0);
  const [mapExtent, setMapExtent] = useState();
  const mapElement = useRef(null);
  const mapRef = useRef(null);
  mapRef.current = mapObject;

  useEffect(() => {
    if (
      mergeMapLayer.first &&
      mergeMapLayer.first?.wms !== '' &&
      mergeMapLayer.second &&
      mergeMapLayer.second?.wms !== ''
    ) {
      mapLayerMerge(mergeMapLayer);
      getArea(mergeMapLayer.first?.wms);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergeMapLayer]);

  const mapLayerMerge = (mergeMapLayer) => {
    let mergeFirstWmsLayer = mergeMapLayer.first;
    let mergeLinkFirst = mergeFirstWmsLayer?.wms?.links.filter(
      (type) => type.rel === 'wms_url'
    );

    const wmsLayer1 = new LayerGroup({
      layers: [
        new TileLayer({
          source: new TileWMS({
            url: `${BASE_URL_GEO_SERVER}?${mergeLinkFirst[0]?.href}`,
            serverType: undefined,
            transition: 0
          })
        })
      ]
    });

    const singleTransform = transformExtent(
      mergeFirstWmsLayer?.wms?.bbox,
      'EPSG:4326',
      'EPSG:3857'
    );

    let mergeSecondWmsLayer = mergeMapLayer.second;
    let mergeLinkSecond = mergeSecondWmsLayer?.wms?.links.filter(
      (type) => type.rel === 'wms_url'
    );

    const wmsLayer2 = new TileLayer({
      source: new TileWMS({
        url: `${BASE_URL_GEO_SERVER}?${mergeLinkSecond[0]?.href}`,
        serverType: undefined,
        transition: 0
      })
    });

    const source1 = new VectorSource({
      features: new GeoJSON({
        defaultDataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857'
      }).readFeatures(mergeSecondWmsLayer?.wms)
    });
    let sds = new SDSControl();
    let map = new Map({
      controls: defaultControls().extend([
        new FullScreen()
        // new ScaleLine()
      ]),
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}'
          })
        }),
        new VectorLayer({
          source: source1,
          style: new Style({
            stroke: new Stroke({
              color: 'rgba(255, 255, 255, 0.7)',
              width: 5,
              zIndex: 999
            })
          })
        })
      ],
      target: mapElement.current,
      view: new View({
        center: [0, 0],
        zoom: 0
      })
      // controls: [sds]
    });
    setMapExtent(singleTransform);
    map.addControl(sds);
    map
      .getView()
      .fit(
        singleTransform,
        { duration: 2000, padding: [50, 50, 50, 50] },
        map.getSize()
      );
    setTimeout(() => {
      compareMap(wmsLayer1, wmsLayer2, map, sds);
    }, 2000);
  };

  const compareMap = (wmsLayer1, wmsLayer2, map, sds) => {
    setMap(map);
    sds.open();
    sds.setLeftLayer(wmsLayer1);
    sds.setRightLayer(wmsLayer2);
  };

  // Area Calculations
  const getArea = (geojson) => {
    let area = turf.area(geojson);
    if (area > 1000000) {
      let output = Math.round(area / 1000000);
      setMergeAreaAoi(output + ' ' + 'sq km');
    } else {
      let output = Math.round(area);
      setMergeAreaAoi(output + ' ' + 'sq m');
    }
  };

  const flyToGeometry = () => {
    mapObject
      .getView()
      .fit(mapExtent, { duration: 3000, padding: [50, 50, 50, 50] });
  };

  return (
    <>
      <div className="merge-container">
        <div className="single-merge-title">
          <div className="legend-title-header">
            <h2>
              Request Name :
              {mergeMapLayer?.first?.wms?.properties?.datetime &&
                ' ' +
                moment(
                  mergeMapLayer?.first?.wms?.properties?.datetime.split('T')[0]
                ).format('DD-MM-YYYY') + '_' + mergeMapLayer?.first?.fileName +
                '_' + getProductListName(mergeMapLayer?.first?.productId)?.treeTitleCode}
            </h2>
          </div>
          <div className="legend-title-header">
            <h2>
              Request Name :
              {mergeMapLayer?.second?.wms?.properties?.datetime &&
                ' ' +
                moment(
                  mergeMapLayer?.second?.wms?.properties?.datetime.split('T')[0]
                ).format('DD-MM-YYYY') + '_' + mergeMapLayer?.second?.fileName +
                '_' + getProductListName(mergeMapLayer?.second?.productId)?.treeTitleCode}
            </h2>
          </div>
        </div>
        <div className="merge-map-component">
          <div ref={mapElement} id="map" className="map-compare">
            <MapControls
              map={mapObject}
              compareLayerName={true}
              flyToGeometry={flyToGeometry}
            />
            <div className="merge-croparea-aoi">
              {mergeAreaAoi ? (
                <div className="aoi-sqm">
                  <label>Area: </label>
                  <span> ({mergeAreaAoi} )</span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="single-merge-legend">
          <div className="map-descriptions merge-map-title">
            {treeParentIdValue.left === 3 && (
              <div className="product-legend-img">
                <img src={CROP_SOWN_AREA} alt="Crop Sowing" />
              </div>
            )}
            {treeParentIdValue.left === 4 && (
              <div className="product-legend-img">
                <img src={TEMP_COMPOSITE_NDVI} alt="Temporal Composite NDVI" />
              </div>
            )}
            {treeParentIdValue.left === 5 && (
              <div className="product-legend-img">
                <img src={TEMP_COMPOSITE_NDWI} alt="Temporal Composite NDWI" />
              </div>
            )}
            {treeParentIdValue.left === 6 && (
              <div className="product-legend-img">
                <img src={CYGNUS_NDVI} alt="CYGNUS NDVI" />
              </div>
            )}
            {treeParentIdValue.left === 7 && (
              <div className="product-legend-img">
                <img src={CYGNUS_NDWI} alt="CYGNUS NDWI" />
              </div>
            )}
            {treeParentIdValue.left === 8 && (
              <div className="product-legend-img">
                <img src={SINGLE_DATE_NDVI} alt="Single Date Stack NDVI" />
              </div>
            )}
            {treeParentIdValue.left === 9 && (
              <div className="product-legend-img">
                <img src={SINGLE_DATE_NDWI} alt="Single Date Stack NDWI" />
              </div>
            )}
            {treeParentIdValue.left === 10 && (
              <div className="product-legend-img">
                <img src={CROP_INTENSITY} alt="Crop Intensity" />
              </div>
            )}
            {treeParentIdValue.left === 11 && (
              <div className="product-legend-img">
                <img src={IRRIGATION_CONDITION} alt="Irrigation Condition" />
              </div>
            )}

            {treeParentIdValue.right === 3 && (
              <div className="product-legend-img">
                <img src={CROP_SOWN_AREA} alt="Crop Sown Area" />
              </div>
            )}
            {treeParentIdValue.right === 4 && (
              <div className="product-legend-img">
                <img src={TEMP_COMPOSITE_NDVI} alt="Temporal Composite NDVI" />
              </div>
            )}
            {treeParentIdValue.right === 5 && (
              <div className="product-legend-img">
                <img src={TEMP_COMPOSITE_NDWI} alt="Temporal Composite NDWI" />
              </div>
            )}
            {treeParentIdValue.right === 6 && (
              <div className="product-legend-img">
                <img src={CYGNUS_NDVI} alt="CYGNUS NDVI" />
              </div>
            )}
            {treeParentIdValue.right === 7 && (
              <div className="product-legend-img">
                <img src={CYGNUS_NDWI} alt="CYGNUS NDWI" />
              </div>
            )}
            {treeParentIdValue.right === 8 && (
              <div className="product-legend-img">
                <img src={SINGLE_DATE_NDVI} alt="Single Date Stack NDVI" />
              </div>
            )}
            {treeParentIdValue.right === 9 && (
              <div className="product-legend-img">
                <img src={SINGLE_DATE_NDWI} alt="Single Date Stack NDWI" />
              </div>
            )}
            {treeParentIdValue.right === 10 && (
              <div className="product-legend-img">
                <img src={CROP_INTENSITY} alt="Crop Intensity" />
              </div>
            )}
            {treeParentIdValue.right === 11 && (
              <div className="product-legend-img">
                <img src={IRRIGATION_CONDITION} alt="Irrigation Condition" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MapCompare;
