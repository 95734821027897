import React from 'react';

import './index.css';

const Loader = ({ loader }) => {
  return (
    <div className={loader}>
      <div className="loader"></div>
    </div>
  );
};
export default Loader;
