import React from 'react';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './privateroute';
import Setting from '../containers/setting';
import Login from '../containers/login/login';
import Signup from '../containers/signup/signup';
import Dashboard from '../containers/dashboard/dashboard';
import Documentation from '../containers/documentation/documentation';
import EmailVerification from '../components/emailverifcation/emailverification';
import EmailVerificationSuccess from '../components/emailverificationsucess/emailVerificationSuccess';
import Requests from '../containers/requests/requests';
import Support from '../containers/support/support';
import ForgotPassword from '../containers/forgot-password/forgot-password';
import ResetPassword from '../containers/reset-password/reset-password';

const Routers = () => {
  return (
    <Routes>
      <Route path="/register" element={<Signup />} />
      <Route path="/" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route path="/documentation/:url"
        element={
          <PrivateRoute>
            <Documentation />
          </PrivateRoute>
        }>
      </Route>

      <Route path="/documentation"
        element={
          <PrivateRoute>
            <Documentation />
          </PrivateRoute>
        }>
      </Route>

      <Route path="/support"
        element={
          <PrivateRoute>
            <Support />
          </PrivateRoute>
        }>
      </Route>
      <Route path="/all-requests"
        element={
          <PrivateRoute>
            <Requests />
          </PrivateRoute>
        }>
      </Route>
      <Route path="/email-verification" element={<EmailVerification />}></Route>
      <Route path="/settings"
        element={
          <PrivateRoute>
            <Setting />
          </PrivateRoute>
        }>
      </Route>
      <Route path="/email-verification-success" element={<EmailVerificationSuccess />}></Route>
    </Routes>
  );
};

export default Routers;
