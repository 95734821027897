import { getRequest } from '../utils/http-helper';
import { COUNTRY_DROPDOWN_URL, SOLUTION_DROPDOWN_URL } from '../utils/url-helper';

export const getCountry = async () => {
  return await getRequest({
    url: COUNTRY_DROPDOWN_URL,
    headers: true,
    Auth: true
  });
};

export const getSolution = async () => {
  return await getRequest({
    url: SOLUTION_DROPDOWN_URL,
    headers: true,
    Auth: true
  });
};
