export const ApiData = [
  {
    id: '#NDVI',
    title: 'NDVI',
    sidebarDetails: [
      'Introduction',
      'Advantages',
      'Use cases'
    ],
    intro:
      'NDVI is calculated from the Visible (Red) and Near-Infrared light reflected by vegetation. Healthy vegetation absorbs most of the Visible Light that hits it, and reflects a large portion of the Near-Infrared light. Unhealthy or sparse vegetation reflects more visible light and less Near-Infrared light. Nearly all satellite Vegetation Indices employ this difference formula to quantify the density of plant growth on the Earth Near-Infrared Radiation (NIR) minus a part of Visible radiation (RED) divided by Near-Infrared radiation (NIR) plus a part of visible radiation (RED). The result of this formula is called the Normalized Difference Vegetation Index (NDVI).',
    method: 'The NDVI is mathematically written as,',
    formula: 'NDVI = (NIR - RED) / (NIR + RED)',
    formula_desc:
      'Calculations of NDVI for a given pixel always result in a number that ranges from minus one (-1) to plus one (+1). No green leaves give a value close to zero. A zero means no vegetation, and close to +1 (0.8 - 0.9) indicates the highest possible density of green leaves',
    content: 'NDVI stands for Normalized Difference Vegetation Index.',
    request:
      'In this study the NDVI values are scaled between 0 and 200 using the following equation:',
    data: 'Scaled NDVI = (NDVI x 100) + 100',
    data2: 'where the NDVI values range between -1 to +1',
    fig_4: 'Fig: Legend for Scaled NDVI',
    ndvi_legend: require('../images/documents/NDVI.png'),
    use_case:
      'The availability of a time series of NDVI contributes significantly to the creation of analytics models that can improve the following:',
    adv_case:
      'NDVI makes it a valuable tool for monitoring and managing vegetation, and for understanding the complex interactions between vegetation and the environment. Some of the key advantages are:',
    useCasePoints: [
      'Vegetation health monitoring',
      'LULC change monitoring',
      'Crop classification',
      'Yield prediction',
      'Biomass estimation',
      'Forest fire monitoring'
    ],
    advantagePoints: [
      'NDVI values can effectively distinguish between vegetation and non-vegetation areas, and can also provide information about the density and health of vegetation cover.',
      'NDVI is a simple index that is easy to calculate using standard remote sensing software. ',
      'NDVI can be applied at different spatial and temporal scales, from local to regional and global scales.',
      'NDVI values are strongly correlated with several vegetation parameters, such as leaf area index, biomass, and chlorophyll content.'
    ],
    queryparams: '',
    child: []
  },

  {
    id: '#NDWI',
    title: 'NDWI',
    // sidebarDetails: [
    //   'Introduction',
    //   'Advantages',
    //   'Use cases'
    // ],   
     intro:
      'Normalized Difference Water Index (NDWI) is used to refer to either one of two remote sensing derived indexes related to liquid water. In this analysis, the NDWI is used in the context of monitoring water content of leaves, using NIR and Short-wave Infrared (SWIR) wavelengths.',
    intro_1: "The SWIR reflectance is a proxy for changes in both vegetation water content and the spongy mesophyll structure in vegetation canopies.The NIR reflectance is however, affected by leaf internal structure and leaf dry matter content.The combination of the NIR with the SWIR removes variations induced by leaf internal structure and leaf dry matter content.",
    method: 'Mathematically NDWI is represented as,',
    formula: 'NDWI = (NIR - SWIR) / (NIR + SWIR)',
    formula_desc:
      'Calculations of NDWI for a given pixel always result in a number that ranges from minus one (-1) to plus one (+1). Values from -1 to 0 indicate a bright surface with no vegetation or water content, while closer to +1 indicates presence of water. ',
    content: 'NDWI stands for Normalized Difference Water Index.',
    request:
      'In this study the NDWI values are scaled between 0 and 200 using the following equation:',
    data: 'Scaled NDWI = (NDWI x 100) + 100',
    data2: 'where the NDWI values range between -1 to +1',
    ndwi_legend: require('../images/documents/NDWI.png'),
    fig_5: 'Fig: Legend for Scaled NDWI',
    use_case:
      'The availability of a time series of Water Indices like NDWI contributes significantly to the creation of bespoke analytics models that can improve the following:',
    adv_case:
      'NDWI makes it a valuable tool for monitoring and managing water resources, and for understanding the complex interactions between water and the environment.',
    useCasePoints: [
      'Irrigation management',
      'Flood detection',
      'Drought monitoring',
      'Land cover mapping',
      'Crop health monitoring',
      'Soil moisture mapping'
    ],
    advantagePoints: [
      'Advantage of NDWI is its sensitivity to water content in vegetation and soil',
      'NDWI values are strongly correlated with several water parameters, such as water content and surface area',
      'NDWI is a simple index that makes it accessible to a wide range of users, including researchers, water managers, and farmers.'
    ],
    queryparams: '',
    child: []
  },
  {
    id: '#Crop-Sown-Area',
    title: 'Crop Sown Area',
    // sidebarDetails: [
    //   'Introduction',
    //   'Advantages',
    //   'Use cases'
    // ],   
     intro:
      'Crop Sowing means the process of planting and the area of sowing is termed as crop sown area. Crop sown area gives us the initial forecast of the crop productivity in a desired area.',
    intro_1: "Crop sown area estimation/sowing progression is a crop-agnostic data product derived from satellite images. The product is derived by sensing the behavior of plants on the ground towards various electromagnetic radiations. The major parameter measured and analyzed  is the photosynthetic activity of the crop.",
    request:
      'In this study the NDWI values are scaled between 0 and 200 using the following equation:',
    image_link_1: require('../images/documents/Phenology-CropSownArea.png'),
    fig_1: 'Fig: Crop Phenology used to detect Crop Sown Area',
    image_desc_1:
      'Crop Sown Area uses satellite images for a specific date range (i.e. starting of kharif or rabi season as the cropping seasons are named in some parts of India) to extract the land area where sowing has taken place. ',
    image_desc_2:
      'The Remote Sensing methodology for estimating the crop sowing is shown here:',
    crop_sown_area_img: require('../images/documents/crop-sown-area-method.png'),
    fig_2: 'Fig: Crop Sown Area Methodology',
    crop_sown_area_desc:
      'Though the actual sowing has happened on the ground, days before. The crop sown area can be derived only when the plant starts giving the signatures ie; towards the Juvenile and Tiller period of crops. ',
    crop_sown_area_legend: require('../images/documents/crop-sown-area-legend.png'),
    fig_3: 'Fig: Legend for Crop Sown Area',
    use_case:
      'Sown data provides information about the farm that helps decisions makers with',
    useCasePoints: [
      'Monitoring Farm Activities',
      'Agri-Resource Planning',
      'Crop yield estimation',
      'Land use planning'
    ],
    advantagePoints: [
      'Remotely sensed phenological data are useful for late and early sowing',
      'Documenting phenological trends over time and detecting the impacts of climate change on ecosystems.',
      'In bad weather conditions SAR based estimation of crop sowing progression is used for accurate data collection.'
    ]
  },

  {
    id: '#SatSure-Cygnus',
    title: 'SatSure Cygnus',
    // sidebarDetails: [
    //   'Introduction',
    //   'Advantages',
    //   'Use cases'
    // ],   
     intro:
      'SatSure Cygnus is a patented solution by SatSure. It is an all-weather, high revisit, optical virtual constellation for vegetation monitoring, enabled by Deep-Learning Based Model.',
    intro_1:
      'One of the major challenges that the industry faces when it comes to continuous crop monitoring is the loss of data due to cloud cover in monsoon season. This discontinuity of data can last for weeks or months through the monsoon season. The only other alternative to optical data for continuous crop monitoring is the usage of microwave data. However, it comes with issues such as interpretation complexity and data sparse temporal availability.',
    intro_2:
      'To address these issues, SatSure Cygnus uses a deep learning-based model that reconstructs optical data from Synthetic Aperture Radar imagery. ',
    method:
      'Workflow diagram showing the  optical data reconstruction from Synthetic Aperture Radar Data using a deep learning based algorithm: ',
    image_link_1: require('../images/documents/Cygnus Flowchart.png'),
    satsure_cygnus_desc:
      'The output generated can be used to reconstruct vegetation indices identical to actual optical satellite imagery. Thus, Satsure Cygnus can be used to generate Satsure Cygnus NDVI & NDWI in regions where we have no data due to cloud cover. ',
    satsure_cygnus_desc:
      'Mathematically Satsure Cygnus NDVI/ NDWI is represented as',
    formula1: 'Satsure Cygnus NDWI = (NIR - SWIR) / (NIR + SWIR), & ',
    formula2: 'Satsure Cygnus NDVI = (NIR - RED) / (NIR + RED) ',
    desc1: 'Calculations of Satsure Cygnus NDVI/ NDWI for a given pixel always result in a number that ranges from minus one (-1) to plus one (+1). Values from -1 to 0 indicate a bright surface with no vegetation or water content, while closer to +1 indicates presence of water. ',
    desc2: 'In this study the Satsure Cygnus NDVI/ NDWI values are scaled between 0 and 200 using the following equation:',
    formula3: 'Scaled Satsure Cygnus NDVI/ NDWI = ( Satsure Cygnus NDVI/ NDWI x 100) + 100',
    formula_desc:'where the Satsure Cygnus NDVI/ NDWI values range between -1 to +1',
    satsure_cygnus1_legend: require('../images/documents/cygnus-ndvi.png'),
    fig_3: 'Fig: Legends for Satsure Cygnus NDVI',
    satsure_cygnus2_legend: require('../images/documents/cygnus-ndwi.png'),
    fig_4: 'Legends for Satsure Cygnus NDWI',
    use_case:
      'The availability of a time series of Satsure Cygnus NDVI contributes significantly to the creation of analytics models that can improve the following:',
    useCasePoints: [
      'Vegetation health monitoring,',
      'LULC change monitoring',
      'Crop classification ',
      'Yield prediction',
      'Flood & Drought monitoring'
    ],
    advantagePoints: [
      'During monsoon season, due to discontinuous data for periods of weeks or even months, optical data is obstructed by clouds. Satsure Cygnus can reconstruct vegitative indices to assess crop performance. ',
      'Satsure Cygnus can be used to generate multiple other indices or images using a combination of 5 bands reconstructed ie. R, G, B, NIR, SWIR using the Deep Learning algorithm. '
    ]
  },

  {
    id: '#Irrigation-Condition',
    title: 'Irrigation Condition',
    // sidebarDetails: [
    //   'Introduction',
    //   'Advantages',
    //   'Use cases'
    // ],   
     intro:
      'Irrigation condition shows the state of the irrigation on individual farm parcels, which can be used to identify irrigation gaps and make efficient use of the water resources that are already available.',
    intro_1:
      'This data product uses vegetative & water indices calculated from the sentinel 2 data. The indices are then processed & aggregated with logical computation based on thresholding to derive an irrigation mask. Resulting in a raster with values indicating Rainfed or Irrigated data at pixel level. ',
    method:
      'Workflow diagram showing the methodology of generating irrigation condition: ',
    image_link_1: require('../images/documents/irrigation.png'),
    // use_case:
    //   'The availability of a time series of NDVI contributes significantly to the creation of analytics models that can improve the following:',
    useCasePoints: [
      'Farm Scouting',
      'Farm Assessment',
      'Water supply planning',
      'Irrigation demand assessments '
    ],
    advantagePoints: [
      'Researchers can get a better understanding about the past trends of the irrigation conditions with a time series analysis helping in identification of the change in irrigated areas. ',
      'Irrigation maps can help in identifying areas where certain crops are more suitable as per the irrigation condition '
    ]
  }
];

export  const mainData =[
  {
    id: '#documentation',
    title: 'documentation',
    sidebarDetails: [
      'Explore docs',
    ],
  }
]
