import React from 'react';
import { Navigate } from 'react-router-dom';
import { USER_DETAILS_KEY } from '../utils/constants';
import Header from '../components/header/header';

const PrivateRoute = ({ children }) => {
  const isAuthenticate = localStorage.getItem(USER_DETAILS_KEY) ? true : false;
  return isAuthenticate ? (
    <>
      <Header />
      {children}
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default PrivateRoute;
