import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import ApiData from '../../assets/json/apidocs.json';

import './apidocs.css';

// Images
import CopyIcon from '../../assets/images/copy-image.svg';
import CopiedIcon from '../../assets/images/request-status-completed.svg';

const APIDocs = () => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [isUrlCopied, setIsUrlCopied] = useState(false);
  const [isRequestCopied, setIsRequestCopied] = useState(false);
  const [isResponseCopied, setIsResponseCopied] = useState(false);
  const myRef = useRef(null);
  const myRes = useRef(null);

  useEffect(() => {
    if (location.hash !== '') {
      setData(ApiData.data.filter((item) => item.id === location.hash));
      setIsUrlCopied(false);
      setIsRequestCopied(false);
      setIsResponseCopied(false);
      if (myRes.current !== null) {
        myRes.current.scrollTo(0, 0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);

  useEffect(() => {
    myRef.current.scrollIntoView();
  }, [data]);

  return (
    <div className="documentation-product-container">
      <div className="documentations-wrapper">
        {/* <div className="search-bar">
          <span className=" search-icon" />
          <input type="text" placeholder="Search" />
        </div> */}
        <div className="document-content-sections">
          <div ref={myRef} className="document-intro">
            <h4>API Docs / {data[0]?.title || ''}</h4>
            <h2>{(data[0] && data[0]?.title) || ''}</h2>
            <p>{(data[0] && data[0]?.content) || ''}</p>
          </div>
          <div>
            <div className="url-section">
              <label class="document-heading">URL</label>
              <div className="url-copy-section">
                <span>
                  <p className="document-common-para">{data[0]?.url || ''}</p>
                </span>
                <img
                  src={isUrlCopied ? CopiedIcon : CopyIcon}
                  alt="copy icon"
                  className="copy-icon"
                  onClick={() => {
                    setIsUrlCopied(true);
                    navigator.clipboard.writeText(data[0]?.url || '');
                    setTimeout(() => setIsUrlCopied(false), 4000);
                  }}
                />
              </div>
            </div>
            <div className="desc-section">
              <label class="document-heading">
                Method<sup>*</sup>
              </label>
              <hr className="horizontal-line" />
              <p className="document-common-para">[{data[0]?.method}]</p>
            </div>
            <div className="desc-section">
              <label class="document-heading">
                Headers<sup>*</sup>
              </label>
              <hr className="horizontal-line" />
              <p className="document-common-para bold">
                Authorization token is required.
              </p>
            </div>
            {data[0]?.queryparams && (
              <div className="desc-section">
                <label class="document-heading">
                  Parameters<sup>*</sup>
                </label>
                <hr className="horizontal-line" />
                {data[0]?.queryparams &&
                  data[0]?.queryparams.map((item) => {
                    return (
                      <div className="sub-header">
                        <span>{item.name}</span>
                        <label>{item.type}</label>
                      </div>
                    );
                  })}
              </div>
            )}
            {data[0]?.pathparams && (
              <div className="desc-section">
                <label class="document-heading">
                  Path Parameters<sup>*</sup>
                </label>
                <hr className="horizontal-line" />
                {data[0]?.pathparams &&
                  data[0]?.pathparams.map((item) => {
                    return (
                      <div className="sub-header">
                        <span>{item.name}</span>
                        <label>{item.type}</label>
                      </div>
                    );
                  })}
              </div>
            )}

            {data[0]?.request && (
              <div className="desc-section">
                <label class="document-heading">
                  Request<sup>*</sup>
                </label>
                <hr className="horizontal-line" />

                <div className="response-section">
                  <span>
                    <pre>{JSON.stringify(data[0]?.request, undefined, 2)}</pre>
                  </span>
                  <img
                    src={isRequestCopied ? CopiedIcon : CopyIcon}
                    alt="copy icon"
                    className="copy-icon"
                    onClick={() => {
                      setIsRequestCopied(true);
                      navigator.clipboard.writeText(
                        JSON.stringify(data[0]?.request, undefined, 2)
                      );
                      setTimeout(() => setIsRequestCopied(false), 4000);
                    }}
                  />
                </div>
              </div>
            )}
            {data[0]?.response && (
              <div className="desc-section">
                <label class="document-heading">Response</label>
                <hr className="horizontal-line" />

                <div ref={myRes} className="response-section">
                  <span>
                    <pre>{JSON.stringify(data[0]?.response, undefined, 2)}</pre>
                  </span>
                  <img
                    src={isResponseCopied ? CopiedIcon : CopyIcon}
                    alt="copy icon"
                    className="copy-icon"
                    onClick={() => {
                      setIsResponseCopied(true);
                      navigator.clipboard.writeText(
                        JSON.stringify(data[0]?.response, undefined, 2)
                      );
                      setTimeout(() => setIsResponseCopied(false), 4000);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className="side-nav-container">
        <h3 className="summary">Summary</h3>
        <p className="notes-summary">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </div> */}
    </div>
  );
};

export default APIDocs;
