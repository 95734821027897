export const BASE_URL = process.env.REACT_APP_BASE_URL;

// export const BASE_URL = 'http://192.168.0.126:8080';

export const AUTH_BASE_URL = `${BASE_URL}/v1/auth`;

export const USER_BASE_URL = `${BASE_URL}/v1/user`;

export const DASHBOARD_BASE_URL = `${BASE_URL}/v1/data`;

/* Auth URL's */
export const REFRESH_TOKEN_URL = `${AUTH_BASE_URL}/refresh-both-tokens`;

export const CHECK_USER_EMAIL_URL = `${AUTH_BASE_URL}/check-user-email`;

export const LOGIN_URL = `${AUTH_BASE_URL}/login`;

export const CREATE_USER_URL = `${AUTH_BASE_URL}/create-user`;

export const INDUSTRY_URL = `${USER_BASE_URL}/industry`;

export const ROLE_URL = `${USER_BASE_URL}/role`;

export const USER_DETAILS = `${USER_BASE_URL}/user-details`;

export const LOGOUT_URL = `${AUTH_BASE_URL}/logout`;

/* Data Request URL's */

export const PRODUCT_URL = `${DASHBOARD_BASE_URL}/products`;

export const PRODUCT_CROP = `${DASHBOARD_BASE_URL}`;

export const RESEND_EMAIL_URL = `${AUTH_BASE_URL}/send-verification-email`;

export const DATA_REQUEST_FILTER_URL = `${DASHBOARD_BASE_URL}/product`;

export const AOI_PRODUCT_URL = `${DASHBOARD_BASE_URL}/products/aoi`;

export const DATASET_URL = `${DASHBOARD_BASE_URL}/product`;

/* Setting page URL's */

export const UPDATE_PASSWORD_URL = `${USER_BASE_URL}/update-password`;

/* Api Key*/

export const API_KEY_URL = `${USER_BASE_URL}/api-key`;

/* WMS Layer */

export const BASE_URL_GEO_SERVER = `${DASHBOARD_BASE_URL}/geoproxy/wms`;

/* Support Page */

export const SUBMIT_URL = `${USER_BASE_URL}`;

export const COUNTRY_DROPDOWN_URL = `${USER_BASE_URL}/country`;

export const SOLUTION_DROPDOWN_URL = `${USER_BASE_URL}/solutions`;

// All Request page

export const FILE_UPLOAD_URL = `${DASHBOARD_BASE_URL}/delivery-document`;

export const API_COUNT_URL = `${DASHBOARD_BASE_URL}/get-api-hits`;

// Forgot Password

export const RESET_PASSWORD_URL = `${AUTH_BASE_URL}/forgot-pass-setpass`;
