import imgRequestStatusRequested from '../assets/images/request-status-requested.svg';
import imgRequestStatusInProgress from '../assets/images/request-status-inprogress.svg';
import imgRequestStatusDeclined from '../assets/images/request-status-declined.svg';
import imgRequestStatusCompleted from '../assets/images/request-status-completed.svg';
import download from '../assets/images/download.svg';
import jwtDecode from 'jwt-decode';
import {
  COMPLETED,
  DECLINE,
  INPROGESS,
  REQUEST,
  USER_DETAILS_KEY
} from './constants';
import { refreshTokenRequest } from './http-helper';

export const forceLogout = () => {
  window.localStorage.clear();
  window.location.replace('/');
};

export const getUserDetails = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const storeUserDetails = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

var refreshToken = JSON.parse(
  localStorage.getItem(USER_DETAILS_KEY)
)?.refreshToken;

export const getToken = async (key) => {
  let token = JSON.parse(localStorage.getItem(key))?.accessToken;
  let decodeToken = jwtDecode(token);
  if (decodeToken.exp <= Math.round(new Date().getTime() / 1000)) {
    return await callRefreshToken();
  } else {
    return JSON.parse(localStorage.getItem(key));
  }
};

const callRefreshToken = async () => {
  const { data, status } = await refreshTokenRequest(refreshToken);
  if (status === 200) {
    storeUserDetails(USER_DETAILS_KEY, data.data);
    refreshToken = data?.data?.refreshToken;
    return data?.data;
  } else {
    forceLogout();
  }
};

/**
 * Responsive Status list
 * @param {*} status
 * @returns
 */
export const getStatusIcon = (status) => {
  switch (status) {
    case REQUEST:
      return {
        img: imgRequestStatusRequested,
        title: 'Requested'
      };
    case INPROGESS:
      return {
        pic: download,
        img: imgRequestStatusInProgress,
        title: 'InProgress'
      };
    case DECLINE:
      return {
        img: imgRequestStatusDeclined,
        title: 'Declined'
      };
    case COMPLETED:
      return {
        pic: download,
        img: imgRequestStatusCompleted,
        title: 'Completed'
      };
    default:
      return null;
  }
};

// Product list Status

export const getProductListName = (status) => {
  let products = JSON.parse(getUserDetails('data-products'));
  let new_obj = products.find((item) => item.productId === status);
  return new_obj;
};

export const getProductId = (type) => {
  let products = JSON.parse(getUserDetails('data-products'));
  let new_obj = products.find((item) => item.treeTitleCode === type);

  return new_obj;
};

export const attachParams = (baseUrl, params) => {
  const url = new URL(baseUrl);
  url.search = new URLSearchParams(params).toString();
  return url;
};
