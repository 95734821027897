import React, { useState } from 'react';
import moment from 'moment';
import Select from 'react-select';

import { getStatusIcon, getProductListName } from '../../utils/user-helper';
import { SUPER_ADMIN, USER } from '../../utils/constants';
import { getApiHitsRequest } from '../../services/request-services';

import RequestMapModal from '../../components/request-map-modal';
// Images

import InfoGreen from '../../assets/images/icons/info-green.svg';
const RequestChildRow = ({
  RequestChildRow,
  RequestRowData,
  RequestRowIsAdmin,
  requestChilApiKeyOption
}) => {
  const [selectedChildApiKey, setSelectedChildApiKey] = useState();
  const [showModal, setShowModal] = useState(false);
  const [apiCallsMade, setApiCallsMade] = useState();

  const onChangeChildRequestRow = async (e) => {
    setSelectedChildApiKey(e);
    var requestId = RequestRowData.id;
    var date = RequestChildRow?.properties?.datetime.split('T')[0];
    var apiKeyId = e.id;
    const { data, status } = await getApiHitsRequest(requestId, date, apiKeyId);
    if (status === 200) {
      setApiCallsMade(data.data.request_count);
    }
  };

  return (
    <>
      <tr className="child-row">
        <td
          className="cursor-pointer"
          onClick={() => {
            setShowModal(true);
          }}>
          <label
            className="file-name"
            title={
              moment(RequestChildRow?.properties?.datetime).format(
                'DD-MMM-YYYY'
              ) +
              '_' +
              RequestRowData.file_name +
              '_' +
              getProductListName(RequestRowData.product_id)?.treeTitleCode
            }>
            {moment(RequestChildRow?.properties?.datetime).format(
              'DD-MMM-YYYY'
            ) +
              '_' +
              RequestRowData.file_name +
              '_' +
              getProductListName(RequestRowData.product_id)?.treeTitleCode}
          </label>
        </td>
        {RequestRowIsAdmin === SUPER_ADMIN && (
          <td className="requestor">{RequestRowData.requested_by}</td>
        )}
        <td className="requestor">
          {getProductListName(RequestRowData.product_id)?.treeTitleCode}
        </td>
        <td className="daterange">
          {`${moment(RequestRowData.from_date).format('DD/MM/YYYY')},
            ${moment(RequestRowData.to_date).format('DD/MM/YYYY')}`}
        </td>
        <td>
          {moment
            .utc(RequestRowData.date_of_request)
            .format('DD/MM/YYYY HH:mm:ss')}
        </td>
        <td className="status">
          <img src={getStatusIcon(3).img} alt="status icons" />
        </td>
        <td>
          {RequestRowData?.date_of_expiry
            ? moment(RequestRowData?.date_of_expiry).format('DD/MM/YYYY')
            : '-'}
        </td>

        <td>
          <div className="filename-wrapper" title={RequestRowData?.file_name}>
            <label className="file-name-aoi-name">
              {RequestRowData?.file_name}
            </label>
          </div>
        </td>

        {RequestRowIsAdmin === USER && (
          <>
            <td className="api-key">
              <Select
                classNamePrefix="key-select"
                options={requestChilApiKeyOption}
                placeholder="Select Api Key"
                value={selectedChildApiKey}
                onChange={(e) => onChangeChildRequestRow(e)}
              />
            </td>
            <td>-</td>
            <td>-</td>
          </>
        )}
        <td className="apicallsmade">
          <label className={apiCallsMade === 100 ? 'green-text' : ''}>
            {apiCallsMade !== undefined ? apiCallsMade + ' / 100' : '-'}
          </label>
          {apiCallsMade === 100 && (
            <span className="apicallsmade-popup">
              <div className="info-img">
                <img src={InfoGreen} alt="Info" />
              </div>
              <div className="apicallsmade-info-popup">
                <p class="info-content">
                  You've exhausted all API calls. Contact SatSure Sparta to
                  increase API calls limit.
                </p>
              </div>
            </span>
          )}
        </td>
        <td>
          {/* {data?.date_of_delivery
                    ? moment(RequestRowData.date_of_delivery).format('DD/MM/YYYY')
                    : '-'} */}
          -
        </td>
        {RequestRowIsAdmin === SUPER_ADMIN && (
          <td>
            {/* <span
                      className="action dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      Action
                      <img src={ExpandArrow} alt="expand down arrow" />
                      <ul className="dropdown-menu option-menu">
                        <li className="menu-RequestChildRow approve">
                          <label className="approve">Approve</label>
                        </li>
                        <li className="menu-RequestChildRow reject">
                          <label className="reject">Reject</label>
                        </li>
                      </ul>
                    </span>
                    <span className="action-reject">Rejected</span>
                    <label className="rejected-person">
                      santhoshkumar@satsure.co
                    </label> */}
            -
          </td>
        )}
        {/* <td className="docs"></td> */}
      </tr>
      <RequestMapModal
        particularDateLayer={RequestChildRow}
        layerParentProductId={RequestRowData}
        particularChildlayerFileName={
          moment(RequestChildRow?.properties?.datetime).format('DD-MM-YYYY') +
          '_' +
          RequestRowData.file_name +
          '_' +
          getProductListName(RequestRowData.product_id)?.treeTitleCode
        }
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default RequestChildRow;
