import React, { useEffect } from 'react';
import SatSureCygnus from '../../components/satsure_cygnus/satsure_cygnus';
import Sidebar from '../../components/sidebar/sidebar';
import './documentation.css';
import APIDocs from '../../components/apidocs/apidocs';
import { ApiData, mainData } from '../../assets/json/product';
import { useLocation, useNavigate } from 'react-router-dom';
import Products from '../../components/products/products';
import Search from '../../assets/images/search-icon.svg';
import AgInputCompanies from '../../components/analytics';

const Documentation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname.split('/');

  const isSidebarPathSelected = path[2] !== undefined;

  return (
    <>
      <div className="documentation-container">
        <div className="documentation-sections">
          <Sidebar tab="documentation" />
        </div>
        <div className="documentation-list">
          {/* <div className="project-search">
            <input
              type="text"
              name="search"
              className="search-input"
              placeholder="Search"
            />
            <img src={Search} alt="search icon" />
          </div> */}

          {!isSidebarPathSelected && (
            <div className="card-container">
              <h2 className="card-heading">Explore Docs</h2>
              <p className="card-paragraph">
              Learn more about Sparta Platform.
              </p>
              <div className="card-div">
                <div
                  className="card-product"
                  onClick={() => navigate('/documentation/api-docs/#products')}>
                  <h3>API Docs</h3>
                  <p>
                  Check API documentation for products and datasets here.
                  </p>
                </div>
                <div
                  className="card-product"
                  onClick={() => navigate('/documentation/products/#NDVI')}>
                  <h3>Products</h3>
                  <p>
                  Introduction about different data products offered on Sparta Platform.
                  </p>
                </div>
              </div>
              <div className="card-div">
                <div
                  className="card-product"
                  onClick={() => navigate('/documentation/industry/#sales')}>
                  <h3>Industry</h3>
                  <p>
                  Explore use cases specific to your industry. For eg. Ag input, Food commodities
                  </p>
                </div>
              </div>
            </div>
          )}

          {path[2] === 'products' && <Products />}
          {path[2] === 'cygnus' && <SatSureCygnus />}
          {path[2] === 'api-docs' && <APIDocs />}
          {path[2] === 'industry' && <AgInputCompanies />}
        </div>

      </div>
    </>
  );
};

export default Documentation;
