import React, { useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';

import './modal.css';

import CloseFullScreen from '../../assets/images/closeFullscreen.svg';

//import Tick from '../../assets/images/tick.svg';
//import { getStatusIcon } from '../../utils/user-helper';

import { getApiRequest } from '../../utils/constants';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '60%',
    padding: '0px',
    borderRadius: '8px'
  }
};

const PopupModal = ({
  modalType,
  showModal,
  setShowModal,
  modalData,
  selectedkey,
  treeIdValue,
  selectedApiKey,
  apiKeyOption,
  setSelectedKey
}) => {
  const [copied, setCopied] = useState(false);

  function closeModal() {
    setShowModal(false);
  }

  const copyToClipboard = () => {
    if (modalType === 'request') {
      navigator.clipboard.writeText(
        getApiRequest(selectedkey?.value, treeIdValue)
      );
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    } else if (modalType === 'response') {
      navigator.clipboard.writeText(
        JSON.stringify(modalData?.response, undefined, 2)
      );
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    }
  };

  return (
    <Modal
      isOpen={showModal}
      ariaHideApp={false}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal">
      <div className="react-modal-header">
        <div className="request-head-item">
          <h1>{modalType === 'request' ? 'Request' : 'Response'}</h1>
          {/* {modalData.status && (
            <span>
              <img src={getStatusIcon(modalData.status)} alt="tick icon" />
            </span>
          )} */}
        </div>
        <div className="request-head-item second">
          {modalType === 'request' ? (
            <div>
              <Select
                classNamePrefix="key-select"
                placeholder="Select Api key"
                options={apiKeyOption}
                value={selectedApiKey}
                onChange={(e) => setSelectedKey(e)}
              />
            </div>
          ) : (
            modalType === 'response'
          )}
          {/* <div>Python</div> */}
          <span
            className={`copy ${copied ? 'copied' : ''}`}
            onClick={() => copyToClipboard(modalData?.response)}>
            {copied ? 'Copied' : 'Copy'}
          </span>

          <div
            className="close-content-wrapper"
            onClick={() => setShowModal(false)}>
            <img src={CloseFullScreen} alt="expand-content" />
          </div>
        </div>
      </div>
      <div className="react-modal-body">
        <pre className="body-data">
          {modalType === 'response' ? (
            <>
              {modalData &&
                modalData.response !== '' &&
                modalData.length !== 0 &&
                JSON.stringify(modalData.response, undefined, 2)}
            </>
          ) : (
            <>
              {treeIdValue !== '' &&
                getApiRequest(selectedkey?.value, treeIdValue)}
            </>
          )}
        </pre>
      </div>
    </Modal>
  );
};

export default PopupModal;
