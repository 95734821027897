import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './product.css';
import { ApiData } from '../../assets/json/product';

const Products = () => {
  const location = useLocation();
  const [data, setData] = useState(
    { useCasePoints: [] },
    { advantagePoints: [] });

  const myRef = useRef(null);
  const sideNavRef = useRef(null);

  useEffect(() => {
    if (location.hash !== '') {
      setData(ApiData.filter((item) => item.id === location.hash));
    }
  }, [location.hash]);

  useEffect(() => {
    myRef.current.scrollIntoView();
  }, [data]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSideNavScroll = (e, sectionId) => {
    e.preventDefault();
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="documentation-product-container">
      <div className="documentations-wrapper">
        {/* <div className="search-bar">
          <span className=" search-icon" />
          <input type="text" placeholder="Search" />
        </div> */}

        <div className="document-content-sections">
          <div ref={myRef} className="document-intro">
            <h4>Products / {data[0]?.title || ''}</h4>
            <h2>{(data[0] && data[0]?.title) || ''}</h2>
            <p>{(data[0] && data[0]?.content) || ''}</p>
            <hr />
          </div>
          <div className="intro-section">
            <label id="section-2" className="document-heading">
              Introduction
            </label>
            {data[0]?.intro_para && (
              <p className="document-common-para margin-top">
                {data[0]?.intro_para}
              </p>
            )}
          </div>
          {data[0]?.intro_1 && (
            <p className="document-common-para margin-top">
              {data[0]?.intro_1}
            </p>
          )}
          {data[0]?.intro_2 && (
            <p className="document-common-para margin-top">
              {data[0]?.intro_2}
            </p>
          )}
          <div className="intro1-section">
            {data[0]?.method && (
              <p className="document-common-para">{data[0]?.method}</p>
            )}
            {data[0]?.formula && <p className="formula1">{data[0]?.formula}</p>}
            {data[0]?.formula_desc && (
              <p className="document-common-para">{data[0]?.formula_desc}</p>
            )}
            {data[0]?.image_link_1 && (
              <div className="document-image-wrapper">
                <img src={data[0]?.image_link_1} alt="doc img"></img>
              </div>
            )}

            {data[0]?.fig_1 && (
              <label className="figures">{data[0]?.fig_1}</label>
            )}
            {data[0]?.image_desc_1 && (
              <p className="document-common-para">{data[0]?.image_desc_1}</p>
            )}
            {data[0]?.image_desc_2 && (
              <p className="document-common-para">{data[0]?.image_desc_2}</p>
            )}
          </div>
          {data[0]?.request && data[0]?.data && data[0]?.data2 && (
            <div className="intro2-section">
              {data[0]?.request && (
                <p className="document-common-para">{data[0]?.request}</p>
              )}
              {data[0]?.data && <p className="formula1">{data[0]?.data}</p>}
              {data[0]?.data2 && (
                <div className="main-desc-section">
                  <p className="document-common-para">{data[0]?.data2}</p>
                </div>
              )}
            </div>
          )}
          <div className="image-section">
            {data[0]?.crop_sown_area_img && (
              <div className="document-image-wrapper">
                <img src={data[0]?.crop_sown_area_img} alt="doc img"></img>
              </div>
            )}
            {data[0]?.fig_2 && (
              <label className="figures">{data[0]?.fig_2}</label>
            )}
            {data[0]?.crop_sown_area_desc && (
              <>
                <p className="document-common-para">
                  {data[0]?.crop_sown_area_desc}
                </p>
                <div className="legend-image-wrapper">
                  <img src={data[0]?.crop_sown_area_legend} alt="doc img"></img>
                </div>
                <label className="figures">{data[0]?.fig_3}</label>
              </>
            )}
            {data[0]?.satsure_cygnus_desc && (
              <>
                <p className="document-common-para margin-top">
                  {data[0]?.satsure_cygnus_desc}
                </p>
                <p className="formula1">{data[0]?.formula1}</p>
                <p className="formula1">{data[0]?.formula2}</p>
                <p className="document-common-para">{data[0]?.desc1}</p>
                <p className="document-common-para margin-top">
                  {data[0]?.desc2}
                </p>
                <p className="formula1">{data[0]?.formula3}</p>
                <div className="main-desc-section">
                  <p className="document-common-para">
                    {data[0]?.formula_desc}
                  </p>
                </div>
                <div className="legend-image-wrapper">
                  <img src={data[0]?.satsure_cygnus1_legend} />
                </div>
                <label className="figures">{data[0]?.fig_3}</label>
                <div className="legend-image-wrapper">
                  <img src={data[0]?.satsure_cygnus2_legend} />
                </div>
                <label className="figures">{data[0]?.fig_4}</label>
              </>
            )}
            {data[0]?.ndvi_legend && (
              <>
                <div className="legend-image-wrapper">
                  <img src={data[0]?.ndvi_legend}></img>
                </div>
                <label className="figures">{data[0]?.fig_4}</label>
              </>
            )}

            {data[0]?.ndwi_legend && (
              <>
                <div className="legend-image-wrapper">
                  <img src={data[0]?.ndwi_legend}></img>
                </div>
                <label className="figures">{data[0]?.fig_5}</label>
              </>
            )}
          </div>
          {data[0]?.advantagePoints &&
            data[0]?.advantagePoints.length !== 0 && (
              <div className="advantage-container">
                <label id="section-3" className="document-heading">
                  Advantages
                </label>
                <p>{data[0]?.adv_case}</p>
                <ol className="advantage-list-item">
                  {data[0]?.advantagePoints.map((advantagePoints, index) => (
                    <li key={index}>{advantagePoints}</li>
                  ))}
                </ol>
              </div>
            )}
          {data[0]?.useCasePoints && data[0]?.useCasePoints.length !== 0 && (
            <>
              <h3 id="section-4" className="use_case_header margin-top">
                Use Cases
              </h3>
              <p className="use_case_para">{data[0]?.use_case}</p>
              <ol className="usecase-list-item">
                {data[0]?.useCasePoints.map((useCasePoints, index) => (
                  <li key={index}>{useCasePoints}</li>
                ))}
              </ol>
            </>
          )}
        </div>
      </div>

      <div className="side-nav-container" ref={sideNavRef}>
        <h2 className="sidenav-header">On this Page</h2>
        <div>
          <li>
            <a
              href="#section-2"
              onClick={(e) => handleSideNavScroll(e, 'section-2')}>
              Introduction
            </a>
          </li>
          <li>
            <a
              href="#section-3"
              onClick={(e) => handleSideNavScroll(e, 'section-3')}>
              Advantages
            </a>
          </li>
          <li>
            <a
              href="#section-4"
              onClick={(e) => handleSideNavScroll(e, 'section-4')}>
              Use Cases
            </a>
          </li>
        </div>
      </div>
    </div>
  );
};

export default Products;
