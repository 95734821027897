import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Typography
} from '@mui/material';
import imgDropDown from '../../assets/images/drop_down_accordion.svg';

const StyledAccordion = styled(Accordion)`
  box-shadow: none;
  &:not(:last-child) {
    border-bottom: 0;
  }
  &:before {
    display: none;
  }
  &.Mui-expanded {
    margin: auto;
  }
`;

const StyledAccordionSummary = styled(AccordionSummary)`
  border: none;
  padding: 11px 16px 11px 16px;
  min-height: 0;
  &:hover {
    background-color: #f2f2f2;
  }
  &.Mui-expanded {
    min-height: 0;
  }
  &.Mui-expanded:hover {
    background-color: #e0eeed;
  }
  .MuiAccordionSummary-content {
    margin: 0;
  }
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
`;

const StyledAccordionDetails = styled(AccordionDetails)`
  padding: 22px 16px 22px 16px;
`;

const Question = styled(Typography)`
  font-size: var(--main-txt-size-14);
  color:var(--main-txt-dark-charcoal-color);
`;

const Answer = styled(Typography)`
  font-size: var(--main-txt-size-14);
  color: var(--main-txt-black-color);
`;

const FAQAccordion = ({ question, answer }) => {

  return (
    <div>
      <StyledAccordion>
        <StyledAccordionSummary
          expandIcon={<img src={imgDropDown} alt="cross" />}
          aria-controls="panel-content"
          id="panel-header">
          <Question>{question}</Question>
        </StyledAccordionSummary>
        <StyledAccordionDetails>
          <Answer>{answer}</Answer>
        </StyledAccordionDetails>
      </StyledAccordion>
    </div>
  );
};

export default FAQAccordion;
