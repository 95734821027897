import React from 'react';
import { useNavigate } from 'react-router-dom';
// Images
import SatsureLogo from '../../assets/images/logo/satsure-logo.svg';
import backgroundBanner from '../../assets/images/banner/landng_banner.png';
import ConfirmEmail from '../../assets/images/confirm-email-verified.svg';
import spartaLogo from '../../assets/images/logo/Sparta 3.svg';

const EmailVerificationSuccess = () => {
  const navigate = useNavigate();
  return (
    <div className="login-container">
      <div className="banner-sections">
        <div className='banner-img'>
          <img alt="sparta Logo" src={backgroundBanner} />
        </div>
        <div className="sparta-logo-sections">
          <div className="sparta-logo">
            <img className="" alt="sparta Logo" src={spartaLogo} />
          </div>
        </div>
      </div>

      <div className="form-container">
        <div className="common-login-field email-verifications">
          <h1 className="email-verification-heading">Thanks</h1>
          <p className="email-description">
            Thank you for confirming your email address.
            <br /> You may now proceed to Sparta Dashboard.
          </p>
          <label className="email-logo-wrapper">
            <img src={ConfirmEmail} alt="email verification logo" />
          </label>
          <div className="resend-email-button">
            <button className="btn-register" onClick={() => navigate('/')}>
              Login
            </button>
          </div>
        </div>
        <div className="powered-by">
          <div className="powered-by-wrapper">
            <label>Powered by</label>
            <div>
              <img src={SatsureLogo} alt="Satsure Logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationSuccess;
