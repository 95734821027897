import React, { useState } from "react"; import moment from 'moment';
import CopyIcon from '../../assets/images/icons/copy-icon.svg';

const ApiKeyRow = ({ regenerationApiKey, handleKeyStatus, apiKey }) => {
    const [copied, setCopied] = useState('');

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setCopied("Copied..!");
        setTimeout(() => {
            setCopied("");
        }, 4000);
    };

    return (
        <tr className="product-body-row">
            <td className="product-1 width-17">{apiKey.name}</td>
            <td className="key-value">
                <label className="text-ellipsis">{apiKey.value}</label>
                <div className="text-tooltip">
                    <span
                        className="copy-img"
                        onClick={() => {
                            copyToClipboard(apiKey.value)
                        }}>
                        <img alt="Text Copy" src={CopyIcon}></img>
                    </span>
                    {copied && (
                        <p className="copy-tooltip">{copied}</p>)
                    }
                </div>
            </td>
            <td className="width-17">
                {moment(apiKey.created_dttm).format('ll')}
            </td>
            <td className="width-17">
                <label class="switch">
                    <input type="checkbox" onChange={() => {
                        handleKeyStatus(apiKey)
                    }}
                        checked={apiKey.is_active}
                    />
                    <span class="slider round"></span>
                </label>
            </td>
            <td className="width-17">
                <label
                    className={"regenerate"}
                    onClick={() => {
                        regenerationApiKey(apiKey);
                    }}>
                    Regenerate
                </label>
            </td>
        </tr>
    );
}
export default ApiKeyRow;